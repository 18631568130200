import { combineReducers } from 'redux';
import { RESOURCE } from '../../actions';
import { indexReducer, detailsReducer } from '../common';
import statement from './statement';
import statementDetails from './statementDetails';

export default combineReducers({
    index: indexReducer(RESOURCE.ACCOUNTING_MOVEMENTS),
    details: detailsReducer(RESOURCE.ACCOUNTING_MOVEMENTS),
    statement,
    statementDetails,
});
