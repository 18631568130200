import createLogger from 'logging';
import { getCRUD, getOptions } from './common';
import doctorsApi from '../api/doctors';
import { RESOURCE, DOCTOR_TREATMENTS, DOCTOR_TREATMENTS_LOADING } from './index';

const logger = createLogger('DoctorAction');

const getTreatments = id => async (dispatch) => {
    dispatch({
        type: DOCTOR_TREATMENTS_LOADING,
        id,
    });
    try {
        const response = await doctorsApi.getTreatments(id);
        dispatch({
            type: DOCTOR_TREATMENTS,
            id,
            payload: response.data,
        });
    } catch (error) {
        logger.error(`${RESOURCE.DOCTORS} getTreatments error:`, error);
        dispatch({
            type: DOCTOR_TREATMENTS,
            id,
            error,
        });
    }
};

export default {
    ...getCRUD(RESOURCE.DOCTORS, doctorsApi),
    getOptions: getOptions(RESOURCE.DOCTORS, doctorsApi),
    getTreatments,
};
