import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Table.css';
import BaseTable, { navigateToDetail } from '../../Table';
import { BANK_CHECK } from '../../../constants/routes';
import CellCurrencyAbs from '../../Table/CellCurrencyAbs';
import CellCheckDate from './CellCheckDate';
import CellCheckState from './CellCheckState';

const bankDetails = (element) => {
    const { bankAccount } = element;
    if (!bankAccount) {
        return '';
    }
    return `${bankAccount.bankName} / ${bankAccount.accountNumber}`;
};

const COLUMNS = [
    {
        Header: 'Cuenta', id: 'bankAccount', accessor: bankDetails, minWidth: 160,
    },
    { Header: 'Talonario', accessor: 'bookNumber', minWidth: 80 },
    { Header: 'Número de cheque', accessor: 'checkNumber', minWidth: 80 },
    {
        Header: 'Monto', id: 'amount', accessor: 'accountingMovement.amount', Cell: CellCurrencyAbs, minWidth: 90,
    },
    {
        Header: 'Fecha', id: 'flowDate', accessor: 'accountingMovement.flowDate', Cell: CellCheckDate, minWidth: 80,
    },
    {
        Header: 'Estado', accessor: 'state', Cell: CellCheckState, minWidth: 100,
    },
];

const Table = (props) => {
    const { history } = props;

    const navigateToDetailsProps = (state, rowInfo, column) => {
        if (column.id === 'state' || !rowInfo) {
            return false;
        }

        return {
            onClick: () => navigateToDetail(BANK_CHECK, rowInfo.original, history),
            className: 'cursor-pointer',
        };
    };

    return (
        <Row>
            <Col>
                <BaseTable
                    className="BankChecksTable"
                    defaultSorted={[
                        {
                            id: 'flowDate',
                            desc: true,
                        },
                    ]}
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
