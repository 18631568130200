import {
    getDetails, update, create, deletee,
} from './common';
import { RESOURCE } from './index';
import treatmentsApi from '../api/treatments';

export default {
    getDetails: getDetails(RESOURCE.TREATMENTS, treatmentsApi),
    update: update(RESOURCE.TREATMENTS, treatmentsApi),
    create: create(RESOURCE.TREATMENTS, treatmentsApi),
    delete: deletee(RESOURCE.TREATMENTS, treatmentsApi),
};
