import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const pathologyDetails = record => [record.bodySection, record.bodyPart, record.injury].join('/');

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: pathologyDetails(record),
    }
));

const SearchPathology = ({ records, getRecords, onSelect }) => {
    React.useEffect(() => {
        getRecords();
    }, [getRecords]);
    return (<ReactSelect
        placeholder="Agregar patología"
        value=""
        onChange={optionSelected => onSelect(optionSelected.value)}
        options={recordsToOptions(records)}
    />);
};

SearchPathology.propTypes = {
    records: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        bodySection: PropTypes.string,
        bodyPart: PropTypes.string,
        injury: PropTypes.string,
    })),
    getRecords: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
};

SearchPathology.defaultProps = {
    records: [],
    onSelect: () => { },
};

export default SearchPathology;
