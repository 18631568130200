import React from 'react';
import Breadcrumb from '../Breadcrumb';
import * as ROUTES from '../../constants/routes';
import ChangePasswordForm from './ChangePasswordForm';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { label: 'Cambiar contraseña' },
];

const ChangePassword = () => (
    <div>
        <Breadcrumb paths={BREADCRUMB} />
        <ChangePasswordForm />
    </div>
);

export default ChangePassword;
