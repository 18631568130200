import baseApi from './base';
import {
    DOCTORS_PATH, DOCTOR_PATH, DOCTORS_UNIQ_PATH, DOCTOR_TREATMENTS_PATH,
} from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(DOCTORS_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = DOCTOR_PATH.replace(':id', id);
    return baseApi.get(url);
};

const getUniq = async (attribute, value, id) => {
    const queryParams = {
        attribute,
        value,
        ...id && { id },
    };
    return baseApi.get(DOCTORS_UNIQ_PATH, { queryParams });
};

const update = async (id, attributes) => {
    const url = DOCTOR_PATH.replace(':id', id);
    return baseApi.put(url, { doctor: attributes });
};

const create = async attributes => baseApi.post(DOCTORS_PATH, { doctor: attributes });

const getTreatments = async (id) => {
    const url = DOCTOR_TREATMENTS_PATH.replace(':id', id);
    return baseApi.get(url);
};

export default {
    getIndex,
    getDetails,
    getUniq,
    update,
    create,
    getTreatments,
};
