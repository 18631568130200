import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { ACCOUNTING_PAYMENT_TYPE } from '../../../constants/routes';

const COLUMNS = [
    { Header: 'Título', accessor: 'title' },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(ACCOUNTING_PAYMENT_TYPE, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
