import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const getOptionForValue = (value, options, isArray) => {
    if (isArray) {
        return value && value.map(v => getOptionForValue(v, options));
    }
    let option;
    let childOption;
    for (let i = 0; i < options.length; i += 1) {
        option = options[i];
        if (option.value === value) {
            return option;
        }
        if (option.options) {
            childOption = getOptionForValue(value, option.options);
            if (childOption) {
                return childOption;
            }
        }
    }
    return undefined;
};

const SelectInput = (props) => {
    const {
        name, value, options, formik: { setFieldValue }, isMulti,
    } = props;
    const selectedOptionValue = getOptionForValue(value, options, isMulti);
    const onChange = (option) => {
        let optionValue = isMulti ? [] : '';
        if (option) {
            optionValue = isMulti ? option.map(o => o.value) : option.value;
        }
        setFieldValue(name, optionValue);
    };

    return (
        <Select
            isClearable
            placeholder=""
            {...props}
            value={selectedOptionValue}
            onChange={onChange}
        />
    );
};

SelectInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
    })).isRequired,
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }),
    isMulti: PropTypes.bool,
};

SelectInput.defaultProps = {
    name: '',
    value: undefined,
    isMulti: false,
    formik: {},
};

export default SelectInput;
