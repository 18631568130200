import baseApi from './base';
import { ACCOUNTING_CHARTS_PATH, ACCOUNTING_CHART_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(
    ACCOUNTING_CHARTS_PATH,
    { queryParams: filters },
);

const getDetails = async (id) => {
    const url = ACCOUNTING_CHART_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = ACCOUNTING_CHART_PATH.replace(':id', id);
    return baseApi.put(url, { accounting_chart: attributes });
};

const create = async attributes => baseApi.post(
    ACCOUNTING_CHARTS_PATH,
    { accounting_chart: attributes },
);

export default {
    getIndex,
    getDetails,
    update,
    create,
};
