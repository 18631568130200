import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Loading, Attribute } from '../../RecordDetails';
import currency from '../../../utils/currency';
import Actions from './Actions.router';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import dateUtil from '../../../utils/date';
import CheckStateSelect from '../StateForm';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.BANK_CHECKS, label: 'Cheques' },
];

const bankAccountInfo = (record) => {
    const { bankAccount } = record;
    if (!bankAccount) {
        return undefined;
    }
    const url = ROUTES.BANK_ACCOUNT.replace(':id', bankAccount.id);
    return (
        <a href={url}>
            {bankAccount.bankName}
            {' '}
            /
            {' '}
            {bankAccount.accountNumber}
        </a>
    );
};

const accountingMovementLink = (accountingMovement) => {
    if (!accountingMovement) {
        return undefined;
    }
    const url = ROUTES.ACCOUNTING_MOVEMENT.replace(':id', accountingMovement.id);
    return (
        <a href={url}>
            {accountingMovement.id}
        </a>
    );
};


class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    {this.renderBankData()}
                    {this.renderAccountingMovementData()}
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderBankData() {
        const { record } = this.props;
        return (
            <Col lg="6">
                <Attribute optional name="Cuenta bancaria" value={bankAccountInfo(record)} />
                <Attribute optional name="Talonario" value={record.bookNumber} />
                <Attribute optional name="Número de cheque" value={record.checkNumber} />
                <Attribute optional name="Estado" value={<CheckStateSelect record={record} />} />
            </Col>
        );
    }

    renderAccountingMovementData() {
        const { record } = this.props;
        const { accountingMovement } = record;

        if (!accountingMovement) {
            return undefined;
        }

        const { flowDate, amount } = accountingMovement;

        return (
            <Col lg="6">
                <Attribute optional name="Movimiento" value={accountingMovementLink(accountingMovement)} />
                <Attribute optional name="Fecha" value={dateUtil.formatDate(flowDate)} />
                <Attribute optional name="Monto" value={currency(Math.abs(amount))} />
            </Col>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: record.checkNumber },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
