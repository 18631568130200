import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logOut } from '../../actions/auth';
import Header from './Header';

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ logOut }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
