import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: '0', label: 'Atención pacientes y contabilidad' },
    { value: '1', label: 'Solamente contable' },
];

const InputOfficeType = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputOfficeType;
