import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../../actions/patients';
import Treatments from './Treatments';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';

const mapStateToProps = (state) => {
    const { filters } = state.patients.treatments;
    return {
        filters,
        ...mapIndexStateToProps(state.patients.treatments),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getTreatments: () => actions.getTreatments(ownProps.recordId),
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Treatments));
