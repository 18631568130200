const FORMAT = {
    DATE_TIME: '%H:%M %d/%m/%Y',
    DATE: '%d/%m/%Y',
    TIME: '%H:%M',
};

const parseDate = (dateString) => {
    let year = 0;
    let month = 1;
    let day = 1;
    if (dateString) {
        [year, month, day] = dateString.split('-');
    }
    return {
        year,
        month,
        day,
    };
};

const parseTime = (timeString) => {
    let hour = 0;
    let minute = 0;
    let second = 0;
    if (timeString) {
        [hour, minute, second] = timeString.split('.')[0].split(':');
    }
    return {
        hour,
        minute,
        second,
    };
};

const parseDatetime = (datetimeString) => {
    if (!datetimeString) {
        return undefined;
    }

    if (datetimeString instanceof Date) {
        return datetimeString;
    }

    const [dateString, timeString] = datetimeString.split('T');
    const { year, month, day } = parseDate(dateString);
    const { hour, minute, second } = parseTime(timeString);
    return new Date(year, month - 1, day, hour, minute, second);
};

const fixDigits = (n, digits = 2) => `0${n}`.slice(-1 * digits);

const formatDateTime = (datetimeString, format = FORMAT.DATE_TIME) => {
    if (!datetimeString) {
        return '';
    }

    const dateTime = parseDatetime(datetimeString);
    const dateTimeReplacements = {
        '%Y': fixDigits(dateTime.getFullYear(), 4),
        '%m': fixDigits(dateTime.getMonth() + 1),
        '%d': fixDigits(dateTime.getDate()),
        '%H': fixDigits(dateTime.getHours()),
        '%M': fixDigits(dateTime.getMinutes()),
        '%S': fixDigits(dateTime.getSeconds()),
    };

    let output = format;
    Object.keys(dateTimeReplacements).forEach((key) => {
        output = output.replace(key, dateTimeReplacements[key]);
    });
    return output;
};

const formatDate = datetimeString => formatDateTime(datetimeString, FORMAT.DATE);
const formatTime = datetimeString => formatDateTime(datetimeString, FORMAT.TIME);

const getCurrentYear = () => (new Date()).getFullYear();

const getMonthLimits = (year, month) => {
    const monthNumber = Number(month);
    const yearNumber = Number(year);
    return {
        beginning: new Date(yearNumber, monthNumber - 1, 1),
        end: new Date(yearNumber, monthNumber, 0),
    };
};

const calculateAge = (dateOfBirth, today = new Date()) => {
    if (!dateOfBirth) {
        return undefined;
    }
    const dob = parseDatetime(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age -= 1;
    }
    return age;
};

export default {
    formatDateTime,
    formatDate,
    formatTime,
    parseDatetime,
    getCurrentYear,
    getMonthLimits,
    calculateAge,
};
