import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/procedures';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.procedures.index;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { getProcedures: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
