import React from 'react';
import FilterSelect from '../../Table/FilterSelect';
import date from '../../../utils/date';

const START_YEAR = 2010;
const END_YEAR = date.getCurrentYear() + 1;

const generateOptions = () => {
    const options = [];
    for (let year = END_YEAR; year >= START_YEAR; year -= 1) {
        options.push({ value: `${year}`, label: `${year}` });
    }
    return options;
};

const OPTIONS = generateOptions();

const FilterYear = props => (
    <FilterSelect
        {...props}
        options={OPTIONS}
        identifier="year"
    />
);

export default FilterYear;
