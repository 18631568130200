import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingMovements';
import CreateModal from './CreateModal';
import getNestedValue from '../../../utils/getNestedValue';

const mapStateToProps = state => ({
    lastUsedFields: getNestedValue(state, 'cache.accountingMovements/created'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createRecord: actions.create,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);
