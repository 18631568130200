import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRoute from '../ConditionalRoute';
import { LANDING } from '../../../constants/routes';
import getNestedValue from '../../../utils/getNestedValue';

const getCurrentPath = (location) => {
    if (typeof location === 'object') {
        return location.pathname;
    }
    return location;
};

const getPreviousPath = location => getNestedValue(location, 'state.from');

const getRedirectPath = ({ location }) => {
    const previousPath = getPreviousPath(location);
    const currentPath = getCurrentPath(location);
    if (previousPath && previousPath !== currentPath) {
        return previousPath;
    }

    return LANDING;
};

const PublicRoute = ({ isAuthenticated, ...rest }) => (
    <ConditionalRoute
        checker={() => !isAuthenticated}
        redirectPath={getRedirectPath(rest)}
        {...rest}
    />
);

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default PublicRoute;
