import { connect } from 'react-redux';
import StatementTable from './StatementTable';
import { STATUS } from '../../../constants/api';

const mapStateToProps = (state) => {
    const {
        status, error, updated, data = {},
    } = state.accountingMovements.statement;
    return {
        loading: status === STATUS.LOADING,
        error,
        categories: data.categories,
        updated,
    };
};

export default connect(mapStateToProps)(StatementTable);
