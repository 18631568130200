const containsOnlyDigits = text => /^\d+$/.test(text);
export const getVerifierDigit = (rut) => {
    if (!rut) {
        return '';
    }
    const cleanRut = rut.toString().trim().replace(/\./g, '');

    if (cleanRut.length < 2 || !containsOnlyDigits(cleanRut)) {
        return '';
    }

    let runningSum = 0;
    let factor = 2;

    for (let i = cleanRut.length - 1; i >= 0; i -= 1) {
        runningSum += cleanRut.charAt(i) * factor;
        if (factor === 7) {
            factor = 2;
        } else {
            factor += 1;
        }
    }

    const mod = runningSum % 11;
    if (mod === 1) {
        return 'k';
    }

    if (mod === 0) {
        return '0';
    }

    return `${11 - mod}`;
};

export const addThousandsSeparator = (number, separator = '.') => {
    if (!number && number !== 0) {
        return '';
    }

    let base = number.toString().replace(/\D/g, '');
    const regexFor3Digits = /(\d+)(\d{3})/;
    while (regexFor3Digits.test(base)) {
        base = base.replace(regexFor3Digits, `$1${separator}$2`);
    }
    const symbol = number < 0 ? '-' : '';
    return `${symbol}${base}`;
};

export const formatRut = (rut) => {
    if (!rut) {
        return '';
    }
    if (rut.length < 2) {
        return rut;
    }
    const rutText = rut.trim().toString();
    const verifierDigit = rutText.substr(-1);
    const base = rutText.slice(0, -1);

    return `${addThousandsSeparator(base)}-${verifierDigit}`;
};

export const formatRutAddingDigit = (rut) => {
    if (!rut) {
        return '';
    }

    const rutWithDigit = `${rut}${getVerifierDigit(rut)}`;
    return formatRut(rutWithDigit);
};
