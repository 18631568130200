import { withFormik } from 'formik';
import createLogger from 'logging';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'doctor',
    'exams',
    'history',
    'notes',
    'pathologies',
    'actions',
    'sessions',
];

const sessionToValue = (session) => {
    const {
        schedule, kinesiologist, office, ...otherSessionValues
    } = session;
    return {
        ...otherSessionValues,
        scheduleId: schedule ? schedule.id.toString() : '',
        kinesiologistId: kinesiologist ? kinesiologist.id.toString() : '',
        officeId: office ? office.id.toString() : '',
    };
};

const RecordFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: record[key] || '',
        }), {});

        values.pathologyIds = values.pathologies
            ? values.pathologies.map(p => p.id.toString())
            : [];
        values.doctorId = values.doctor ? values.doctor.id.toString() : '';
        values.actions = values.actions ? values.actions.map(action => ({
            _destroy: false,
            ...action,
        })) : [];
        values.sessions = values.sessions ? values.sessions.map(sessionToValue) : [];
        delete values.pathologies;
        delete values.doctor;
        return values;
    },
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            const { actions, sessions, ...newValues } = values;
            newValues.actions_attributes = actions.map(action => ({
                id: action.id,
                quantity: action.quantity,
                procedure_id: action.procedure.id,
                // eslint-disable-next-line no-underscore-dangle
                _destroy: action._destroy ?? false,
            }));
            newValues.sessions_attributes = sessions;
            await onSubmit(newValues);
        } catch (error) {
            logger.error('Error updating treatment:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
