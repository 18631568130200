import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoPlus } from 'react-icons/go';
import CreateModal from './CreateModal.redux';

const ButtonCreateModal = ({ onSuccess }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const onSucessInternal = (record) => {
        setModalShow(false);
        onSuccess(record);
    };

    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-primary">
                <GoPlus />
                {' '}
                Agregar
            </Button>
            <CreateModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSuccess={onSucessInternal}
            />
        </div>
    );
};

ButtonCreateModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
};

export default ButtonCreateModal;
