import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/bankChecks';
import CreateMultipleModal from './CreateMultipleModal';

const mapDispatchToProps = dispatch => bindActionCreators({
    createMultipleRecords: actions.createMultiple,
}, dispatch);

export default connect(null, mapDispatchToProps)(CreateMultipleModal);
