import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'bookNumber',
    'checkNumber',
    'bankAccount',
];

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: typeof record[key] !== 'undefined' ? record[key] : '',
        }), {});

        values.bankAccountId = values.bankAccount ? values.bankAccount.id.toString() : '';
        delete values.bankAccount;
        return values;
    },
    validationSchema: () => Yup.object().shape({
        bookNumber: Yup.string()
            .required('Campo obligatorio'),
        checkNumber: Yup.string()
            .required('Campo obligatorio'),
        bankAccountId: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            await onSubmit(values);
        } catch (error) {
            logger.error('Error updating bankCheck:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
