import { isValidElementType } from 'react-is';

function createElementTypePropTypeChecker(isRequired) {
    return (props, propName, componentName) => {
        const prop = props[propName];
        if (prop) {
            if (!isValidElementType(prop)) {
                throw new Error(`Invalid prop '${propName}' supplied to '${componentName}': the prop is not a valid React component`);
            }
        } else if (isRequired) {
            throw new Error(`Missing prop '${propName}' supplied to '${componentName}'`);
        }
    };
}

const elementTypePropTypeChecker = createElementTypePropTypeChecker(false);
elementTypePropTypeChecker.isRequired = createElementTypePropTypeChecker(true);

export default elementTypePropTypeChecker;
