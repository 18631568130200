import baseApi from './base';
import { ACCOUNTING_PAYMENT_TYPES_PATH, ACCOUNTING_PAYMENT_TYPE_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(
    ACCOUNTING_PAYMENT_TYPES_PATH,
    { queryParams: filters },
);

const getDetails = async (id) => {
    const url = ACCOUNTING_PAYMENT_TYPE_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = ACCOUNTING_PAYMENT_TYPE_PATH.replace(':id', id);
    return baseApi.put(url, { accounting_payment_type: attributes });
};

const create = async attributes => baseApi.post(
    ACCOUNTING_PAYMENT_TYPES_PATH,
    { accounting_payment_type: attributes },
);

const deletee = async (id) => {
    const url = ACCOUNTING_PAYMENT_TYPE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    update,
    create,
    delete: deletee,
};
