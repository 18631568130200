import { AbilityBuilder, Ability } from '@casl/ability';

const ability = new Ability([]);

const containsRole = (user, role) => user && user.roles && user.roles.indexOf(role) > -1;
const isAdmin = user => containsRole(user, 'administrator');
// const isRecep = user => containsRole(user, 'recepcionist');
// const isKine = user => containsRole(user, 'kinesiologist');

export const updateAbilityForUser = (user) => {
    const { rules, can/* , cannot */ } = AbilityBuilder.extract();

    if (isAdmin(user)) {
        can('filterActive', 'User');
        can('filterActive', 'Office');
        can('filterActive', 'Pathology');
        can('filterActive', 'Procedure');
        can('filterActive', 'Provider');
        can('create', 'User');
        can('edit', 'User');
        can('readLogs', 'User');
        can('resetPassword', 'User');
        can('activate', 'User');
        can('create', 'Procedure');
        can('edit', 'Procedure');
        can('activate', 'Procedure');
        can('create', 'Pathology');
        can('edit', 'Pathology');
        can('activate', 'Pathology');
        can('create', 'Provider');
        can('edit', 'Provider');
        can('activate', 'Provider');
        can('readLogs', 'BankAccount');
        can('create', 'BankAccount');
        can('edit', 'BankAccount');
        can('readLogs', 'BankCheck');
        can('create', 'BankCheck');
        can('edit', 'BankCheck');
        can('delete', 'BankCheck');
        can('readLogs', 'AccountingChart');
        can('create', 'AccountingChart');
        can('edit', 'AccountingChart');
        can('readLogs', 'AccountingPaymentType');
        can('create', 'AccountingPaymentType');
        can('edit', 'AccountingPaymentType');
        can('delete', 'AccountingPaymentType');
        can('readLogs', 'AccountingMovement');
        can('create', 'AccountingMovement');
        can('edit', 'AccountingMovement');
        can('delete', 'AccountingMovement');
    }

    ability.update(rules);
};

updateAbilityForUser();

export default ability;
