import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import Filter from './Filter';
import Table from './Table.redux';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { label: 'Actividad' },
];

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilters = this.updateFilters.bind(this);
    }

    updateFilters(newFilters) {
        const { getLogs, filters } = this.props;
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getLogs(updatedFilters);
    }

    render() {
        const { filters } = this.props;
        return (
            <div>
                <Breadcrumb paths={BREADCRUMB} />
                <Filter
                    filters={filters}
                    onChange={this.updateFilters}
                />
                <Table
                    filters={filters}
                    onFetchData={this.updateFilters}
                />
            </div>
        );
    }
}

IndexPage.propTypes = {
    getLogs: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        offset: PropTypes.number,
        limit: PropTypes.number,
        search: PropTypes.string,
    }),
};

IndexPage.defaultProps = {
    filters: {
        offset: 0,
        limit: 20,
        search: '',
    },
};

export default IndexPage;
