export { default } from './Table';

export const navigateToDetail = (baseUrl, element, history) => {
    const url = baseUrl.replace(':id', element.id);
    history.push(url);
};

export const navigateToIndex = (baseUrl, history) => {
    history.push(baseUrl);
};

export const navigateToDetailsProps = (baseUrl, history) => (state, rowInfo) => {
    if (!rowInfo) {
        return false;
    }

    return {
        onClick: () => navigateToDetail(baseUrl, rowInfo.original, history),
        className: 'cursor-pointer',
    };
};
