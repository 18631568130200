import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Can from '../../Can';
import FilterActive from '../../Table/FilterActive';
import FilterSearch from '../../Table/FilterSearch';

const Filter = ({ filters, onChange }) => (
    <Row className="mb-2">
        <Col xs md="3">
            <FilterSearch
                onChange={onChange}
            />
        </Col>
        <Can do="filterActive" on="Procedure">
            <Col xs md="3">
                <FilterActive
                    defaultValue={filters.active}
                    onChange={onChange}
                />
            </Col>
        </Can>
    </Row>
);

Filter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        active: PropTypes.string,
    }).isRequired,
};

export default Filter;
