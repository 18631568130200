import createLogger from 'logging';
import { getCRUD, search } from './common';
import patientsApi from '../api/patients';
import { RESOURCE, PATIENT_TREATMENTS, PATIENT_TREATMENTS_LOADING } from './index';

const logger = createLogger('PatientAction');

const getTreatments = id => async (dispatch) => {
    dispatch({
        type: PATIENT_TREATMENTS_LOADING,
        id,
    });
    try {
        const response = await patientsApi.getTreatments(id);
        dispatch({
            type: PATIENT_TREATMENTS,
            id,
            payload: response.data,
        });
    } catch (error) {
        logger.error(`${RESOURCE.PATIENTS} getTreatments error:`, error);
        dispatch({
            type: PATIENT_TREATMENTS,
            id,
            error,
        });
    }
};

export default {
    ...getCRUD(RESOURCE.PATIENTS, patientsApi),
    search: search(RESOURCE.PATIENTS, patientsApi),
    getTreatments,
};
