import React from 'react';
import FilterSelectRemote from './FilterSelectRemote';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: `${record.bankName} / ${record.accountNumber}`,
    }
));

const FilterBankAccount = props => (
    <FilterSelectRemote
        {...props}
        recordsToOptions={recordsToOptions}
        emptyOption={{ value: '', label: 'Cuentas de banco: Todas' }}
        identifier="bankAccount"
    />
);

export default FilterBankAccount;
