import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoClippy } from 'react-icons/go';
import LogsModal from '../LogsModal';

const ButtonLogsModal = ({ filters, title, ...otherProps }) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-dark" {...otherProps}>
                <GoClippy />
                {' '}
                Actividad
            </Button>
            <LogsModal
                show={modalShow}
                title={title}
                onHide={() => setModalShow(false)}
                filters={filters}
            />
        </div>
    );
};

ButtonLogsModal.propTypes = {
    filters: PropTypes.shape({}),
    title: PropTypes.string,
};

ButtonLogsModal.defaultProps = {
    filters: {},
    title: undefined,
};

export default ButtonLogsModal;
