import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { PATHOLOGY } from '../../../constants/routes';
import CellActive from '../../Table/CellActive';

const COLUMNS = [
    { Header: 'Lesión', accessor: 'injury', Cell: CellActive },
    { Header: 'Parte del cuerpo', accessor: 'bodyPart', minWidth: 120 },
    { Header: 'Sección del cuerpo', accessor: 'bodySection', minWidth: 120 },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(PATHOLOGY, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
