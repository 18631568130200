import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterSearch from '../../Table/FilterSearch';

const Filter = ({ onChange }) => (
    <Row className="mb-2">
        <Col xs md="3">
            <FilterSearch
                onChange={onChange}
            />
        </Col>
    </Row>
);

Filter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default Filter;
