import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/procedures';
import ButtonActivate from './ButtonActivate';

const mapDispatchToProps = dispatch => bindActionCreators({
    activate: actions.activate,
    deactivate: actions.deactivate,
}, dispatch);

export default connect(null, mapDispatchToProps)(ButtonActivate);
