import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/logs';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.logs;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { getLogs: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
