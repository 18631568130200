import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: '1', label: '+' },
    { value: '-1', label: '-' },
];

const InputSign = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputSign;
