import baseApi from './base';
import { OFFICES_PATH, OFFICE_PATH, OFFICE_ACTIVE_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(OFFICES_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = OFFICE_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = OFFICE_PATH.replace(':id', id);
    return baseApi.put(url, { office: attributes });
};

const create = async attributes => baseApi.post(OFFICES_PATH, { office: attributes });

const activate = async (id) => {
    const url = OFFICE_ACTIVE_PATH.replace(':id', id);
    return baseApi.post(url);
};

const deactivate = async (id) => {
    const url = OFFICE_ACTIVE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    update,
    create,
    activate,
    deactivate,
};
