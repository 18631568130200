import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../actions/bankAccounts';
import FilterBankAccount from './FilterBankAccount';
import { mapIndexStateToProps } from '../../utils/apiStateToProps';
import getNestedValue from '../../utils/getNestedValue';

const mapStateToProps = state => mapIndexStateToProps(getNestedValue(state, 'bankAccounts.options'));
const mapDispatchToProps = dispatch => bindActionCreators(
    { getRecords: actions.getOptions },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FilterBankAccount);
