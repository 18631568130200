import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';

const recordsToOptions = (records) => {
    const options = {};
    records.forEach((record) => {
        if (!options[record.subcategory]) {
            options[record.subcategory] = {
                label: record.subcategory,
                options: [],
            };
        }
        options[record.subcategory].options.push({
            value: `${record.id}`,
            label: `${record.name} - ${record.chartNumber}`,
        });
    });
    return Object.values(options);
};

const SelectInputAccountingChart = props => (
    <SelectInputRemote
        {...props}
        recordsToOptions={recordsToOptions}
    />
);

export default SelectInputAccountingChart;
