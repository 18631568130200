import React from 'react';
import FilterSelectRemote from './FilterSelectRemote';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: record.name,
    }
));

const FilterOffice = props => (
    <FilterSelectRemote
        {...props}
        recordsToOptions={recordsToOptions}
        emptyOption={{ value: '', label: 'Oficinas: Todas' }}
        identifier="office"
    />
);

export default FilterOffice;
