import * as Yup from 'yup';
import createLogger from 'logging';
import cache from 'memory-cache';
import bankChecksApi from '../../api/bankChecks';

const cacheClient = new cache.Cache();
const logger = createLogger('Yup check available validator');

const getCacheKey = (checkNumber, bankAccountId, accountingMovementId) => [checkNumber, bankAccountId, accountingMovementId].join('|');

const isValid = (checkNumber, records, bankAccountId, accountingMovementId) => {
    if (!records || records.length === 0) {
        logger.info(`No existe cheque ${checkNumber}`);
        return false;
    }

    const existingBankCheck = records.find(
        r => r.bankAccount && r.bankAccount.id.toString() === bankAccountId,
    );
    if (!existingBankCheck) {
        logger.info(`No existe cheque ${checkNumber} para esa cuenta bancaria ${bankAccountId}`);
        return false;
    }

    if (existingBankCheck.accountingMovement
            && existingBankCheck.accountingMovement.id !== accountingMovementId) {
        logger.info(`Cheque ${checkNumber} asociado a otro movimiento ${existingBankCheck.accountingMovement.id}`);
        return false;
    }

    return true;
};

Yup.addMethod(Yup.string, 'bankCheckAvailable', function bankCheckAvailable(accountingMovementId, bankAccountIdRef, message) {
    return this.test({
        name: 'bankCheckAvailable',
        exclusive: true,
        message: message || 'not available',
        async test(checkNumber) {
            const bankAccountId = this.resolve(bankAccountIdRef);

            if (!checkNumber) {
                return true;
            }

            const cacheKey = getCacheKey(checkNumber, bankAccountId, accountingMovementId);
            const cachedValid = cacheClient.get(cacheKey);
            if (cachedValid !== null) {
                return cachedValid;
            }

            try {
                const response = await bankChecksApi.getIndex({ checkNumber });
                const { records } = response.data;
                const valid = isValid(checkNumber, records, bankAccountId, accountingMovementId);
                cacheClient.put(cacheKey, valid, 5000);
                return valid;
            } catch (error) {
                logger.error('Error:', error);
                return true;
            }
        },
    });
});
