import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { connect, Field } from 'formik';
import SelectAssistance from './SelectAssistance';
import BKInput from '../../BKForm/BKInput';
import { ASSISTANCE_TO_COUNT } from '../../../utils/assistance';
import InputDate from '../../RecordDetails/InputDate';
import FlagButton from './FlagButton';
import SelectInputOffice from './SelectInputOffice.redux';
import SelectSchedule from '../../Users/RecordForm/SelectSchedule.redux';
import SelectKinesiologist from './SelectKinesiologist.redux';

const assistanceCount = (sessions) => {
    if (!sessions) {
        return '-';
    }

    const realizedSessions = sessions.filter(s => ASSISTANCE_TO_COUNT.includes(s.assist));
    return `${realizedSessions.length}/${sessions.length}`;
};

const SessionsInput = ({ formik }) => {
    const { values } = formik;
    let runningSession = 0;
    const getSessionNumer = (session) => {
        if (ASSISTANCE_TO_COUNT.includes(session.assist)) {
            runningSession += 1;
            return runningSession;
        }
        return '-';
    };

    return (
        <>
            <legend>
                Sesiones y Evoluciones (
                <span className="sessions-count">
                    {assistanceCount(values.sessions)}
                </span>
                )
            </legend>
            <Row>
                <Col lg="12">
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Fecha y asistencia</th>
                                <th>Evolución</th>
                                <th>Kinesiólogo y lugar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values.sessions && values.sessions.length > 0)
                                ? values.sessions.map((session, index) => (
                                    <tr key={session.id}>
                                        <td className="wrap-content-column">
                                            <span className="session-counter">
                                                {getSessionNumer(session)}
                                            </span>
                                            <br />
                                            <Field type="hidden" name={`sessions.${index}.id`} />
                                            <FlagButton name={`sessions.${index}.flag`} value={session.flag} />
                                        </td>
                                        <td className="wrap-content-column" style={{ width: '230px' }}>
                                            <div className="d-flex flex-row">
                                                <div className="w-50">
                                                    <BKInput
                                                        name={`sessions.${index}.startTime`}
                                                        component={InputDate}
                                                        className="form-control search-disable-autocomplete"
                                                    />
                                                </div>
                                                <div className="w-50">
                                                    <BKInput
                                                        name={`sessions.${index}.scheduleId`}
                                                        component={SelectSchedule}
                                                        isClearable={false}
                                                    />
                                                </div>
                                            </div>
                                            <BKInput
                                                name={`sessions.${index}.assist`}
                                                component={SelectAssistance}
                                                isClearable={false}
                                            />
                                        </td>
                                        <td>
                                            <BKInput name={`sessions.${index}.evolution`} placeholder="Evolución" as="textarea" rows="3" />
                                        </td>
                                        <td className="wrap-content-column" style={{ width: '250px' }}>
                                            <BKInput name={`sessions.${index}.kinesiologistId`} component={SelectKinesiologist} isClearable={false} />
                                            <BKInput name={`sessions.${index}.officeId`} component={SelectInputOffice} isClearable={false} />
                                        </td>
                                    </tr>
                                ))
                                : null
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

SessionsInput.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            sessions: PropTypes.arrayOf(PropTypes.shape({
                assist: PropTypes.string,
            })),
        }),
    }).isRequired,
};

export default connect(SessionsInput);
