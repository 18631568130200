import dateUtil from './date';
import getNestedValue from './getNestedValue';

export const STATUS = {
    IDLE: 0,
    ISSUED: 1,
    PAID: 2,
    NULL: 3,
    STOP_PAYMENT: 4,
    STOLEN: 5,
};

export const STATUS_MAP = {
    0: 'Sin Movimiento',
    1: 'Emitido',
    2: 'Pagado',
    3: 'Nulo',
    4: 'Orden de no pago',
    5: 'Robado',
};

export const COLOR_MAP = {
    0: '#D3D3D3',
    1: '#1E90FF',
    2: '#228B22',
    3: '#CD5C5C',
    4: '#8B0000',
    5: '#000000',
};

export const OPEN_STATUS = [STATUS.IDLE, STATUS.ISSUED];
export const ACTIVE_STATUS = [STATUS.ISSUED, STATUS.PAID];
export const FLOW_PAID_STATUS = [STATUS.PAID];
export const DOC_PAID_STATUS = [STATUS.IDLE, STATUS.ISSUED, STATUS.PAID];
export const CANCEL_STATUS = [STATUS.NULL, STATUS.STOP_PAYMENT, STATUS.STOLEN];

export const isOpen = ({ status } = {}) => OPEN_STATUS.includes(status);
export const isActive = ({ status } = {}) => ACTIVE_STATUS.includes(status);
export const wasFlowPaid = ({ status } = {}) => FLOW_PAID_STATUS.includes(status);
export const wasDocPaid = ({ status } = {}) => DOC_PAID_STATUS.includes(status);
export const wasCanceled = ({ status } = {}) => CANCEL_STATUS.includes(status);

const removeTime = datetime => datetime.setHours(0, 0, 0, 0);

const compareDateWithToday = (flowDate) => {
    const checkDate = dateUtil.parseDatetime(flowDate);
    removeTime(checkDate);
    const today = new Date();
    removeTime(today);
    return checkDate.getTime() - today.getTime();
};

const isToday = flowDate => flowDate !== undefined && compareDateWithToday(flowDate) === 0;
const beforeToday = flowDate => flowDate !== undefined && compareDateWithToday(flowDate) < 0;

export const isOverdue = (bankCheck) => {
    const flowDate = getNestedValue(bankCheck, 'accountingMovement.flowDate');
    return isOpen(bankCheck) && beforeToday(flowDate);
};

export const overdueToday = (bankCheck) => {
    const flowDate = getNestedValue(bankCheck, 'accountingMovement.flowDate');
    return isOpen(bankCheck) && isToday(flowDate);
};
