import { combineReducers } from 'redux';
import { METHOD, SUB_METHOD, buildActionType } from '../actions';
import { STATUS } from '../constants/api';

export const INITIAL_STATE = {
    status: STATUS.IDLE,
};

export const finish = (action) => {
    const base = {
        updated: new Date(),
        filters: action.filters,
        ...action.id && { id: action.id },
    };

    if (action.error) {
        return {
            ...base,
            status: STATUS.ERROR,
            error: action.error,
        };
    }

    return {
        ...base,
        status: STATUS.COMPLETE,
        data: action.payload,
    };
};

export const loading = (state, action = {}) => {
    const newState = {
        ...state,
        status: STATUS.LOADING,
    };

    if (action.id) {
        newState.id = action.id;
    } else {
        delete newState.id;
    }

    if (action.id !== state.id) {
        delete newState.data;
    }

    return newState;
};

export const indexReducer = (resource) => {
    const loadingAction = buildActionType(resource, METHOD.INDEX, SUB_METHOD.LOADING);
    const finishAction = buildActionType(resource, METHOD.INDEX);
    return (state = INITIAL_STATE, action) => {
        switch (action.type) {
        case loadingAction:
            return loading(state);
        case finishAction:
            return finish(action);
        default:
            return state;
        }
    };
};

export const detailsReducer = (resource) => {
    const loadingAction = buildActionType(resource, METHOD.DETAILS, SUB_METHOD.LOADING);
    const finishAction = buildActionType(resource, METHOD.DETAILS);
    return (state = INITIAL_STATE, action) => {
        switch (action.type) {
        case loadingAction:
            return loading(state, action);
        case finishAction:
            return finish(action);
        default:
            return state;
        }
    };
};

export const crudReducer = resource => combineReducers({
    index: indexReducer(resource),
    details: detailsReducer(resource),
});

export const optionsReducer = (resource) => {
    const loadingAction = buildActionType(resource, METHOD.OPTIONS, SUB_METHOD.LOADING);
    const finishAction = buildActionType(resource, METHOD.OPTIONS);
    return (state = INITIAL_STATE, action) => {
        switch (action.type) {
        case loadingAction:
            return loading(state);
        case finishAction:
            return finish(action);
        default:
            return state;
        }
    };
};

export const searchReducer = (resource) => {
    const loadingAction = buildActionType(resource, METHOD.SEARCH, SUB_METHOD.LOADING);
    const finishAction = buildActionType(resource, METHOD.SEARCH);
    return (state = INITIAL_STATE, action) => {
        switch (action.type) {
        case loadingAction:
            return loading(state);
        case finishAction:
            return finish(action);
        default:
            return state;
        }
    };
};

export const crudAndOptionsReducer = resource => combineReducers({
    index: indexReducer(resource),
    details: detailsReducer(resource),
    options: optionsReducer(resource),
    search: searchReducer(resource),
});
