import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: 'true', label: 'Sí considerar' },
    { value: 'false', label: 'No considerar' },
];

const InputAccountability = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputAccountability;
