import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import InputOfficeType from './InputOfficeType';
import SelectAdministrator from './SelectAdministrator.redux';

class RecordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    static renderBasicData() {
        return (
            <div>
                <BKInputLabeled label="Nombre" name="name" placeholder="Ej: Centro" />
                <BKInputLabeled label="Teléfonos" name="phone" placeholder="Ej: 4832234" />
                <BKInputLabeled label="Horario" name="schedule" placeholder="Ej: Lunes a Viernes: 8:00 - 14:00" />
                <BKInputLabeled label="Encargado" name="responsibleId" component={SelectAdministrator} />
                <BKInputLabeled label="Tipo" name="officeType" component={InputOfficeType} />
                <BKInputLabeled label="Código Imed" name="imedCode" placeholder="Ej: 2" />
            </div>
        );
    }

    static renderSalesData() {
        return (
            <div>
                <legend>Ventas kinesiólogos</legend>
                <BKInputLabeled label="Asignación normal" name="saleAllocationPercentage" placeholder="Ej: 10.75" />
                <BKInputLabeled label="Particular/KTR/piso pélvico" name="saleAllocationSpecialPercentage" placeholder="Ej: 33" />
                <BKInputLabeled label="Retención" name="saleRetentionPercentage" placeholder="Ej: 50" />
            </div>
        );
    }

    static renderAddressData() {
        return (
            <div>
                <BKInputLabeled label="Dirección" name="address" placeholder="Ej: Av. Suecia 243" />
                <BKInputLabeled label="Comuna" name="borough" placeholder="Ej: Providencia" />
                <BKInputLabeled label="Ciudad" name="city" placeholder="Ej: Santiago" />
                <BKInputLabeled label="Región" name="state" placeholder="Ej: Región Metropolitana" />
                <BKInputLabeled label="País" name="country" placeholder="Ej: Chile" />
            </div>
        );
    }

    render() {
        const { onCancel, isSubmitting } = this.props;
        return (
            <BKForm>
                <Modal.Body>
                    <Container>
                        {this.renderAlert()}
                        <Row>
                            <Col lg="6">
                                {RecordForm.renderBasicData()}
                                {RecordForm.renderSalesData()}
                            </Col>
                            <Col lg="6">
                                {RecordForm.renderAddressData()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancel} size="sm" variant="outline-secondary">Cancelar</Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

RecordForm.defaultProps = {
    status: {},
    onCancel: () => { },
    isSubmitting: false,
};

export default RecordForm;
