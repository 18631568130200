import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/users';
import SelectAdministrator from './SelectAdministrator';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';
import getNestedValue from '../../../utils/getNestedValue';

const mapStateToProps = state => mapIndexStateToProps(getNestedValue(state, 'users.optionsAdmin'));
const mapDispatchToProps = dispatch => bindActionCreators({
    getRecords: actions.getOptionsAdmin,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectAdministrator);
