import React from 'react';
import PropTypes from 'prop-types';
import currency from '../../../utils/currency';

const MONTHS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const CALCULATED = ['sum', 'avg'];

const StatementAmountsRow = (props) => {
    const {
        title, amounts, rowClass, titleClass, monthsClass, calculatedClass, onMonthClick,
    } = props;
    return (
        <tr className={rowClass}>
            <td className={titleClass}>
                {title}
            </td>
            {MONTHS.map(month => (
                <td key={month} className={monthsClass}>
                    <div
                        onClick={() => onMonthClick(month)}
                        onKeyPress={() => onMonthClick(month)}
                        role="presentation"
                        className="cursor-pointer"
                    >
                        {currency(amounts[month])}
                    </div>
                </td>
            ))}
            {CALCULATED.map(calculatedKey => (
                <td key={calculatedKey} className={calculatedClass}>
                    {currency(amounts[calculatedKey])}
                </td>
            ))}
        </tr>
    );
};

StatementAmountsRow.propTypes = {
    title: PropTypes.string.isRequired,
    amounts: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        3: PropTypes.number,
        4: PropTypes.number,
        5: PropTypes.number,
        6: PropTypes.number,
        7: PropTypes.number,
        8: PropTypes.number,
        9: PropTypes.number,
        10: PropTypes.number,
        11: PropTypes.number,
        12: PropTypes.number,
        sum: PropTypes.number,
        avg: PropTypes.number,
    }).isRequired,
    rowClass: PropTypes.string,
    titleClass: PropTypes.string,
    monthsClass: PropTypes.string,
    calculatedClass: PropTypes.string,
    onMonthClick: PropTypes.func,
};

StatementAmountsRow.defaultProps = {
    rowClass: '',
    titleClass: '',
    monthsClass: '',
    calculatedClass: '',
    onMonthClick: () => { },
};

export default StatementAmountsRow;
