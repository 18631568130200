import commonParseOptions from './parseOptions';
import { getFullName } from '../../../utils/user';
import { PATIENT } from '../../../constants/routes';

const parseOptions = ({ error, loading, records }) => commonParseOptions({
    resource: 'Pacientes',
    error,
    loading,
    records,
    resourceToLabel: getFullName,
    resourceUrl: PATIENT,
});

export default parseOptions;
