import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import StateForm from './StateForm';

const logger = createLogger('StateForm');
const ATTRIBUTES = [
    'state',
];

const StateFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => ATTRIBUTES.reduce((obj, key) => ({
        ...obj,
        [key]: (record[key] || record[key] === 0) ? `${record[key]}` : '',
    }), {}),
    validationSchema: () => Yup.object().shape({
        state: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { record, updateRecord } = props;
        try {
            await updateRecord(record.id, values);
        } catch (error) {
            logger.error('Error updating bankCheck:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'StateForm',
})(StateForm);

export default StateFormik;
