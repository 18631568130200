import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import CellActive from '../../Table/CellActive';
import { USER } from '../../../constants/routes';
import { translateRoles } from '../../../utils/roles';

export const translateRolesCell = params => translateRoles(params.value);

const COLUMNS = [
    { Header: 'Apellido', accessor: 'lastName', Cell: CellActive },
    { Header: 'Nombre', accessor: 'firstName', minWidth: 80 },
    { Header: 'Email', accessor: 'email', minWidth: 120 },
    { Header: 'Celular', accessor: 'cellPhone', minWidth: 60 },
    {
        Header: 'Rol', accessor: 'roles', Cell: translateRolesCell, sortable: false, minWidth: 65,
    },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(USER, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
