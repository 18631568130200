import React from 'react';
import PropTypes from 'prop-types';

const LinkEmail = ({ email }) => {
    if (!email) {
        return null;
    }

    return (
        <a href={`mailto:${email}`}>
            {email}
        </a>
    );
};

LinkEmail.propTypes = {
    email: PropTypes.string,
};

LinkEmail.defaultProps = {
    email: undefined,
};

export default LinkEmail;
