import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import dateUtil from '../../../utils/date';
import { isOverdue, overdueToday } from '../../../utils/bankCheck';

const getAlertInfo = (bankCheck) => {
    if (isOverdue(bankCheck)) {
        return {
            id: bankCheck.id,
            text: 'Atrasado',
            textClassName: 'text-danger',
        };
    }

    if (overdueToday(bankCheck)) {
        return {
            id: bankCheck.id,
            text: 'Hoy',
            textClassName: 'text-info',
        };
    }

    return undefined;
};

const getTooltip = alertInfo => (
    <Tooltip id={`tooltip-future-${alertInfo.id}`}>
        {alertInfo.text}
    </Tooltip>
);

const CellCheckDate = ({ value, original }) => {
    if (value === undefined || value === '') {
        return '-';
    }

    const date = dateUtil.formatDate(value);
    const alertInfo = original && getAlertInfo(original);
    if (alertInfo) {
        return (
            <OverlayTrigger overlay={getTooltip(alertInfo)}>
                <span className={alertInfo.textClassName}>
                    {date}
                </span>
            </OverlayTrigger>
        );
    }
    return date;
};

export default CellCheckDate;
