import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoPlus } from 'react-icons/go';
import CreateMultipleModal from './CreateMultipleModal.redux';

const ButtonCreateMultipleModal = ({ onSuccess, ...otherProps }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const onSucessInternal = (records) => {
        setModalShow(false);
        onSuccess(records);
    };

    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-info" {...otherProps}>
                <GoPlus />
                {' '}
                Agregar talonario
            </Button>
            <CreateMultipleModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSuccess={onSucessInternal}
            />
        </div>
    );
};

ButtonCreateMultipleModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
};

export default ButtonCreateMultipleModal;
