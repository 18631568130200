import React from 'react';
import SelectInput from './SelectInput';

const OPTIONS = [
    { value: 'Banmedica', label: 'Banmedica' },
    { value: 'Colmena', label: 'Colmena' },
    { value: 'Consalud', label: 'Consalud' },
    { value: 'Cruz Blanca', label: 'Cruz Blanca' },
    { value: 'Fundación Banco Estado', label: 'Fundación Banco Estado' },
    { value: 'Masvida', label: 'Masvida' },
    { value: 'Vida Tres', label: 'Vida Tres' },
    { value: 'Fonasa', label: 'Fonasa' },
    { value: 'Otro/No Tiene', label: 'Otro/No Tiene' },
];

const InputHealthSchema = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputHealthSchema;
