import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';

const PATIENT_AND_ACCOUNTABLE_TYPE = 0;
const FIVE_MINS = 5 * 60 * 1000;
const NON_OFFICE = { name: 'Todas las oficinas', id: '' };

const shouldUpdate = (loading, error, updated) => {
    if (loading) {
        return false;
    }

    if (error || !updated) {
        return true;
    }

    const timeLapsed = new Date() - updated;
    return timeLapsed > FIVE_MINS;
};

const isOptionValid = (currentOffice, options) => {
    if (typeof options === 'undefined') {
        return true;
    }

    const valid = !!options.find(option => option.id === currentOffice.id);
    return valid;
};

class NavOffice extends React.Component {
    constructor(props) {
        super(props);
        this.loadOptions();

        this.onSelectOption = this.onSelectOption.bind(this);
    }

    onSelectOption(newOffice) {
        const { setCurrent } = this.props;
        setCurrent(newOffice);
    }

    getPlaceholder() {
        const { loading, error } = this.props;
        if (loading) {
            return 'Cargando...';
        }

        if (error) {
            return 'Error';
        }

        return undefined;
    }

    getOptions() {
        const { records } = this.props;
        if (!records) {
            return records;
        }

        return records.filter(record => record.officeType === PATIENT_AND_ACCOUNTABLE_TYPE);
    }

    loadOptions() {
        const {
            getRecords, loading, error, updated,
        } = this.props;
        if (shouldUpdate(loading, error, updated)) {
            getRecords();
        }
    }

    printOptions() {
        const options = [
            NON_OFFICE,
            ...(this.getOptions() || []),
        ];

        return options.map(option => (
            <Dropdown.Item
                key={option.id}
                onClick={() => this.onSelectOption(option)}
            >
                {option.name}
            </Dropdown.Item>
        ));
    }

    printCurrentOffice() {
        const { loading, setCurrent } = this.props;
        let { currentOffice } = this.props;
        const options = this.getOptions();

        if (!isOptionValid(currentOffice, options, loading)) {
            currentOffice = NON_OFFICE;
            setCurrent(currentOffice);
        }

        return currentOffice.name;
    }

    render() {
        return (
            <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link}>
                    {this.printCurrentOffice()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.printOptions()}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

NavOffice.propTypes = {
    getRecords: PropTypes.func.isRequired,
    setCurrent: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({})),
    updated: PropTypes.instanceOf(Date),
    currentOffice: PropTypes.shape({}),
};

NavOffice.defaultProps = {
    error: undefined,
    records: undefined,
    updated: undefined,
    currentOffice: '',
};

export default NavOffice;
