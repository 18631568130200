import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const getOptionForValue = (value, options) => options.find(opt => opt.value === value);

const FilterSelect = (props) => {
    const {
        identifier, defaultValue, onChange, options,
    } = props;
    const defaultOptionSelected = getOptionForValue(defaultValue, options);
    const handleChange = (selectedOption) => {
        onChange({ [identifier]: selectedOption.value });
    };

    return (
        <Select
            {...props}
            value={defaultOptionSelected}
            onChange={handleChange}
        />
    );
};

FilterSelect.propTypes = {
    identifier: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
    })).isRequired,
};

FilterSelect.defaultProps = {
    defaultValue: '',
    onChange: () => { },
};

export default FilterSelect;
