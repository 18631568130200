import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import StatementDetailsTable from './StatementDetailsTable.redux';
import dateUtil from '../../../utils/date';

const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

const StatementDetailsModal = ({
    show,
    onHide,
    accountingChart,
    month,
    year,
    office,
    filters,
    getStatementDetails,
    clearStatementDetails,
}) => {
    const updateFilters = (newFilters) => {
        const { beginning, end } = dateUtil.getMonthLimits(year, month);
        const updatedFilters = {
            accountingChart: accountingChart.id,
            fromDocDate: dateUtil.formatDateTime(beginning, '%Y-%m-%d'),
            toDocDate: dateUtil.formatDateTime(end, '%Y-%m-%d'),
            office: office ? office.id : '',
            ...filters,
            ...newFilters,
        };
        getStatementDetails(updatedFilters);
    };

    const intermediateOnHide = () => {
        onHide();
        clearStatementDetails();
    };

    let officeTitle = 'Todas las oficinas';
    if (office) {
        officeTitle = office.name;
    }

    return (
        <Modal
            show={show}
            onHide={intermediateOnHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${accountingChart.name} / ${MONTHS[month - 1]} ${year}`}
                    <br />
                    {officeTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <StatementDetailsTable
                                filters={filters}
                                onFetchData={updateFilters}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={intermediateOnHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
};

StatementDetailsModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    getStatementDetails: PropTypes.func.isRequired,
    clearStatementDetails: PropTypes.func.isRequired,
    show: PropTypes.bool,
    accountingChart: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    month: PropTypes.string,
    year: PropTypes.string,
    office: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    filters: PropTypes.shape({}),
};

StatementDetailsModal.defaultProps = {
    show: false,
    accountingChart: {},
    office: undefined,
    month: '',
    year: '',
    filters: {
        offset: 0,
        limit: 10,
    },
};

export default StatementDetailsModal;
