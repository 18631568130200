import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import './NavSearch.css';
import ReactSelect from 'react-select';
import userOptions from './userOptions';
import patientOptions from './patientOptions';

const HIDE_ARROW_FOR_OPTIONS = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
};

const cleanText = inputValue => inputValue && inputValue.trim();
const NO_OPTS_MESSAGE = ({ inputValue }) => {
    const cleanInput = cleanText(inputValue);
    if (!cleanInput) {
        return 'Empiece a escribir para buscar';
    }
    return 'No se ha encontrado nada';
};

const NavSearch = ({
    history,
    users,
    searchUsers,
    patients,
    searchPatients,
}) => {
    const [search, setSearch] = React.useState('');

    const bouncedOnInputChange = debounce(350, (inputValue) => {
        const cleanInput = cleanText(inputValue);
        if (!cleanInput) {
            return;
        }

        setSearch(cleanInput);
        searchUsers(cleanInput);
        searchPatients(cleanInput);
    });

    const onOptionSelected = (option) => {
        setSearch('');
        const url = option.value;
        history.push(url);
    };

    let options = [];

    if (search) {
        options = [
            ...patientOptions(patients),
            ...userOptions(users),
        ];
    }

    return (
        <ReactSelect
            placeholder="Buscar"
            className="NavSearch"
            value=""
            noOptionsMessage={NO_OPTS_MESSAGE}
            components={HIDE_ARROW_FOR_OPTIONS}
            onInputChange={bouncedOnInputChange}
            onChange={onOptionSelected}
            options={options}
            filterOption={() => true}
        />
    );
};

NavSearch.propTypes = {
    history: PropTypes.shape({}).isRequired,
    users: PropTypes.shape({}),
    searchUsers: PropTypes.func.isRequired,
    patients: PropTypes.shape({}),
    searchPatients: PropTypes.func.isRequired,
};

NavSearch.defaultProps = {
    users: {},
    patients: {},
};

export default NavSearch;
