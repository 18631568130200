import createLogger from 'logging';
import { getCRUD, getActivations, search } from './common';
import usersApi from '../api/users';
import {
    RESOURCE, USERS_OPTIONS_ADMIN, USERS_OPTIONS_ADMIN_LOADING, USERS_OPTIONS_KINESIOLOGIST, USERS_OPTIONS_KINESIOLOGIST_LOADING,
} from './index';

const logger = createLogger('UserAction');

const getOptionsAdmin = () => async (dispatch) => {
    dispatch({
        type: USERS_OPTIONS_ADMIN_LOADING,
    });
    try {
        const response = await usersApi.getIndex({
            active: true, role: 'Administrator', sort: 'first_name', limit: 100,
        });
        dispatch({
            type: USERS_OPTIONS_ADMIN,
            payload: response.data,
        });
    } catch (error) {
        logger.error('getOptionsAdmin error:', error);
        dispatch({
            type: USERS_OPTIONS_ADMIN,
            error,
        });
    }
};

const getOptionsKinesiologist = () => async (dispatch) => {
    dispatch({
        type: USERS_OPTIONS_KINESIOLOGIST_LOADING,
    });
    try {
        const response = await usersApi.getIndex({
            active: true, role: 'Kinesiologist', sort: 'first_name', limit: 100,
        });
        dispatch({
            type: USERS_OPTIONS_KINESIOLOGIST,
            payload: response.data,
        });
    } catch (error) {
        logger.error('getOptionsKinesiologist error:', error);
        dispatch({
            type: USERS_OPTIONS_KINESIOLOGIST,
            error,
        });
    }
};

export default {
    ...getCRUD(RESOURCE.USERS, usersApi),
    ...getActivations(RESOURCE.USERS, usersApi),
    search: search(RESOURCE.USERS, usersApi),
    getOptionsAdmin,
    getOptionsKinesiologist,
};
