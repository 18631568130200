import createLogger from 'logging';
import {
    RESOURCE,
    ACCOUNTING_MOVEMENTS_STATEMENT,
    ACCOUNTING_MOVEMENTS_STATEMENT_LOADING,
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS,
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_LOADING,
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_CLEAR,
} from './index';
import { getCRUD } from './common';
import accountingMovementsApi from '../api/accountingMovements';

const logger = createLogger('AccountingMovementAction');

const getStatement = (filters = {}) => async (dispatch) => {
    dispatch({
        type: ACCOUNTING_MOVEMENTS_STATEMENT_LOADING,
    });
    try {
        const response = await accountingMovementsApi.getStatement(filters);
        dispatch({
            type: ACCOUNTING_MOVEMENTS_STATEMENT,
            payload: response.data,
            filters,
        });
    } catch (error) {
        logger.error('getStatement error:', error);
        dispatch({
            type: ACCOUNTING_MOVEMENTS_STATEMENT,
            error,
        });
    }
};

const getStatementDetails = filters => async (dispatch) => {
    dispatch({
        type: ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_LOADING,
    });
    try {
        const response = await accountingMovementsApi.getIndex(filters);
        dispatch({
            type: ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS,
            payload: response.data,
            filters,
        });
    } catch (error) {
        logger.error('getStatementDetails error:', error);
        dispatch({
            type: ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS,
            error,
        });
    }
};

const clearStatementDetails = () => ({
    type: ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_CLEAR,
});

const createCache = {
    key: `${RESOURCE.ACCOUNTING_MOVEMENTS}/created`,
    mapping: ({ officeId, bankAccountId }) => ({
        office: { id: officeId },
        bankAccount: { id: bankAccountId },
    }),
};

const indexCache = {
    key: `${RESOURCE.ACCOUNTING_MOVEMENTS}/index`,
    mapping: ({
        fromFlowDate, toFlowDate, office, bankAccount,
    }) => ({
        fromFlowDate,
        toFlowDate,
        ...(office ? { office } : {}),
        ...(bankAccount ? { bankAccount } : {}),
    }),
};

export default {
    ...getCRUD(RESOURCE.ACCOUNTING_MOVEMENTS, accountingMovementsApi, { createCache, indexCache }),
    getStatement,
    getStatementDetails,
    clearStatementDetails,
};
