import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import currency from '../../../utils/currency';

const getIsFuture = original => original && original.futureFlow;
const getTooltip = original => (
    <Tooltip id={`tooltip-future-${original.id}`}>
        Pendiente
    </Tooltip>
);

const CellFutureBalance = ({ value, original }) => {
    if (value === undefined) {
        return '';
    }

    const amount = currency(value);
    const isFuture = getIsFuture(original);
    if (isFuture) {
        return (
            <OverlayTrigger overlay={getTooltip(original)}>
                <span className="text-secondary">
                    {amount}
                </span>
            </OverlayTrigger>
        );
    }
    return amount;
};

export default CellFutureBalance;
