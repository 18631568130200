import baseApi from './base';
import { LOGIN_PATH, LOGOUT_PATH, PASSWORD_PATH } from '../constants/api';

const AUTH_HEADER = 'authorization';

const getAuthokenFromHeaders = (headers) => {
    if (!headers || !headers[AUTH_HEADER]) {
        throw new Error(`Header ${AUTH_HEADER} not present in response`);
    }
    const authHeader = headers[AUTH_HEADER];
    const authHeaderParts = authHeader.split(' ');

    if (authHeaderParts.length < 2) {
        throw new Error(`Token not found in header ${AUTH_HEADER}`);
    }
    const token = authHeaderParts[1];
    return token;
};

const logIn = async (username, password) => {
    const data = {
        user: {
            rut: username,
            password,
        },
    };
    const response = await baseApi.post(LOGIN_PATH, data);
    return getAuthokenFromHeaders(response.headers);
};

const logOut = async () => baseApi.delete(LOGOUT_PATH);

const updatePassword = async (currentPassword, password, passwordConfirmation) => {
    const data = {
        currentPassword,
        password,
        passwordConfirmation,
    };
    return baseApi.put(PASSWORD_PATH, data);
};

export default {
    logIn,
    logOut,
    updatePassword,
};
