import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import RutControl from '../../RutControl';

class RecordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    static renderPersonalData() {
        return (
            <div>
                <legend>Datos Personales</legend>
                <BKInputLabeled label="Nombreo razón social" name="name" placeholder="Ej: Homecenter" />
                <BKInputLabeled label="Rut" name="rut" placeholder="Ej: 21.993.845" component={RutControl} />
                <BKInputLabeled label="Área" name="area" placeholder="Ej: Electricidad" />
                <BKInputLabeled label="Notas" name="notes" placeholder="Datos relevantes" as="textarea" rows="3" />
            </div>
        );
    }

    static renderContactData() {
        return (
            <div>
                <legend>Contacto</legend>
                <BKInputLabeled label="Persona" name="contact" placeholder="Ej: Miguel Guerrero" />
                <BKInputLabeled label="Email" name="email" placeholder="Ej: miguel_g@gmail.com" />
                <BKInputLabeled label="Teléfono celular" name="cellPhone" placeholder="Ej: 998871455" />
                <BKInputLabeled label="Teléfono de oficina" name="officePhone" placeholder="Ej: 226858821" />
                <BKInputLabeled label="Dirección" name="address" placeholder="Ej: Av. Suecia 243" />
                <BKInputLabeled label="Comuna" name="borough" placeholder="Ej: Providencia" />
                <BKInputLabeled label="Ciudad" name="city" placeholder="Ej: Santiago" />
            </div>
        );
    }

    render() {
        const { onCancel, isSubmitting } = this.props;
        return (
            <BKForm>
                <Modal.Body>
                    <Container>
                        {this.renderAlert()}
                        <Row>
                            <Col lg="6">
                                {RecordForm.renderPersonalData()}
                            </Col>
                            <Col lg="6">
                                {RecordForm.renderContactData()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancel} size="sm" variant="outline-secondary">Cancelar</Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

RecordForm.defaultProps = {
    status: {},
    onCancel: () => { },
    isSubmitting: false,
};

export default RecordForm;
