const addThousandsSeparator = (number, separator = '.', decimal = ',') => {
    if (!number && number !== 0) {
        return '';
    }

    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join(decimal);
};

const format = (amount, symbol = '$', separator) => {
    if (!amount && amount !== 0) {
        return '';
    }

    return `${symbol}${addThousandsSeparator(amount, separator)}`;
};

export default format;
