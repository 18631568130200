import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCreateModal from '../CreateModal/ButtonCreateModal';
import CreateMultipleModal from '../CreateMultipleModal/ButtonCreateMultipleModal';
import { navigateToDetail } from '../../Table';
import { BANK_CHECK } from '../../../constants/routes';

const Actions = ({ history, onMultipleCreated }) => {
    const onSuccess = (record) => {
        navigateToDetail(BANK_CHECK, record, history);
    };

    const onSuccessMultiple = (records) => {
        if (!records) {
            return;
        }

        const record = records[0];
        if (!record) {
            return;
        }

        const filter = {
            offset: 0,
            search: record.bookNumber,
            state: '',
            bankAccount: `${record.bankAccount.id}`,
        };
        onMultipleCreated(filter);
    };

    return (
        <Row className="mb-3">
            <Col>
                <ButtonCreateModal
                    className="mr-1"
                    onSuccess={onSuccess}
                />
                <CreateMultipleModal
                    className="mr-1"
                    onSuccess={onSuccessMultiple}
                />
            </Col>
        </Row>
    );
};

Actions.propTypes = {
    history: PropTypes.shape({}),
    onMultipleCreated: PropTypes.func,
};

Actions.defaultProps = {
    history: {},
    onMultipleCreated: () => { },
};

export default Actions;
