import { DOCTOR_TREATMENTS, DOCTOR_TREATMENTS_LOADING } from '../../actions/index';
import { INITIAL_STATE, loading, finish } from '../common';

const treatments = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case DOCTOR_TREATMENTS_LOADING:
        return loading(state);
    case DOCTOR_TREATMENTS:
        return finish(action);
    default:
        return state;
    }
};

export default treatments;
