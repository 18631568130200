import baseApi from './base';
import {
    PATIENTS_PATH, PATIENT_PATH, PATIENTS_UNIQ_PATH, PATIENT_TREATMENTS_PATH,
} from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(PATIENTS_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = PATIENT_PATH.replace(':id', id);
    return baseApi.get(url);
};

const getUniq = async (attribute, value, id) => {
    const queryParams = {
        attribute,
        value,
        ...id && { id },
    };
    return baseApi.get(PATIENTS_UNIQ_PATH, { queryParams });
};

const update = async (id, attributes) => {
    const url = PATIENT_PATH.replace(':id', id);
    return baseApi.put(url, { patient: attributes });
};

const create = async attributes => baseApi.post(PATIENTS_PATH, { patient: attributes });

const getTreatments = async (id) => {
    const url = PATIENT_TREATMENTS_PATH.replace(':id', id);
    return baseApi.get(url);
};

export default {
    getIndex,
    getDetails,
    getUniq,
    update,
    create,
    getTreatments,
};
