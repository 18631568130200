import * as Yup from 'yup';
import createLogger from 'logging';
import cache from 'memory-cache';
import usersApi from '../../api/users';

const cacheClient = new cache.Cache();
const logger = createLogger('Yup uniq validator');

const keepOnlyDigits = text => text.replace(/\D+/g, '');
const getCacheKey = (value, attribute, id) => [value, attribute, id].join('|');

Yup.addMethod(Yup.string, 'userUniq', function validateUniq(attribute, id, message) {
    return this.test({
        name: 'userUniq',
        exclusive: true,
        message: message || 'already exists',
        async test(value) {
            if (!value) {
                return true;
            }

            let cleanValue = value;
            if (attribute === 'rut') {
                cleanValue = keepOnlyDigits(value);
            }

            const cacheKey = getCacheKey(cleanValue, attribute, id);
            const cachedValue = cacheClient.get(cacheKey);
            if (cachedValue !== null) {
                return cachedValue;
            }

            try {
                const response = await usersApi.getUniq(attribute, cleanValue, id);
                const valid = response.data.uniq;
                cacheClient.put(cacheKey, valid, 5000);
                return valid;
            } catch (error) {
                logger.error('Error:', error);
                return true;
            }
        },
    });
});
