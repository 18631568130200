import qs from 'qs';

const addQueryString = (url, parameters) => {
    if (!parameters || Object.keys(parameters).length === 0) {
        return url;
    }

    const urlAlreadyHasSearchParams = url.indexOf('?') >= 0;
    const character = urlAlreadyHasSearchParams ? '&' : '?';
    return url + character + qs.stringify(parameters);
};

export default {
    addQueryString,
};
