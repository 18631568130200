import createLogger from 'logging';
import { RESOURCE } from './index';
import { getCRUD } from './common';
import bankChecksApi from '../api/bankChecks';

const logger = createLogger('BankChecks');

const createMultiple = attributes => async () => {
    try {
        const response = await bankChecksApi.createMultiple(attributes);
        return response.data.records;
    } catch (error) {
        logger.error(`${RESOURCE.BANK_CHECKS} create multiple error:`, error);
        throw error;
    }
};

export default {
    ...getCRUD(RESOURCE.BANK_CHECKS, bankChecksApi),
    createMultiple,
};
