import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { ACCOUNTING_CHART } from '../../../constants/routes';
import CellSign from './CellSign';

const COLUMNS = [
    { Header: 'Correlativo', accessor: 'orderNumber', minWidth: 40 },
    { Header: 'Categoría', accessor: 'category' },
    { Header: 'Subcategoría', accessor: 'subcategory' },
    { Header: 'Nombre', accessor: 'name', Cell: CellSign },
    { Header: 'Número', accessor: 'chartNumber', minWidth: 40 },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(ACCOUNTING_CHART, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
