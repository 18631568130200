import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoLock } from 'react-icons/go';
import createLogger from 'logging';
import ConfirmModal, { STATUS } from '../../ConfirmModal';
import usersApi from '../../../api/users';

const logger = createLogger('ButtonResetPassword');

const MESSAGE_QUESTION = '¿Estás seguro que deseas reiniciar la contraseña? Con esto la contraseña será el rut de la persona';
const MESSAGE_LOADING = 'Reiniciando contraseña...';
const MESSAGE_COMPLETED = 'Contraseña reestablecida a su rut, por favor recuerde cambiarla.';
const MESSAGE_ERROR = 'Hubo un error reiniciando la contraseña, por favor intente más tarde';

const ButtonResetPassword = ({ record, ...otherProps }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [status, setStatus] = React.useState(STATUS.IDLE);
    const [message, setMessage] = React.useState(MESSAGE_QUESTION);
    const onSelect = async (confirmed) => {
        if (!confirmed) {
            setModalShow(false);
            setMessage(MESSAGE_QUESTION);
            setStatus(STATUS.IDLE);
            return;
        }

        setStatus(STATUS.LOADING);
        setMessage(MESSAGE_LOADING);
        try {
            await usersApi.resetPassword(record.id);
            setMessage(MESSAGE_COMPLETED);
            setStatus(STATUS.COMPLETED);
        } catch (error) {
            logger.error('Error reseting password', error);
            setMessage(MESSAGE_ERROR);
            setStatus(STATUS.ERROR);
        }
    };

    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-warning" {...otherProps}>
                <GoLock />
                {' '}
                Reiniciar contraseña
            </Button>
            <ConfirmModal
                show={modalShow}
                message={message}
                onSelect={onSelect}
                status={status}
            />
        </div>
    );
};

ButtonResetPassword.propTypes = {
    record: PropTypes.shape({}).isRequired,
};

export default ButtonResetPassword;
