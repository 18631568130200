import jwtDecode from 'jwt-decode';
import createLogger from 'logging';
import { keysToCamel } from './camelCaseConverter';

const logger = createLogger('TokenParser');

const parse = (accessToken) => {
    try {
        if (accessToken) {
            const decodedPayload = jwtDecode(accessToken);
            return keysToCamel(decodedPayload);
        }
    } catch (error) {
        logger.warn(`Error parsing auth token: ${error.message}`);
    }
    return undefined;
};

export default {
    parse,
};
