import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { GoTrashcan } from 'react-icons/go';
import { connect, Field, FieldArray } from 'formik';
import SearchProcedure from './SearchProcedure.redux';

const actionDetails = action => (action?.procedure
    ? `(${action.procedure.code}) ${action.procedure.name}`
    : ''
);

const ActionsInput = ({ formik }) => {
    const { values, setFieldValue, isSubmitting } = formik;
    const actionFields = (action, index) => {
        const onDeleteClick = () => {
            setFieldValue(`actions.${index}._destroy`, true);
        };

        return (
            // eslint-disable-next-line no-underscore-dangle
            <tr key={index} className={action._destroy ? 'd-none' : ''}>
                <td>
                    {actionDetails(action)}
                    <Field type="hidden" name={`actions.${index}.id`} />
                    <Field type="hidden" name={`actions.${index}.procedure.id`} />
                    <Field type="hidden" name={`actions.${index}._destroy`} className="element-destroy" />
                </td>
                <td className="wrap-content-column">
                    <Field
                        type="number"
                        name={`actions.${index}.quantity`}
                        disabled={isSubmitting}
                        style={{ width: '70px' }}
                    />
                </td>
                <td className="wrap-content-column">
                    <OverlayTrigger overlay={<Tooltip>Eliminar</Tooltip>}>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={onDeleteClick}
                        >
                            <GoTrashcan />
                        </Button>
                    </OverlayTrigger>
                </td>
            </tr>
        );
    };
    const onProcedureSelect = arrayHelpers => (newProcedure) => {
        const existingProcedure = values?.actions?.find(
            action => action.procedure.id === newProcedure.id);
        if (existingProcedure) {
            // eslint-disable-next-line no-underscore-dangle
            if (existingProcedure._destroy) {
                const index = values.actions.indexOf(existingProcedure);
                setFieldValue(`actions.${index}._destroy`, false);
            }
            return;
        }
        const newAction = {
            id: '',
            procedure: newProcedure,
            quantity: values?.sessions?.length || 1,
            _destroy: false,
        };
        arrayHelpers.push(newAction);
    };

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={1} className="text-muted text-nowrap">
                <small className="float-md-right">
                    Procedimientos
                </small>
            </Form.Label>
            <Col sm={11}>
                <FieldArray
                    name="actions"
                    render={arrayHelpers => (
                        <div>
                            <SearchProcedure onSelect={onProcedureSelect(arrayHelpers)} />
                            <Table bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Procedimiento</th>
                                        <th>Cantidad</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {(values?.actions?.length > 0)
                                        ? values.actions.map(actionFields)
                                        : (
                                            <tr>
                                                <td colSpan={3} className="font-italic text-center">
                                                    No se han ingresado procedimientos
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )}
                />
            </Col>
        </Form.Group>
    );
};

ActionsInput.propTypes = {
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func,
        isSubmitting: PropTypes.bool,
        values: PropTypes.shape({
            actions: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                quantity: PropTypes.number,
                _destroy: PropTypes.bool,
                procedure: PropTypes.shape({
                    id: PropTypes.number,
                    code: PropTypes.string,
                    name: PropTypes.string,
                }),
            })),
        }),
    }).isRequired,
};

export default connect(ActionsInput);
