import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/doctors';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.doctors.index;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { getDoctors: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
