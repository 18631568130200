import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';
import dateUtil from '../../../utils/date';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: dateUtil.formatTime(record.start),
    }
));

const SelectSchedule = props => (
    <SelectInputRemote
        {...props}
        recordsToOptions={recordsToOptions}
    />
);

export default SelectSchedule;
