import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { GoTrashcan } from 'react-icons/go';
import createLogger from 'logging';
import ConfirmModal, { STATUS } from '../../ConfirmModal';

const logger = createLogger('ButtonDelete');

const QUESTION = '¿Estás seguro que deseas eliminar este cheque? Esta acción no se puede revertir';
const MESSAGE_LOADING = 'Cargando...';
const MESSAGE_ERROR = 'Hubo un error eliminando el cheque, por favor intente más tarde';

const ButtonDelete = ({
    record, deletee, onDeleted, ...otherProps
}) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [status, setStatus] = React.useState(STATUS.IDLE);
    const [message, setMessage] = React.useState(QUESTION);
    const onSelect = async (confirmed) => {
        if (!confirmed) {
            setModalShow(false);
            setMessage(QUESTION);
            setStatus(STATUS.IDLE);
            return;
        }

        setStatus(STATUS.LOADING);
        setMessage(MESSAGE_LOADING);
        try {
            await deletee(record.id);
            setModalShow(false);
            setStatus(STATUS.IDLE);
            onDeleted();
        } catch (error) {
            logger.error('Error deleting bank check', error);
            setMessage(MESSAGE_ERROR);
            setStatus(STATUS.ERROR);
        }
    };

    const renderButton = (enabled) => {
        let moreProps = {};
        if (!enabled) {
            moreProps = {
                disabled: true,
                style: {
                    pointerEvents: 'none',
                },
            };
        }

        return (
            <Button
                onClick={() => setModalShow(true)}
                variant="outline-danger"
                {...moreProps}
                {...otherProps}
            >
                <GoTrashcan />
                {' Eliminar'}
            </Button>
        );
    };

    const renderConditionButton = () => {
        const { accountingMovement } = record;
        const canDelete = !accountingMovement;
        if (canDelete) {
            return renderButton(canDelete);
        }

        return (
            <OverlayTrigger
                overlay={(
                    <Tooltip>
                        No puedes eliminar este cheque porque está asociado al movimiento
                        {' '}
                        {accountingMovement.id}
                    </Tooltip>
                )}
            >
                <span className="d-inline-block">
                    {renderButton(canDelete)}
                </span>
            </OverlayTrigger>
        );
    };

    return (
        <div className="d-inline">
            {renderConditionButton()}
            <ConfirmModal
                show={modalShow}
                message={message}
                onSelect={onSelect}
                status={status}
            />
        </div>
    );
};

ButtonDelete.propTypes = {
    record: PropTypes.shape({}).isRequired,
    deletee: PropTypes.func.isRequired,
    onDeleted: PropTypes.func.isRequired,
};

export default ButtonDelete;
