import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoPencil } from 'react-icons/go';
import EditModal from './EditModal.redux';

const ButtonEditModal = ({ record }) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-primary">
                <GoPencil />
                {' '}
                Editar
            </Button>
            <EditModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                record={record}
            />
        </div>
    );
};

ButtonEditModal.propTypes = {
    record: PropTypes.shape({}).isRequired,
};

export default ButtonEditModal;
