import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const className = (active) => {
    const classes = ['breadcrumb-item'];
    if (active) {
        classes.push('active');
    }
    return classes.join(' ');
};

const Breadcrumb = ({ paths }) => (
    <ol className="breadcrumb">
        {paths.map((item, index) => (
            <li key={item.path + item.label} className={className(index === paths.length - 1)}>
                {item.path ? (<Link to={item.path}>{item.label}</Link>) : item.label}
            </li>
        ))}
    </ol>
);

Breadcrumb.propTypes = {
    paths: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
};

export default Breadcrumb;
