import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Can from '../../Can';
import ButtonLogsModal from '../../Logs/ButtonLogsModal';
import ButtonEditModal from '../EditModal/ButtonEditModal';
import ButtonDelete from './ButtonDelete.redux';
import { navigateToIndex } from '../../Table';
import { ACCOUNTING_PAYMENT_TYPES } from '../../../constants/routes';

const Actions = ({ record, history }) => {
    const onDeleted = () => {
        navigateToIndex(ACCOUNTING_PAYMENT_TYPES, history);
    };
    return (
        <Row className="my-3 smb-3">
            <Col>
                <Can do="readLogs" on="AccountingPaymentType">
                    <ButtonLogsModal
                        className="mr-1"
                        title={record.checkNumber}
                        filters={{ relatedObjectType: 'AccountingPaymentType', relatedObjectId: record.id, limit: 10 }}
                    />
                </Can>
                <Can do="edit" on="AccountingPaymentType">
                    <ButtonEditModal
                        className="mr-1"
                        record={record}
                    />
                </Can>
                <Can do="delete" on="AccountingPaymentType">
                    <ButtonDelete
                        className="mr-1"
                        record={record}
                        onDeleted={onDeleted}
                    />
                </Can>
            </Col>
        </Row>
    );
};

Actions.propTypes = {
    record: PropTypes.shape({}),
    history: PropTypes.shape({}),
};

Actions.defaultProps = {
    record: {},
    history: {},
};

export default Actions;
