import React from 'react';
import chroma from 'chroma-js';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: 'Citado', label: 'Citado', color: '#0052CC' },
    { value: 'Presente', label: 'Presente', color: '#36B37E' },
    { value: 'Ausente con aviso', label: 'Ausente con aviso', color: '#722F37' },
    { value: 'Ausente sin aviso', label: 'Ausente sin aviso', color: '#FF5630' },
];

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const getBackgroundColor = ({
    data, isDisabled, isFocused, isSelected,
}) => {
    if (isDisabled) {
        return null;
    }
    if (isSelected) {
        return data.color;
    }
    if (isFocused) {
        return chroma(data.color).alpha(0.1).css();
    }
    return null;
};

const getColor = ({ data, isDisabled, isSelected }) => {
    if (isDisabled) {
        return '#ccc';
    }
    if (isSelected) {
        return chroma.contrast(chroma(data.color), 'white') > 2
            ? 'white'
            : 'black';
    }
    return data.color;
};

const getActiveBackgroundColor = ({
    data, isDisabled, isSelected,
}) => !isDisabled && (isSelected ? data.color : chroma(data.color).alpha(0.3).css());

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: getBackgroundColor(state),
        color: getColor(state),
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        ':active': {
            ...styles[':active'],
            backgroundColor: getActiveBackgroundColor(state),
        },
    }),
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const SelectAssistance = props => (
    <SelectInput
        options={OPTIONS}
        {...props}
        styles={colourStyles}
    />
);

export default SelectAssistance;
