import React from 'react';
import PropTypes from 'prop-types';

const urlToAddressElements = elements => `https://maps.google.com/?q=${elements.filter(e => !!e).join(',')}`;
const renderConditionalWithSpace = (element) => {
    if (element) {
        return (
            <span>
                <br />
                {element}
            </span>
        );
    }
    return null;
};

const LinkAddress = ({
    address, borough, city, state, country,
}) => {
    if (!address && !borough && !city) {
        return null;
    }

    return (
        <a href={urlToAddressElements([address, borough, city, state, country])}>
            {address}
            {renderConditionalWithSpace(borough)}
            {renderConditionalWithSpace(city)}
            {renderConditionalWithSpace(state)}
            {renderConditionalWithSpace(country)}
        </a>
    );
};

LinkAddress.propTypes = {
    address: PropTypes.string,
    borough: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
};

LinkAddress.defaultProps = {
    address: undefined,
    borough: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
};

export default LinkAddress;
