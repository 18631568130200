import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormCheck from 'react-bootstrap/FormCheck';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import SelectInputBankAccount from '../../BankAccounts/SelectInput';
import SelectInputOffice from '../../Offices/SelectInput';
import SelectInputProvider from '../../Providers/SelectInput';
import SelectInputAccountingChart from '../../AccountingCharts/SelectInput';
import SelectInputAccountingPaymentType from '../../AccountingPaymentTypes/SelectInput';
import InputDate from '../../RecordDetails/InputDate';
import InputCurrency from '../../RecordDetails/InputCurrency';

const isCheck = accountingPaymentType => accountingPaymentType && accountingPaymentType.title === 'Cheque';
const isCharge = accountingChart => accountingChart && accountingChart.sign < 0;

class RecordForm extends React.Component {
    constructor(props) {
        super(props);
        this.onDocDateChange = this.onDocDateChange.bind(this);
    }

    onDocDateChange(name, newValue) {
        const { values, setFieldValue } = this.props;

        if (!values.flowDate || values.flowDate === values.docDate) {
            setFieldValue('flowDate', newValue);
        }

        setFieldValue(name, newValue);
    }

    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    renderFollowUpCreation() {
        const { onFollowUpChange, followUp } = this.props;

        if (typeof onFollowUpChange === 'undefined') {
            return '';
        }

        return (
            <FormCheck
                type="checkbox"
                label="Ingresar otro después"
                onChange={onFollowUpChange}
                checked={followUp}
            />
        );
    }

    renderCheckNumber() {
        const {
            values, accountingPaymentTypes, accountingCharts,
        } = this.props;

        const accountingChart = accountingCharts[values.accountingChartId];
        const accountingPaymentType = accountingPaymentTypes[values.accountingPaymentTypeId];

        if (!isCharge(accountingChart) || !isCheck(accountingPaymentType)) {
            return null;
        }

        return (
            <BKInputLabeled label="Número cheque" name="bankCheckNumber" placeholder="Ej: 5694317" />
        );
    }

    render() {
        const { onCancel, isSubmitting } = this.props;
        return (
            <BKForm>
                <Modal.Body>
                    <Container>
                        {this.renderAlert()}
                        <Row>
                            <Col lg="6">
                                <BKInputLabeled label="Oficina" name="officeId" component={SelectInputOffice} />
                                <BKInputLabeled label="Cuenta" name="bankAccountId" component={SelectInputBankAccount} />
                                <BKInputLabeled label="Fecha documento" name="docDate" otherThing="wololoh" setFieldValueOverride={this.onDocDateChange} component={InputDate} />
                                <BKInputLabeled label="Fecha flujo de caja" name="flowDate" component={InputDate} />
                                <BKInputLabeled label="Monto" name="amount" placeholder="Ej: 1000000" component={InputCurrency} />
                            </Col>
                            <Col lg="6">
                                <BKInputLabeled label="Proveedor" name="providerId" component={SelectInputProvider} />
                                <BKInputLabeled label="Cuenta contable" name="accountingChartId" component={SelectInputAccountingChart} />
                                <BKInputLabeled label="Módo de pago" name="accountingPaymentTypeId" component={SelectInputAccountingPaymentType} />
                                {this.renderCheckNumber()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.renderFollowUpCreation()}
                    <Button onClick={onCancel} size="sm" variant="outline-secondary">Cancelar</Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    values: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
    onFollowUpChange: PropTypes.func,
    followUp: PropTypes.bool,
    accountingPaymentTypes: PropTypes.shape({}),
    accountingCharts: PropTypes.shape({}),
};

RecordForm.defaultProps = {
    status: {},
    onCancel: () => { },
    isSubmitting: false,
    onFollowUpChange: undefined,
    followUp: undefined,
    accountingPaymentTypes: {},
    accountingCharts: {},
};

export default RecordForm;
