export const FORMAT_FULL = 'full';
export const FORMAT_MEDIUM = 'medium';
export const FORMAT_SHORT = 'short';
export const FORMAT_INITIALS = 'initials';
export const FORMAT_INVERTED = 'inverted';

const getInitial = (name) => {
    if (name) {
        return `${name.charAt(0)}.`;
    }
    return '';
};
const getFirstWord = name => name.split(' ')[0];

const transformFirstName = (firstName, format) => {
    if (format === FORMAT_MEDIUM) {
        return getFirstWord(firstName);
    }

    if (format === FORMAT_SHORT || format === FORMAT_INITIALS) {
        return getInitial(firstName);
    }

    return firstName;
};

const transformLastName = (lastName, format) => {
    if (format === FORMAT_MEDIUM || format === FORMAT_SHORT) {
        return getFirstWord(lastName);
    }

    if (format === FORMAT_INITIALS) {
        return getInitial(lastName);
    }

    return lastName;
};

export const getFullName = ({ firstName = '', lastName = '' }, format = FORMAT_FULL) => {
    let separator = '';
    let transformedFirstName = transformFirstName(firstName.trim(), format);
    let transformedLastName = transformLastName(lastName.trim(), format);
    if (transformedFirstName && transformedLastName && format !== FORMAT_INITIALS) {
        separator = ' ';
    }
    if (format === FORMAT_INVERTED) {
        separator = ', ';
        const aux = transformedFirstName;
        transformedFirstName = transformedLastName;
        transformedLastName = aux;
    }
    return `${transformedFirstName}${separator}${transformedLastName}`;
};


// def full_name_short
// name.split(' ').first.capitalize + " " + (last_name && last_name.length > 0 ? last_name.split(' ').first.capitalize : '')
// end

// def full_name_ultra_short
// name[0] + ". " + last_name.split(' ').first.capitalize
// end

// def full_name_initials
// name[0].upcase + ' ' + last_name[0].upcase
// end
