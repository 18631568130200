import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable from '../../Table';
import CellDateTime from '../../Table/CellDateTime';
import FullNameAccessor from '../../Table/FullNameAccessor';
import CellUserLink from '../../Table/CellUserLink';
import CellMessageLink from './CellMessageLink';

const COLUMNS = [
    {
        Header: 'Fecha', accessor: 'createdAt', minWidth: 40, Cell: CellDateTime,
    },
    {
        Header: 'Usuario',
        id: 'user',
        accessor: FullNameAccessor('user'),
        minWidth: 80,
        Cell: CellUserLink,
    },
    {
        Header: 'Acción', accessor: 'message', minWidth: 60, Cell: CellMessageLink,
    },
];

const Table = props => (
    <Row>
        <Col>
            <BaseTable
                columns={COLUMNS}
                defaultSorted={[
                    {
                        id: 'createdAt',
                        desc: true,
                    },
                ]}
                {...props}
            />
        </Col>
    </Row>
);

export default Table;
