import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import '../../../utils/validations/userUniq';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'firstName',
    'lastName',
    'rut',
    'genre',
    'birthday',
    'healthScheme',
    'university',
    'universityEndDate',
    'postGradTitles',
    'bank',
    'bankCountType',
    'bankCountNumber',
    'email',
    'cellPhone',
    'homePhone',
    'officePhone',
    'address',
    'borough',
    'city',
    'roles',
    'workStartDate',
    'workStopDate',
    'checkIn',
    'checkOut',
    'offices',
    'lifeSheet',
];
const keepOnlyDigits = text => text.replace(/\D+/g, '');

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: record[key] || '',
        }), {});

        [values.roles] = values.roles;
        values.officeIds = values.offices ? values.offices.map(office => office.id.toString()) : [];
        values.checkInId = values.checkIn ? values.checkIn.id.toString() : '';
        values.checkOutId = values.checkOut ? values.checkOut.id.toString() : '';
        delete values.offices;
        delete values.checkIn;
        delete values.checkOut;
        return values;
    },
    validationSchema: ({ record = {} }) => Yup.object().shape({
        rut: Yup.string()
            .min(2, 'Debe tener al menos 2 caracteres')
            .required('Campo obligatorio')
            .userUniq('rut', record.id, 'Este rut ya existe'),
        firstName: Yup.string()
            .required('Campo obligatorio'),
        lastName: Yup.string()
            .required('Campo obligatorio'),
        email: Yup.string()
            .email('Ingrese una direccion de correo válida'),
        genre: Yup.string()
            .required('Campo obligatorio'),
        roles: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            const cleanValues = {
                ...values,
                rut: keepOnlyDigits(values.rut),
                roles: [values.roles],
            };
            await onSubmit(cleanValues);
        } catch (error) {
            logger.error('Error updating patient:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
