import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import RecordForm from '../RecordForm';

const CreateModal = ({
    show, onHide, onSuccess, createRecord,
}) => {
    const onSubmit = async (attributes) => {
        const record = await createRecord(attributes);
        onSuccess(record);
    };
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar procedimiento
                </Modal.Title>
            </Modal.Header>
            <RecordForm onCancel={onHide} onSubmit={onSubmit} />
        </Modal>
    );
};

CreateModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    createRecord: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
};

CreateModal.defaultProps = {
    onSuccess: () => { },
    show: false,
};

export default CreateModal;
