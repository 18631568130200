import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoCheck, GoX } from 'react-icons/go';
import createLogger from 'logging';
import ConfirmModal, { STATUS } from '../../ConfirmModal';

const logger = createLogger('ButtonActivate');

const ACTIVATE_QUESTION = '¿Estás seguro que deseas dejar la oficina ACTIVA?';
const DEACTIVATE_QUESTION = '¿Estás seguro que deseas dejar la oficina INACTIVA? Con esto no podrá elegirla en futuros tratemientos';
const MESSAGE_LOADING = 'Cargando...';
const MESSAGE_ERROR = 'Hubo un error cambiando la activación de la oficina, por favor intente más tarde';

const ButtonActivate = ({
    record, activate, deactivate, ...otherProps
}) => {
    let verb = 'Desactivar ';
    let variant = 'outline-danger';
    let icon = <GoX />;
    let question = DEACTIVATE_QUESTION;
    let action = deactivate;

    if (!record.active) {
        verb = 'Activar ';
        variant = 'outline-success';
        icon = <GoCheck />;
        question = ACTIVATE_QUESTION;
        action = activate;
    }

    const [modalShow, setModalShow] = React.useState(false);
    const [status, setStatus] = React.useState(STATUS.IDLE);
    const [message, setMessage] = React.useState(question);
    const onSelect = async (confirmed) => {
        if (!confirmed) {
            setModalShow(false);
            setMessage(question);
            setStatus(STATUS.IDLE);
            return;
        }

        setStatus(STATUS.LOADING);
        setMessage(MESSAGE_LOADING);
        try {
            await action(record.id);
            setModalShow(false);
            setStatus(STATUS.IDLE);
        } catch (error) {
            logger.error('Error deactivatinf/activating office', error);
            setMessage(MESSAGE_ERROR);
            setStatus(STATUS.ERROR);
        }
    };

    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant={variant} {...otherProps}>
                {icon}
                {' '}
                {verb}
            </Button>
            <ConfirmModal
                show={modalShow}
                message={message}
                onSelect={onSelect}
                status={status}
            />
        </div>
    );
};

ButtonActivate.propTypes = {
    record: PropTypes.shape({}).isRequired,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
};

export default ButtonActivate;
