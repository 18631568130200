import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import './StatementTable.css';
import StatementAmountsRow from './StatementAmountsRow';

class StatementTable extends React.Component {
    constructor(props) {
        super(props);
        const { onFetchData } = props;
        onFetchData({});
    }

    renderTable() {
        const { categories } = this.props;
        return (
            <Table id="StatementTable">
                <thead>
                    <tr>
                        <th className="bg-dark text-white sticky-top">Item</th>
                        <th className="bg-dark text-white sticky-top">Enero</th>
                        <th className="bg-dark text-white sticky-top">Febrero</th>
                        <th className="bg-dark text-white sticky-top">Marzo</th>
                        <th className="bg-dark text-white sticky-top">Abril</th>
                        <th className="bg-dark text-white sticky-top">Mayo</th>
                        <th className="bg-dark text-white sticky-top">Junio</th>
                        <th className="bg-dark text-white sticky-top">Julio</th>
                        <th className="bg-dark text-white sticky-top">Agosto</th>
                        <th className="bg-dark text-white sticky-top">Septiembre</th>
                        <th className="bg-dark text-white sticky-top">Octubre</th>
                        <th className="bg-dark text-white sticky-top">Noviembre</th>
                        <th className="bg-dark text-white sticky-top">Diciembre</th>
                        <th className="bg-dark text-white sticky-top">Total</th>
                        <th className="bg-dark text-white sticky-top">Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderCategories(categories)}
                </tbody>
            </Table>
        );
    }

    renderCategories(categories) {
        return categories.reduce(
            (rows, category, index) => rows.concat(
                this.renderCategoryRows(category, index, categories),
            ), [],
        );
    }

    renderCategoryRows(category, categoryIndex, categories) {
        return [
            StatementTable.renderCategoryHeader(category, categoryIndex),
            ...this.renderSubcategoriesRows(category.subcategories, categoryIndex),
            StatementTable.renderCategoryTotal(category, categoryIndex),
            StatementTable.renderCategoryRunningTotal(category, categoryIndex, categories),
        ];
    }

    static renderCategoryHeader(category, index) {
        return (
            <tr key={`C-${index}-${category.name}`} className="category bg-dark text-white text-uppercase">
                <td className="category-header-title" colSpan="15">
                    {category.name}
                </td>
            </tr>
        );
    }

    static renderCategoryTotal(category, index) {
        return (
            <StatementAmountsRow
                title={`Total ${category.name}`}
                amounts={category.amounts}
                key={`C-T-${index}-${category.name}`}
                rowClass="category-total bg-secondary text-white text-uppercase"
            />
        );
    }

    static renderCategoryRunningTotal(category, index, categories) {
        if (index === 0) {
            return undefined;
        }

        const isLastOne = (index === (categories.length - 1));
        let title = 'Resultado operación';
        if (isLastOne) {
            title = 'Resultado final';
        } else if (index > 1) {
            title = `${title} ${index}`;
        }

        return (
            <StatementAmountsRow
                title={title}
                amounts={category.runningAmounts}
                key={`C-RT-${index}-${category.name}`}
                rowClass="category-running-total bg-warning text-uppercase"
            />
        );
    }

    renderSubcategoriesRows(subcategories, categoryIndex) {
        return subcategories.reduce(
            (rows, subcategory, index) => rows.concat(
                this.renderSubcategoryRows(subcategory, categoryIndex, index),
            ), [],
        );
    }

    renderSubcategoryRows(subcategory, categoryIndex, subcategoryIndex) {
        return [
            this.renderSubcategoryHeader(subcategory, categoryIndex, subcategoryIndex),
            ...this.renderChartsRows(subcategory.charts, categoryIndex, subcategoryIndex),
        ];
    }

    renderSubcategoryHeader(subcategory, categoryIndex, subcategoryIndex) {
        const { categories } = this.props;
        const { subcategories } = categories[categoryIndex];
        if (subcategories.length === 1) {
            return undefined;
        }

        return (
            <StatementAmountsRow
                title={subcategory.name}
                amounts={subcategory.amounts}
                key={`SC-${categoryIndex}-${subcategoryIndex}-${subcategory.name}`}
                rowClass="subcategory bg-light font-weight-bold"
            />
        );
    }

    renderChartsRows(charts) {
        return charts.reduce((rows, chart) => rows.concat(this.renderCartRow(chart)), []);
    }

    renderCartRow(chart) {
        const { onMonthClick } = this.props;
        return (
            <StatementAmountsRow
                title={chart.name}
                amounts={chart.amounts}
                key={chart.id}
                rowClass="chart bg-white"
                titleClass="chart-title"
                calculatedClass="font-weight-bold"
                onMonthClick={month => onMonthClick(month, chart)}
            />
        );
    }

    render() {
        return (
            <Row id="StatementTableRow">
                <Col>
                    {this.renderTable()}
                </Col>
            </Row>
        );
    }
}

const amountsShape = PropTypes.shape({
    1: PropTypes.number,
    2: PropTypes.number,
    3: PropTypes.number,
    4: PropTypes.number,
    5: PropTypes.number,
    6: PropTypes.number,
    7: PropTypes.number,
    8: PropTypes.number,
    9: PropTypes.number,
    10: PropTypes.number,
    11: PropTypes.number,
    12: PropTypes.number,
    sum: PropTypes.number,
    avg: PropTypes.number,
});

StatementTable.propTypes = {
    onFetchData: PropTypes.func.isRequired,
    onMonthClick: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        subcategories: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            charts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                orderNumber: PropTypes.number,
                name: PropTypes.string,
                amounts: amountsShape,
            })),
            amounts: amountsShape,
        })),
        amounts: amountsShape,
        runningAmounts: amountsShape,
    })),
};

StatementTable.defaultProps = {
    onMonthClick: () => { },
    categories: [],
};

export default StatementTable;
