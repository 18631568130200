import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { connect, getIn } from 'formik';
import elementTypePropTypeChecker from '../../utils/propTypeReactElementChecker';

const BKInput = (props) => {
    const {
        component: Component,
        ...rest
    } = props;
    const {
        name,
        formik: {
            isSubmitting,
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
        },
    } = rest;
    const error = getIn(errors, name);
    const touch = getIn(touched, name);
    const value = getIn(values, name);
    const isInvalid = touch && !!error;
    return (
        <div>
            <Component
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                isInvalid={isInvalid}
                disabled={isSubmitting}
                {...rest}
            />
            {isInvalid && (
                <Form.Control.Feedback className="d-block" type="invalid">
                    {error}
                </Form.Control.Feedback>
            )}
        </div>
    );
};

BKInput.propTypes = {
    name: PropTypes.string.isRequired,
    formik: PropTypes.shape({
        handleSubmit: PropTypes.func.isRequired,
        handleReset: PropTypes.func.isRequired,
    }).isRequired,
    component: elementTypePropTypeChecker,
};

BKInput.defaultProps = {
    component: Form.Control,
};

export default connect(BKInput);
