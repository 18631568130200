import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { GoSearch } from 'react-icons/go';

const FilterSearch = ({ onChange, placeholder }) => {
    const bouncedOnChange = debounce(350, onChange);
    const handleChange = (event) => {
        bouncedOnChange({ search: event.target.value });
    };

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text><GoSearch /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                placeholder={placeholder}
                onChange={handleChange}
            />
        </InputGroup>
    );
};

FilterSearch.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

FilterSearch.defaultProps = {
    onChange: () => { },
    placeholder: 'Buscar',
};

export default FilterSearch;
