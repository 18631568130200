import createLogger from 'logging';
import { LOG_IN, LOG_OUT } from './index';
import authApi from '../api/auth';

const logger = createLogger('AuthAction');

export const logIn = (username, password) => async (dispatch) => {
    try {
        const token = await authApi.logIn(username, password);
        dispatch({
            type: LOG_IN,
            payload: token,
        });
        return token;
    } catch (error) {
        logger.error('Error logging in:', error);
        error.message = 'No se pudo conectar con el servidor, intente nuevamente';
        if (error.response && error.response.data && error.response.data.error) {
            error.message = error.response.data.error;
        }
        throw error;
    }
};

export const logOut = () => async (dispatch) => {
    authApi.logOut();
    dispatch({
        type: LOG_OUT,
    });
};

export const sessionExpired = () => ({
    type: LOG_OUT,
    payload: {
        warning: 'La sesión ha expirado',
    },
});

export default {
    logIn,
    logOut,
    sessionExpired,
};
