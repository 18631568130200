import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import Filter from './Filter';
import Actions from './Actions.router';
import Table from './Table.redux';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { label: 'Flujo de caja' },
];

class AccountingMovementsPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilters = this.updateFilters.bind(this);
    }

    updateFilters(newFilters = {}) {
        const { getAccountingMovements, filters } = this.props;
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getAccountingMovements(updatedFilters);
    }

    render() {
        const { filters } = this.props;
        return (
            <div>
                <Breadcrumb paths={BREADCRUMB} />
                <Actions
                    onReload={this.updateFilters}
                />
                <Filter
                    filters={filters}
                    onChange={this.updateFilters}
                />
                <Table
                    filters={filters}
                    onFetchData={this.updateFilters}
                />
            </div>
        );
    }
}

AccountingMovementsPage.propTypes = {
    getAccountingMovements: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        offset: PropTypes.number,
        limit: PropTypes.number,
        search: PropTypes.string,
        fromFlowDate: PropTypes.string,
        toFlowDate: PropTypes.string,
    }).isRequired,
};

export default AccountingMovementsPage;
