import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => (
    <div className="text-center">
        <Spinner animation="border" />
    </div>
);

export default Loading;
