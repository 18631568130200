import React from 'react';
import PropTypes from 'prop-types';

const MultiLineText = ({ text }) => {
    if (!text) {
        return null;
    }

    return (
        text.split('\n').map(item => <p key={item}>{item}</p>)
    );
};

MultiLineText.propTypes = {
    text: PropTypes.string,
};

MultiLineText.defaultProps = {
    text: undefined,
};

export default MultiLineText;
