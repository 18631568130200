import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: 'Kinesiologist', label: 'Kinesiólogo' },
    { value: 'Recepcionist', label: 'Recepcionista' },
    { value: 'Administrator', label: 'Administrador' },
];

const InputRole = props => (
    <SelectInput options={OPTIONS} isClearable={false} {...props} />
);

export default InputRole;
