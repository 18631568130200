import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import CellActive from '../../Table/CellActive';
import FullNameAccessor from '../../Table/FullNameAccessor';
import { OFFICE } from '../../../constants/routes';

const COLUMNS = [
    { Header: 'Nombre', accessor: 'name', Cell: CellActive },
    { Header: 'Direción', accessor: 'address', minWidth: 120 },
    {
        Header: 'Responsable', id: 'responsible', accessor: FullNameAccessor('responsible'), minWidth: 80,
    },
    { Header: 'Tipo', accessor: 'officeTypeName', minWidth: 60 },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(OFFICE, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
