import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import dateUtil from '../../utils/date';

registerLocale('es', es);

const FilterDate = (props) => {
    const {
        identifier, defaultValue, onChange,
    } = props;
    const defaultOptionSelected = dateUtil.parseDatetime(defaultValue);
    const handleChange = (newDate) => {
        const dateString = dateUtil.formatDateTime(newDate, '%Y-%m-%d');
        onChange({ [identifier]: dateString });
    };

    return (
        <DatePicker
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            autoComplete="off"
            dropdownMode="select"
            className="form-control"
            dateFormat="dd/MM/yyyy"
            todayButton="Hoy"
            locale="es"
            {...props}
            selected={defaultOptionSelected}
            onChange={handleChange}
        />
    );
};

FilterDate.propTypes = {
    identifier: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
};

FilterDate.defaultProps = {
    defaultValue: '',
    onChange: () => { },
};

export default FilterDate;
