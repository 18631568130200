import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import BKForm from '../../BKForm';
import BKInput from '../../BKForm/BKInput';
import InputState from './InputState';
import useIsMount from '../../../utils/useIsMount';

const StateForm = (props) => {
    const {
        isSubmitting, status, values, submitForm,
    } = props;

    const isMount = useIsMount();

    useEffect(() => {
        if (!isMount) {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const renderAlert = () => {
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    };

    return (
        <BKForm>
            <BKInput
                name="state"
                component={InputState}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                loadingIndicator={Spinner}
            />
            {renderAlert()}
        </BKForm>
    );
};

StateForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    submitForm: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
    isSubmitting: PropTypes.bool,
};

StateForm.defaultProps = {
    status: {},
    isSubmitting: false,
};

export default StateForm;
