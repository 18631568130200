import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/procedures';
import SearchProcedure from './SearchProcedure';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';

const mapStateToProps = state => mapIndexStateToProps(state?.procedures?.options);
const mapDispatchToProps = dispatch => bindActionCreators({
    getRecords: actions.getOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchProcedure);
