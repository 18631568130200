import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/offices';
import SelectInput from '../../Offices/SelectInput/SelectInput';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';
import getNestedValue from '../../../utils/getNestedValue';

const mapStateToProps = (state) => {
    const props = mapIndexStateToProps(getNestedValue(state, 'offices.options'));
    const records = props.records ?? [];
    const filteredRecords = records.filter(record => record.officeType === 0);
    return {
        ...props,
        records: filteredRecords,
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({
    getRecords: actions.getOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);
