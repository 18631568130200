import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import BookForm from './BookForm';

const logger = createLogger('BookFormik');
const ATTRIBUTES = [
    'bookNumber',
    'checkNumberFrom',
    'checkNumberTo',
    'bankAccount',
];

const BookFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: typeof record[key] !== 'undefined' ? record[key] : '',
        }), {});

        values.bankAccountId = values.bankAccount ? values.bankAccount.id.toString() : '';
        delete values.bankAccount;
        return values;
    },
    validationSchema: () => Yup.object().shape({
        bookNumber: Yup.string()
            .required('Campo obligatorio'),
        checkNumberFrom: Yup.number()
            .typeError('Debe ser un numéro')
            .positive('Debe ser positivo')
            .required('Campo obligatorio'),
        checkNumberTo: Yup.number()
            .typeError('Debe ser un numéro')
            .positive('Debe ser positivo')
            .required('Campo obligatorio')
            .moreThan(Yup.ref('checkNumberFrom'), 'Debe ser mayor a número de cheque inicial')
            .test('maxQuantity', 'No puede generar más de 100 cheques', function validateMaxQuantity(value) {
                const { checkNumberFrom } = this.parent;
                return value - checkNumberFrom <= 100;
            }),
        bankAccountId: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            await onSubmit(values);
        } catch (error) {
            logger.error('Error creating bankCheck book:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'BookForm',
})(BookForm);

export default BookFormik;
