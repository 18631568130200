export const ASSISTANCE = {
    SCHEDULED: 'Citado',
    ASSISTED: 'Presente',
    MISSED_WITH_NOTICE: 'Ausente con aviso',
    MISSED_WITHOUT_NOTICE: 'Ausente sin aviso',
};

export const ASSISTANCE_TO_COUNT = [
    ASSISTANCE.ASSISTED,
    ASSISTANCE.MISSED_WITHOUT_NOTICE,
];
