import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import InputSign from './InputSign';
import InputAccountability from './InputAccountability';

class RecordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    render() {
        const { onCancel, isSubmitting } = this.props;
        return (
            <BKForm>
                <Modal.Body>
                    <Container>
                        {this.renderAlert()}
                        <Row>
                            <Col lg="6">
                                <BKInputLabeled label="Correlativo" name="orderNumber" placeholder="Ej: 411" />
                                <BKInputLabeled label="Categoría" name="category" placeholder="Ej: 1.- Ingresos" />
                                <BKInputLabeled label="Subcategoría" name="subcategory" placeholder="Ej: 2.1.- Remuneraciones" />
                                <BKInputLabeled label="Nombre" name="name" placeholder="Ej: Auxiliar de Aseo" />
                            </Col>
                            <Col lg="6">
                                <BKInputLabeled label="Número" name="chartNumber" placeholder="Ej: 161" />
                                <BKInputLabeled label="Ingreso/Egreso" name="sign" component={InputSign} />
                                <BKInputLabeled label="Informe de Gestión" name="sumForAccountabiliy" component={InputAccountability} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancel} size="sm" variant="outline-secondary">Cancelar</Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

RecordForm.defaultProps = {
    status: {},
    onCancel: () => { },
    isSubmitting: false,
};

export default RecordForm;
