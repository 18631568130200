import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/bankChecks';
import ButtonDelete from './ButtonDelete';

const mapDispatchToProps = dispatch => bindActionCreators({
    deletee: actions.delete,
}, dispatch);

export default connect(null, mapDispatchToProps)(ButtonDelete);
