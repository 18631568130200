import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import RutControl from '../../RutControl';
import InputDate from '../../RecordDetails/InputDate';
import InputHealthSchema from '../../RecordDetails/InputHealthSchema';
import InputGender from '../../RecordDetails/InputGender';
import InputReference from './InputReference';

class RecordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    render() {
        const { onCancel, isSubmitting } = this.props;
        return (
            <BKForm>
                <Modal.Body>
                    <Container>
                        {this.renderAlert()}
                        <Row>
                            <Col lg="6">
                                <legend>Datos Personales</legend>
                                <BKInputLabeled label="Nombre" name="firstName" placeholder="Ej: Miguel" />
                                <BKInputLabeled label="Apellido" name="lastName" placeholder="Ej: Garcia" />
                                <BKInputLabeled label="Rut" name="rut" placeholder="Ej: 21.993.845" component={RutControl} />
                                <BKInputLabeled label="Género" name="genre" component={InputGender} />
                                <BKInputLabeled label="Cumpleaños" name="birthday" component={InputDate} />
                                <BKInputLabeled label="Previsión" name="healthScheme" component={InputHealthSchema} />
                                <BKInputLabeled label="Profesión" name="profession" placeholder="Ej: Abogado" />
                                <BKInputLabeled label="Referencia" name="reference" component={InputReference} />
                                <BKInputLabeled label="Notas" name="lifeSheet" placeholder="Datos relevantes" as="textarea" rows="3" />
                            </Col>
                            <Col lg="6">
                                <legend>Contacto</legend>
                                <BKInputLabeled label="Email" name="email" placeholder="Ej: miguel_g@gmail.com" />
                                <BKInputLabeled label="Teléfono celular" name="cellPhone" placeholder="Ej: 998871455" />
                                <BKInputLabeled label="Teléfono de casa" name="homePhone" placeholder="Ej: 224759284" />
                                <BKInputLabeled label="Teléfono de oficina" name="officePhone" placeholder="Ej: 226858821" />
                                <BKInputLabeled label="Dirección" name="address" placeholder="Ej: Av. Suecia 243" />
                                <BKInputLabeled label="Comuna" name="borough" placeholder="Ej: Providencia" />
                                <BKInputLabeled label="Ciudad" name="city" placeholder="Ej: Santiago" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancel} size="sm" variant="outline-secondary">Cancelar</Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

RecordForm.defaultProps = {
    status: {},
    onCancel: () => { },
    isSubmitting: false,
};

export default RecordForm;
