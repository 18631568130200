const signToSymbol = (sign) => {
    if (sign < 0) {
        return '-';
    }
    return '+';
};

const CellSign = ({ value, original }) => {
    if (original && original.sign !== undefined) {
        return (
            `${value} ( ${signToSymbol(original.sign)} )`
        );
    }
    return value;
};

export default CellSign;
