import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getVerifierDigit, addThousandsSeparator } from '../../utils/rut';

class RutControl extends React.Component {
    constructor(props) {
        super(props);

        const { value } = props;
        this.state = {
            verifier: getVerifierDigit(value),
            value: addThousandsSeparator(value),
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const newValue = addThousandsSeparator(event.target.value);
        this.setState({
            value: newValue,
            verifier: getVerifierDigit(newValue),
        });

        const { onChange: onChangeProp } = this.props;
        if (onChangeProp) {
            onChangeProp(event);
        }
    }

    render() {
        const { verifier, value } = this.state;
        return (
            <InputGroup>
                <Form.Control {...this.props} onChange={this.onChange} value={value} />
                <InputGroup.Append>
                    <InputGroup.Text>
                        {'- '}
                        {verifier}
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

RutControl.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

RutControl.defaultProps = {
    value: undefined,
    onChange: undefined,
    name: undefined,
};

export default RutControl;
