export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const PASSWORD_PATH = '/password';
export const USERS_PATH = '/users';
export const USER_PATH = '/users/:id';
export const USER_PASSWORD_PATH = '/users/:id/password';
export const USER_ACTIVE_PATH = '/users/:id/active';
export const USERS_UNIQ_PATH = '/users/uniq';
export const PATIENTS_PATH = '/patients';
export const PATIENT_PATH = '/patients/:id';
export const PATIENT_TREATMENTS_PATH = '/patients/:id/treatments';
export const PATIENTS_UNIQ_PATH = '/patients/uniq';
export const OFFICES_PATH = '/offices';
export const OFFICE_PATH = '/offices/:id';
export const OFFICE_ACTIVE_PATH = '/offices/:id/active';
export const PROCEDURES_PATH = '/procedures';
export const PROCEDURE_PATH = '/procedures/:id';
export const PROCEDURE_ACTIVE_PATH = '/procedures/:id/active';
export const PATHOLOGIES_PATH = '/pathologies';
export const PATHOLOGY_PATH = '/pathologies/:id';
export const PATHOLOGY_ACTIVE_PATH = '/pathologies/:id/active';
export const DOCTORS_PATH = '/doctors';
export const DOCTOR_PATH = '/doctors/:id';
export const DOCTOR_TREATMENTS_PATH = '/doctors/:id/treatments';
export const DOCTORS_UNIQ_PATH = '/doctors/uniq';
export const PROVIDERS_PATH = '/providers';
export const PROVIDER_PATH = '/providers/:id';
export const PROVIDERS_UNIQ_PATH = '/providers/uniq';
export const PROVIDER_ACTIVE_PATH = '/providers/:id/active';
export const ACCOUNTING_PAYMENT_TYPES_PATH = '/accounting_payment_types';
export const ACCOUNTING_PAYMENT_TYPE_PATH = '/accounting_payment_types/:id';
export const ACCOUNTING_MOVEMENTS_PATH = '/accounting_movements';
export const ACCOUNTING_MOVEMENT_PATH = '/accounting_movements/:id';
export const ACCOUNTING_MOVEMENTS_STATEMENT_PATH = '/accounting_movements/statement';
export const ACCOUNTING_CHARTS_PATH = '/accounting_charts';
export const ACCOUNTING_CHART_PATH = '/accounting_charts/:id';
export const BANK_ACCOUNTS_PATH = '/bank_accounts';
export const BANK_ACCOUNT_PATH = '/bank_accounts/:id';
export const BANK_CHECKS_PATH = '/bank_checks';
export const BANK_CHECKS_MULTIPLE_PATH = '/bank_checks/multiple';
export const BANK_CHECK_PATH = '/bank_checks/:id';
export const SCHEDULES_PATH = '/schedules';
export const TREATMENTS_PATH = '/treatments';
export const TREATMENT_PATH = '/treatments/:id';
export const SESSIONS_PATH = '/sessions';
export const LOGS_PATH = '/logs';

export const STATUS = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
};

export const ROLES = {
    ADMINISTRATOR: 'Administrator',
    KINESIOLOGIST: 'Kinesiologist',
    RECEPCIONIST: 'Recepcionist',
};
