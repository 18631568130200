import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import userActions from '../../../actions/users';
import patientActions from '../../../actions/patients';
import NavSearch from './NavSearch.router';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';

const mapStateToProps = state => ({
    users: mapIndexStateToProps(state.users.search),
    patients: mapIndexStateToProps(state.patients.search),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    searchUsers: userActions.search,
    searchPatients: patientActions.search,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavSearch));
