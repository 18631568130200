import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/doctors';
import ShowPage from './ShowPage';
import { mapDetailsStateToProps } from '../../../utils/apiStateToProps';

const mapStateToProps = (state, ownProps) => mapDetailsStateToProps(
    state.doctors.details,
    ownProps,
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getDetails: actions.getDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowPage);
