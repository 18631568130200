import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { connect } from 'formik';

const BKForm = (props) => {
    const { formik: { handleSubmit, handleReset }, ...rest } = props;
    return (
        <Form onSubmit={handleSubmit} onReset={handleReset} noValidate {...rest} />
    );
};

BKForm.propTypes = {
    formik: PropTypes.shape({
        handleSubmit: PropTypes.func.isRequired,
        handleReset: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(BKForm);
