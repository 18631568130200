import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingMovements';
import IndexPage from './IndexPage';
import getNestedValue from '../../../utils/getNestedValue';
import dateUtil from '../../../utils/date';

const defaultTo = () => dateUtil.formatDateTime(new Date(), '%Y-%m-%d');
const defaultFrom = () => {
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(date.getMonth() - 1);
    if (date.getMonth() === month) {
        date.setDate(0);
    }
    date.setHours(0, 0, 0);
    date.setMilliseconds(0);
    return dateUtil.formatDateTime(date, '%Y-%m-%d');
};

const defaultFilters = () => ({
    offset: 0,
    limit: 20,
    search: '',
    fromFlowDate: defaultFrom(),
    toFlowDate: defaultTo(),
});

const getCachedFilterUsed = state => getNestedValue(state, 'cache.accountingMovements/index') || {};
const getCurrentFilterUsed = state => getNestedValue(state, 'accountingMovements.index.filters') || {};

const getCurrentOfficeFilter = (state) => {
    const id = getNestedValue(state, 'cache.currentOffice.id');
    if (typeof id !== 'undefined') {
        return { office: `${id}` };
    }
    return {};
};

const mapStateToProps = state => ({
    filters: {
        ...defaultFilters(),
        ...getCachedFilterUsed(state),
        ...getCurrentOfficeFilter(state),
        ...getCurrentFilterUsed(state),
    },
});

const mapDispatchToProps = dispatch => bindActionCreators(
    { getAccountingMovements: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
