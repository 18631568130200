import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Can from '../../Can';
import ButtonLogsModal from '../../Logs/ButtonLogsModal';
import ButtonEditModal from '../EditModal/ButtonEditModal';
import ButtonActivate from './ButtonActivate.redux';
import { getFullName } from '../../../utils/user';

const Actions = ({ record }) => (
    <Row className="my-3 smb-3">
        <Col>
            <Can do="readLogs" on="Provider">
                <ButtonLogsModal
                    className="mr-1"
                    title={getFullName(record)}
                    filters={{ relatedObjectType: 'Provider', relatedObjectId: record.id, limit: 10 }}
                />
            </Can>
            <Can do="edit" on="Provider">
                <ButtonEditModal
                    className="mr-1"
                    record={record}
                />
            </Can>
            <Can do="activate" on="Provider">
                <ButtonActivate
                    className="mr-1"
                    record={record}
                />
            </Can>
        </Col>
    </Row>
);

Actions.propTypes = {
    record: PropTypes.shape({}),
};

Actions.defaultProps = {
    record: {},
};

export default Actions;
