import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Table.css';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { ACCOUNTING_MOVEMENT } from '../../../constants/routes';
import CellCheck from './CellCheck';
import CellAmountNotPaid from './CellAmountNotPaid';
import CellFutureBalance from './CellFutureBalance';
import CellDate from '../../Table/CellDate';

const bankAccountAccessor = ({ bankAccount } = {}) => bankAccount && `${bankAccount.bankName} / ${bankAccount.accountNumber}`;
const accountChartAccessor = ({ accountingChart } = {}) => accountingChart
    && `${accountingChart.name} / ${accountingChart.subcategory}`;
const conditionalAmountAccessor = sign => ({ amount }) => {
    if (amount !== undefined && sign * amount >= 0) {
        return Math.abs(amount);
    }
    return undefined;
};

const COLUMNS = [
    {
        Header: 'Fecha flujo', accessor: 'flowDate', Cell: CellDate, minWidth: 80,
    },
    {
        Header: 'Fecha documento', accessor: 'docDate', Cell: CellDate, minWidth: 80,
    },
    { Header: 'Oficina', accessor: 'office.name', sortable: false },
    {
        Header: 'Cuenta Corriente', id: 'bankAccount', accessor: bankAccountAccessor, sortable: false,
    },
    {
        Header: 'Cuenta Contable', id: 'accountingChart', accessor: accountChartAccessor, sortable: false, minWidth: 150,
    },
    {
        Header: 'Modo de pago', accessor: 'accountingPaymentType.title', Cell: CellCheck, sortable: false,
    },
    {
        Header: 'Egreso o cargo', id: 'amountNegative', accessor: conditionalAmountAccessor(-1), Cell: CellAmountNotPaid, sortable: false, minWidth: 80,
    },
    {
        Header: 'Depósito o abono', id: 'amountPositive', accessor: conditionalAmountAccessor(1), Cell: CellAmountNotPaid, sortable: false, minWidth: 80,
    },
    {
        Header: 'Saldo', accessor: 'bankAccountBalance', Cell: CellFutureBalance, minWidth: 80,
    },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    getProps={() => ({ id: 'AccountingMovementsTable' })}
                    defaultSorted={[
                        {
                            id: 'flowDate',
                            desc: true,
                        },
                    ]}
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(ACCOUNTING_MOVEMENT, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
