import baseApi from './base';
import { PROCEDURES_PATH, PROCEDURE_PATH, PROCEDURE_ACTIVE_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(PROCEDURES_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = PROCEDURE_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = PROCEDURE_PATH.replace(':id', id);
    return baseApi.put(url, { procedure: attributes });
};

const create = async attributes => baseApi.post(PROCEDURES_PATH, { procedure: attributes });

const activate = async (id) => {
    const url = PROCEDURE_ACTIVE_PATH.replace(':id', id);
    return baseApi.post(url);
};

const deactivate = async (id) => {
    const url = PROCEDURE_ACTIVE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    update,
    create,
    activate,
    deactivate,
};
