import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import FullNameAccessor from '../../Table/FullNameAccessor';
import { TREATMENT } from '../../../constants/routes';
import CellDate from '../../Table/CellDate';
import CellPathology from './CellPathology';

const COLUMNS = [
    {
        Header: 'Ingreso', accessor: 'firstSession.startTime', Cell: CellDate, minWidth: 40,
    },
    {
        Header: 'Paciente', id: 'patient', accessor: FullNameAccessor('patient'), minWidth: 100,
    },
    {
        Header: 'Primer Kinesiólogo', id: 'kinesiologist', accessor: FullNameAccessor('firstSession.kinesiologist'), minWidth: 100,
    },
    { Header: 'Sesiones', accessor: 'sessionsCount', minWidth: 30 },
    {
        Header: 'Diagnóstico', accessor: 'pathologies', Cell: CellPathology, minWidth: 150,
    },
];

const Treatments = (props) => {
    const { history, getTreatments, filters } = props;

    const fetch = (newFilters) => {
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getTreatments(updatedFilters);
    };

    return (
        <Row>
            <Col>
                <legend>Tratamientos</legend>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(TREATMENT, history)}
                    onFetchData={fetch}
                    filters={filters}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Treatments.propTypes = {
    history: PropTypes.shape({}).isRequired,
    getTreatments: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        offset: PropTypes.number,
        limit: PropTypes.number,
    }),
};

Treatments.defaultProps = {
    filters: {
        offset: 0,
        limit: 5,
    },
};

export default Treatments;
