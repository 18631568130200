/*
    Inspired on https://gist.github.com/Aebrathia/0dc01cc86268b194e39ba567ec38dd9a
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import elementTypePropTypeChecker from '../../../utils/propTypeReactElementChecker';
import getNestedValue from '../../../utils/getNestedValue';

const getCurrentPath = (location) => {
    if (typeof location === 'object') {
        return location.pathname;
    }
    return location;
};

const getPreviousPath = location => getNestedValue(location, 'state.from');

const getOriginRedirectPath = (redirectPath, location) => {
    const currentPath = getCurrentPath(location);
    if (currentPath === redirectPath) {
        return getPreviousPath(location);
    }
    return currentPath;
};

const ConditionalRoute = ({
    component: Component, redirectPath, checker, ...rest
}) => {
    const render = (props) => {
        if (checker()) {
            return (
                <Component {...props} />
            );
        }

        const { location } = props;
        return (
            <Redirect to={{
                pathname: redirectPath,
                state: {
                    from: getOriginRedirectPath(redirectPath, location),
                },
            }}
            />
        );
    };

    return (
        <Route
            {...rest}
            render={render}
        />
    );
};

ConditionalRoute.propTypes = {
    component: elementTypePropTypeChecker.isRequired,
    redirectPath: PropTypes.string.isRequired,
    checker: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export const ConditionalRouteInternal = ConditionalRoute;
export default withRouter(ConditionalRoute);
