import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: 'Cuenta Corriente', label: 'Cuenta Corriente' },
    { value: 'Cuenta Vista', label: 'Cuenta Vista' },
    { value: 'Cuenta de Ahorro', label: 'Cuenta de Ahorro' },
    { value: 'Otro', label: 'Otro' },
];

const InputBankType = props => (
    <SelectInput options={OPTIONS} isClearable {...props} />
);

export default InputBankType;
