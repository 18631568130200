import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingCharts';
import CreateModal from './CreateModal';

const mapDispatchToProps = dispatch => bindActionCreators({
    createRecord: actions.create,
}, dispatch);

export default connect(null, mapDispatchToProps)(CreateModal);
