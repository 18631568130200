import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';
import { STATUS_MAP, COLOR_MAP } from '../../../utils/bankCheck';

const OPTIONS = Object.keys(STATUS_MAP).map(key => (
    { value: `${key}`, label: STATUS_MAP[key], color: COLOR_MAP[key] }
));

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const dotStyle = (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
});

const colourStyles = {
    option: dotStyle,
    singleValue: dotStyle,
};

const InputState = props => (
    <SelectInput
        options={OPTIONS}
        isClearable={false}
        styles={colourStyles}
        {...props}
    />
);

export default InputState;
