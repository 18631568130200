import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/providers';
import SelectInput from './SelectInput';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';
import getNestedValue from '../../../utils/getNestedValue';

const mapStateToProps = state => mapIndexStateToProps(getNestedValue(state, 'providers.options'));
const mapDispatchToProps = dispatch => bindActionCreators({
    getRecords: actions.getOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);
