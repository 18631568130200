import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Popover from 'react-bootstrap/Popover';
import { IoFilter } from 'react-icons/io5';
import { getFullName } from '../../utils/user';

const FIVE_MINS = 5 * 60 * 1000;
const shouldUpdate = ({ loading, error, updated }) => {
    if (loading) {
        return false;
    }

    if (error || !updated) {
        return true;
    }

    const timeLapsed = new Date() - updated;
    return timeLapsed > FIVE_MINS;
};

function KinesiologistsFilter({
    getKinesiologists,
    kinesiologists,
    onChange,
    defaultValues,
}) {
    const [selected, setSelected] = useState(defaultValues ?? []);
    const addSelected = (kines) => {
        const newSelected = [
            ...selected,
            ...kines,
        ];
        setSelected(newSelected);
    };
    const removeSelected = (kines) => {
        const removeSet = new Set(kines);
        const newSelected = selected.filter(kine => !removeSet.has(kine));
        setSelected(newSelected);
    };

    const loadOptions = () => {
        if (shouldUpdate(kinesiologists)) {
            getKinesiologists();
        }
    };

    const onToggle = (isShown) => {
        if (isShown) {
            return;
        }
        if (JSON.stringify(defaultValues) !== JSON.stringify(selected)) {
            onChange({
                kinesiologist_ids: selected,
            });
        }
    };

    useEffect(() => {
        loadOptions();
    });

    const PopOverCentent = () => {
        if (kinesiologists.loading) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            );
        }
        return (
            <KinesiologistSelect
                records={kinesiologists.records}
                selected={selected}
                addKinesiologists={addSelected}
                removeKinesiologists={removeSelected}
            />
        );
    };

    const popover = (
        <Popover>
            <Popover.Title as="h3">Filtrar kinesiólogos</Popover.Title>
            <Popover.Content>
                <PopOverCentent />
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
            onToggle={onToggle}
        >
            <Button variant="outline-info">
                <IoFilter />
                {' '}
                Kinesiólogos
            </Button>
        </OverlayTrigger>
    );
}

KinesiologistsFilter.propTypes = {
    getKinesiologists: PropTypes.func.isRequired,
    defaultValues: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
    kinesiologists: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        records: PropTypes.arrayOf(PropTypes.shape({})),
        updated: PropTypes.instanceOf(Date),
    }),
};

KinesiologistsFilter.defaultProps = {
    kinesiologists: {},
    defaultValues: undefined,
};

const categorizeKinesiologists = (records) => {
    const categories = {
        Mañana: [],
        Tarde: [],
    };
    records.forEach((record) => {
        if (record.checkIn?.id != null && record.checkIn.id < 15) {
            categories['Mañana'].push(record);
        } else {
            categories.Tarde.push(record);
        }
    });
    return categories;
};

function KinesiologistSelect({
    records,
    selected,
    addKinesiologists,
    removeKinesiologists,
}) {
    const categories = categorizeKinesiologists(records);
    const selectedSet = new Set(selected);
    return (
        <Form>
            {Object.keys(categories).map((category) => {
                let allChecked = true;
                const kineChecboxes = categories[category].map((kine) => {
                    const checked = selectedSet.has(kine.id);
                    allChecked = allChecked && checked;
                    return (
                        <Form.Check
                            key={kine.id}
                            className="ml-2"
                            type="checkbox"
                            label={getFullName(kine)}
                            checked={checked}
                            onChange={() => (checked
                                ? removeKinesiologists([kine.id])
                                : addKinesiologists([kine.id]))
                            }
                        />
                    );
                });
                return (
                    <Form.Group key={category}>
                        <Form.Check
                            type="checkbox"
                            label={category}
                            checked={allChecked}
                            onChange={() => (allChecked
                                ? removeKinesiologists(categories[category].map(kine => kine.id))
                                : addKinesiologists(categories[category].map(kine => kine.id)))
                            }
                        />
                        {kineChecboxes}
                    </Form.Group>
                );
            })}
        </Form>
    );
}

KinesiologistSelect.propTypes = {
    records: PropTypes.arrayOf(PropTypes.shape({})),
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    addKinesiologists: PropTypes.func.isRequired,
    removeKinesiologists: PropTypes.func.isRequired,
};

KinesiologistSelect.defaultProps = {
    records: [],
};

export default KinesiologistsFilter;
