const formatPhone = (number) => {
    if (!number) {
        return '';
    }

    const phoneRegex = /^(\+)?([0-9]{2})?([0-9]{1})?([0-9]{4})([0-9]{4})$/;
    if (phoneRegex.test(`${number}`)) {
        return number.replace(phoneRegex, '$1$2 $3 $4-$5').trim();
    }
    return number;
};

export default {
    formatPhone,
};
