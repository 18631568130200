import React from 'react';
import { Link } from 'react-router-dom';
import { USER } from '../../constants/routes';

const getPath = ({ user } = {}) => {
    if (!user) {
        return undefined;
    }

    const { id } = user;
    if (!id) {
        return undefined;
    }

    return USER.replace(':id', id);
};

const CellUserLink = ({ value, original }) => {
    const path = getPath(original);
    if (path) {
        return (
            <Link to={path}>
                {value}
            </Link>
        );
    }
    return value;
};

export default CellUserLink;
