import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import {
    Loading, Attribute, LinkAddress, LinkPhone, LinkEmail, MultiLineText,
} from '../../RecordDetails';
import Actions from './Actions';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import { getFullName } from '../../../utils/user';
import dateUtil from '../../../utils/date';
import { formatRutAddingDigit } from '../../../utils/rut';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.PROVIDERS, label: 'Proveedores' },
];

const systemEvent = (user = {}, datetime) => (
    <span>
        {getFullName(user)}
        <br />
        {dateUtil.formatDateTime(datetime)}
    </span>
);

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        {this.renderPersonalData()}
                        {this.renderSystemData()}
                    </Col>
                    <Col lg="6">
                        {this.renderContactData()}
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderPersonalData() {
        const { record } = this.props;
        return (
            <div>
                <legend>{record.name}</legend>
                <Attribute optional name="Rut" value={formatRutAddingDigit(record.rut)} />
                <Attribute optional name="Área" value={record.area} />
                <Attribute optional name="Notas" value={MultiLineText({ text: record.notes })} />
            </div>
        );
    }

    renderContactData() {
        const { record } = this.props;
        const {
            contact, email, cellPhone, officePhone, address, borough, city,
        } = record;
        const containsContactInfo = contact || email || cellPhone || officePhone
            || address || borough || city;

        if (containsContactInfo) {
            return (
                <div>
                    <legend>Contacto</legend>
                    <Attribute optional name="Persona" value={contact} />
                    <Attribute optional name="Mail" value={LinkEmail(record)} />
                    <Attribute optional name="Teléfono Celular" value={LinkPhone({ number: cellPhone })} />
                    <Attribute optional name="Teléfono Oficina" value={LinkPhone({ number: officePhone })} />
                    <Attribute optional name="Dirección" value={LinkAddress(record)} />
                </div>
            );
        }

        return null;
    }

    renderSystemData() {
        const { record } = this.props;

        const {
            active, createdBy, createdAt, updatedBy, updatedAt, lastSignInAt, lastSignInIp,
        } = record;

        let loginActive;
        if (active === true) {
            loginActive = <Badge variant="success">Activo</Badge>;
        } else if (active === false) {
            loginActive = <Badge variant="danger">Inactivo</Badge>;
        }

        const showSystemData = loginActive || lastSignInAt || lastSignInIp
            || createdBy || createdAt || updatedBy || updatedAt;
        if (!showSystemData) {
            return null;
        }

        return (
            <div>
                <legend>Sistema</legend>
                <Attribute optional name="Agregado" value={systemEvent(createdBy, createdAt)} />
                <Attribute optional name="Actualizado" value={systemEvent(updatedBy, updatedAt)} />
                <Attribute optional name="Estado" value={loginActive} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: record.name },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
