import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';
import Actions from './Actions';
import Calendar from './Calendar';

const BREADCRUMB = [
    { label: 'Inicio' },
];

function CalendarPage({ getSessions, filters }) {
    const updateFilters = (newFilters) => {
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getSessions(updatedFilters);
    };

    return (
        <div>
            <Breadcrumb paths={BREADCRUMB} />
            <Actions
                filters={filters}
                onChange={updateFilters}
            />
            <Calendar
                filters={filters}
                onFetchData={updateFilters}
            />
        </div>
    );
}

CalendarPage.propTypes = {
    getSessions: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        kinesiologist_ids: PropTypes.arrayOf(PropTypes.number),
        office_id: PropTypes.number,
    }),
};

CalendarPage.defaultProps = {
    filters: {},
};

export default CalendarPage;
