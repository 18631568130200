import axios from 'axios';
import applyConverters from 'axios-case-converter';
import urlHelper from '../utils/url';
import authMiddleware from './authMiddleware';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TOKEN_PREFIX = 'Bearer';

let accessToken;
const setToken = (newToken) => {
    accessToken = newToken;
};

const getUrlForPath = path => `${BASE_URL}${path}`;
const snakeCase = str => str.toString().split(/(?=[A-Z])/).join('_').toLowerCase();
const snakeCaseParams = (queryParams = {}) => {
    const newQueryParams = {};
    Object.keys(queryParams).forEach((key) => {
        const newKey = snakeCase(key);
        const newValue = snakeCase(queryParams[key]);
        newQueryParams[newKey] = newValue;
    });
    return newQueryParams;
};

const getUrl = (path, queryParams) => {
    let url = getUrlForPath(path);
    url = urlHelper.addQueryString(url, snakeCaseParams(queryParams));
    return url;
};

const getHeaders = () => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };
    if (accessToken) {
        headers.authorization = `${TOKEN_PREFIX} ${accessToken}`;
    }
    return headers;
};

const getClient = () => {
    const headers = getHeaders();
    const options = {
        preservedKeys: ['_destroy'],
    };
    const client = applyConverters(axios.create({ headers }), options);
    authMiddleware(client);
    return client;
};

const get = async (path, { queryParams } = {}) => {
    const url = getUrl(path, queryParams);
    return getClient().get(url);
};

const post = async (path, data, { queryParams } = {}) => {
    const url = getUrl(path, queryParams);
    return getClient().post(url, data);
};

const put = async (path, data, { queryParams } = {}) => {
    const url = getUrl(path, queryParams);
    return getClient().put(url, data);
};

const deletee = async (path, { queryParams } = {}) => {
    const url = getUrl(path, queryParams);
    return getClient().delete(url);
};

export default {
    setToken,
    get,
    post,
    put,
    delete: deletee,
};
