const isArray = a => Array.isArray(a);
const isObject = o => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const toCamel = s => s && s.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''));

export const keysToCamel = (o) => {
    if (isObject(o)) {
        const newO = {};

        Object.keys(o).forEach((k) => {
            const newKey = toCamel(k);
            const newValue = keysToCamel(o[k]);
            newO[newKey] = newValue;
        });

        return newO;
    }

    if (isArray(o)) {
        return o.map(i => keysToCamel(i));
    }

    return o;
};
