import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import RecordForm from './RecordForm';
import '../../../utils/validations/bankCheckAvailable';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'office',
    'bankAccount',
    'docDate',
    'flowDate',
    'amount',
    'provider',
    'accountingChart',
    'accountingPaymentType',
    'bankCheck',
];

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: typeof record[key] !== 'undefined' ? record[key] : '',
        }), {});

        values.amount = values.amount ? Math.abs(values.amount) : values.amount;
        values.officeId = values.office ? values.office.id.toString() : '';
        values.bankAccountId = values.bankAccount ? values.bankAccount.id.toString() : '';
        values.providerId = values.provider ? values.provider.id.toString() : '';
        values.accountingChartId = values.accountingChart ? values.accountingChart.id.toString() : '';
        values.accountingPaymentTypeId = values.accountingPaymentType ? values.accountingPaymentType.id.toString() : '';
        values.bankCheckNumber = values.bankCheck ? values.bankCheck.checkNumber.toString() : '';
        delete values.office;
        delete values.bankAccount;
        delete values.provider;
        delete values.accountingChart;
        delete values.accountingPaymentType;
        delete values.bankCheck;
        return values;
    },
    validationSchema: ({ record = {} }) => Yup.object().shape({
        amount: Yup.number()
            .required('Campo obligatorio'),
        docDate: Yup.date()
            .required('Campo obligatorio'),
        flowDate: Yup.date()
            .min(Yup.ref('docDate'), 'Debe ser mayor o igual a la fecha de documento')
            .required('Campo obligatorio'),
        officeId: Yup.string()
            .required('Campo obligatorio'),
        bankAccountId: Yup.string()
            .required('Campo obligatorio'),
        accountingChartId: Yup.string()
            .required('Campo obligatorio'),
        accountingPaymentTypeId: Yup.string()
            .required('Campo obligatorio'),
        bankCheckNumber: Yup.string()
            .bankCheckAvailable(record.id, Yup.ref('bankAccountId'), 'Cheque no disponible'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            await onSubmit(values);
        } catch (error) {
            logger.error('Error updating accountingMovement:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
