import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Header from '../../Header';
import CalendarPage from '../../CalendarPage';
import UsersPage from '../../Users/IndexPage';
import UserPage from '../../Users/ShowPage';
import PatientsPage from '../../Patients/IndexPage';
import PatientPage from '../../Patients/ShowPage';
import OfficesPage from '../../Offices/IndexPage';
import OfficePage from '../../Offices/ShowPage';
import ProceduresPage from '../../Procedures/IndexPage';
import ProcedurePage from '../../Procedures/ShowPage';
import PathologiesPage from '../../Pathologies/IndexPage';
import PathologyPage from '../../Pathologies/ShowPage';
import DoctorsPage from '../../Doctors/IndexPage';
import DoctorPage from '../../Doctors/ShowPage';
import ProvidersPage from '../../Providers/IndexPage';
import ProviderPage from '../../Providers/ShowPage';
import AccountingPaymentTypesPage from '../../AccountingPaymentTypes/IndexPage';
import AccountingPaymentTypePage from '../../AccountingPaymentTypes/ShowPage';
import AccountingMovementsPage from '../../AccountingMovements/IndexPage';
import AccountingMovementPage from '../../AccountingMovements/ShowPage';
import StatementPage from '../../AccountingMovements/StatementPage';
import AccountingChartsPage from '../../AccountingCharts/IndexPage';
import AccountingChartPage from '../../AccountingCharts/ShowPage';
import BankAccountsPage from '../../BankAccounts/IndexPage';
import BankAccountPage from '../../BankAccounts/ShowPage';
import BankChecksPage from '../../BankChecks/IndexPage';
import BankCheckPage from '../../BankChecks/ShowPage';
import TreatmentPage from '../../Treatments/EditPage';
import LogsPage from '../../Logs/IndexPage';
import NotFoundPage from '../../NotFoundPage';
import ChangePassword from '../../ChangePassword';

const renderSwitch = () => (
    <Switch>
        <Route exact path="/calendar" component={CalendarPage} />
        <Route exact path={ROUTES.USERS} component={UsersPage} />
        <Route exact path={ROUTES.USER} component={UserPage} />
        <Route exact path={ROUTES.PATIENTS} component={PatientsPage} />
        <Route exact path={ROUTES.PATIENT} component={PatientPage} />
        <Route exact path={ROUTES.OFFICES} component={OfficesPage} />
        <Route exact path={ROUTES.OFFICE} component={OfficePage} />
        <Route exact path={ROUTES.PROCEDURES} component={ProceduresPage} />
        <Route exact path={ROUTES.PROCEDURE} component={ProcedurePage} />
        <Route exact path={ROUTES.PATHOLOGIES} component={PathologiesPage} />
        <Route exact path={ROUTES.PATHOLOGY} component={PathologyPage} />
        <Route exact path={ROUTES.DOCTORS} component={DoctorsPage} />
        <Route exact path={ROUTES.DOCTOR} component={DoctorPage} />
        <Route exact path={ROUTES.PROVIDERS} component={ProvidersPage} />
        <Route exact path={ROUTES.PROVIDER} component={ProviderPage} />
        <Route
            exact
            path={ROUTES.ACCOUNTING_PAYMENT_TYPES}
            component={AccountingPaymentTypesPage}
        />
        <Route exact path={ROUTES.ACCOUNTING_PAYMENT_TYPE} component={AccountingPaymentTypePage} />
        <Route exact path={ROUTES.ACCOUNTING_MOVEMENTS_STATEMENT} component={StatementPage} />
        <Route exact path={ROUTES.ACCOUNTING_MOVEMENTS} component={AccountingMovementsPage} />
        <Route exact path={ROUTES.ACCOUNTING_MOVEMENT} component={AccountingMovementPage} />
        <Route exact path={ROUTES.ACCOUNTING_CHARTS} component={AccountingChartsPage} />
        <Route exact path={ROUTES.ACCOUNTING_CHART} component={AccountingChartPage} />
        <Route exact path={ROUTES.BANK_ACCOUNTS} component={BankAccountsPage} />
        <Route exact path={ROUTES.BANK_ACCOUNT} component={BankAccountPage} />
        <Route exact path={ROUTES.BANK_CHECKS} component={BankChecksPage} />
        <Route exact path={ROUTES.BANK_CHECK} component={BankCheckPage} />
        <Route exact path={ROUTES.TREATMENT} component={TreatmentPage} />
        <Route exact path={ROUTES.LOGS} component={LogsPage} />
        <Route exact path={ROUTES.PASSWORD} component={ChangePassword} />
        <Redirect exact from={ROUTES.LANDING} to="/calendar" />
        <Route component={NotFoundPage} />
    </Switch>
);

const LoggedInRoutes = () => (
    <div>
        <Header />
        <main className="container bg-white my-3 p-2">
            {renderSwitch()}
        </main>
    </div>
);

export default LoggedInRoutes;
