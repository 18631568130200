import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import ChangePasswordForm from './ChangePasswordForm';
import authApi from '../../../api/auth';

const logger = createLogger('ChangePasswordFormik');

const ChangePasswordFormik = withFormik({
    mapPropsToValues: () => ({
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
    }),
    validationSchema: Yup.object().shape({
        currentPassword: Yup.string()
            .required('Campo obligatorio'),
        password: Yup.string()
            .min(4, 'Debe tener al menos 4 caracteres')
            .required('Campo obligatorio'),
        passwordConfirmation: Yup.string()
            .min(4, 'Debe tener al menos 4 caracteres')
            .oneOf([Yup.ref('password'), null], 'Contraseñas deben coincidir')
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
        setStatus({
            success: false,
        });
        try {
            const { currentPassword, password, passwordConfirmation } = values;
            await authApi.updatePassword(currentPassword, password, passwordConfirmation);
            resetForm();
            setStatus({
                success: true,
            });
        } catch (error) {
            logger.error('Error updating password', error);
            let message = 'No se pudo cambiar la contraseña, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                message = error.response.data.error;
            }
            setStatus({
                error: message,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'ChangePasswordForm',
})(ChangePasswordForm);

export default ChangePasswordFormik;
