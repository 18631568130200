import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import logo from '../../logo.png';
import LogInForm from './LogInForm';

const LogInPage = () => (
    <Container className="vertical-center justify-content-center text-center">
        <Card style={{ width: '18rem' }}>
            <Card.Header>
                <Image src={logo} width="100" />
            </Card.Header>
            <Card.Body>
                <LogInForm />
            </Card.Body>
        </Card>
    </Container>
);

export default LogInPage;
