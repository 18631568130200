export const LANDING = '/';
export const LOG_IN = '/login';
export const PASSWORD = '/password';
export const USERS = '/users';
export const USER = '/users/:id';
export const PATIENTS = '/patients';
export const PATIENT = '/patients/:id';
export const OFFICES = '/offices';
export const OFFICE = '/offices/:id';
export const PROCEDURES = '/procedures';
export const PROCEDURE = '/procedures/:id';
export const PATHOLOGIES = '/pathologies';
export const PATHOLOGY = '/pathologies/:id';
export const DOCTORS = '/doctors';
export const DOCTOR = '/doctors/:id';
export const PROVIDERS = '/providers';
export const PROVIDER = '/providers/:id';
export const ACCOUNTING_PAYMENT_TYPES = '/accountingPaymentTypes';
export const ACCOUNTING_PAYMENT_TYPE = '/accountingPaymentTypes/:id';
export const ACCOUNTING_MOVEMENTS = '/accountingMovements';
export const ACCOUNTING_MOVEMENT = '/accountingMovements/:id';
export const ACCOUNTING_MOVEMENTS_STATEMENT = '/accountingMovements/statement';
export const ACCOUNTING_CHARTS = '/accountingCharts';
export const ACCOUNTING_CHART = '/accountingCharts/:id';
export const BANK_ACCOUNTS = '/bankAccounts';
export const BANK_ACCOUNT = '/bankAccounts/:id';
export const BANK_CHECKS = '/bankChecks';
export const BANK_CHECK = '/bankChecks/:id';
export const LOGS = '/logs';
export const LOG = '/logs/:id';
export const SCHEDULES = '/schedules';
export const SCHEDULE = '/schedules/:id';
export const TREATMENTS = '/treatments';
export const TREATMENT = '/treatments/:id';
export const SALES = '/sales';
export const SALE = '/sales/:id';
