import createLogger from 'logging';
import { METHOD, SUB_METHOD, buildActionType } from './index';
import cacheActions from './cache';

const logger = createLogger('CommonActions');

const dispatchCache = (dispatch, values, cache = {}) => {
    const { key, mapping } = cache;
    if (!key) {
        return;
    }

    let cachedValues = values;
    if (mapping) {
        cachedValues = mapping(values);
    }

    const cacheObject = {
        [key]: cachedValues,
    };
    dispatch(cacheActions.set(cacheObject));
};

export const getIndex = (resource, api, { cache } = {}) => (filters = {}) => async (dispatch) => {
    dispatch({
        type: buildActionType(resource, METHOD.INDEX, SUB_METHOD.LOADING),
    });
    try {
        const response = await api.getIndex(filters);
        dispatch({
            type: buildActionType(resource, METHOD.INDEX),
            payload: response.data,
            filters,
        });
        dispatchCache(dispatch, filters, cache);
    } catch (error) {
        logger.error(`${resource} getIndex error:`, error);
        dispatch({
            type: buildActionType(resource, METHOD.INDEX),
            error,
        });
    }
};

export const getDetails = (resource, api) => id => async (dispatch) => {
    dispatch({
        type: buildActionType(resource, METHOD.DETAILS, SUB_METHOD.LOADING),
        id,
    });
    try {
        const response = await api.getDetails(id);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id,
            payload: response.data,
        });
    } catch (error) {
        logger.error(`${resource} getDetails error:`, error);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id,
            error,
        });
    }
};

export const update = (resource, api) => (id, attributes) => async (dispatch) => {
    try {
        const response = await api.update(id, attributes);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id,
            payload: response.data,
        });
        return response.data.record;
    } catch (error) {
        logger.error(`${resource} update error:`, error);
        throw error;
    }
};

export const create = (resource, api, { cache } = {}) => attributes => async (dispatch) => {
    try {
        const response = await api.create(attributes);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id: response.data.record.id,
            payload: response.data,
        });
        dispatchCache(dispatch, attributes, cache);
        return response.data.record;
    } catch (error) {
        logger.error(`${resource} update error:`, error);
        throw error;
    }
};

export const deletee = (resource, api) => id => async (dispatch) => {
    try {
        await api.delete(id);
        dispatch({
            type: buildActionType(resource, METHOD.DELETE),
            id,
        });
        return true;
    } catch (error) {
        logger.error(`${resource} delete error:`, error);
        throw error;
    }
};

export const getCRUD = (resource, api, { createCache, indexCache } = {}) => ({
    getIndex: getIndex(resource, api, { cache: indexCache }),
    getDetails: getDetails(resource, api),
    update: update(resource, api),
    create: create(resource, api, { cache: createCache }),
    delete: deletee(resource, api),
});

export const activate = (resource, api) => id => async (dispatch) => {
    try {
        const response = await api.activate(id);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id: response.data.record.id,
            payload: response.data,
        });
        return response.data.record;
    } catch (error) {
        logger.error(`${resource} update error:`, error);
        throw error;
    }
};

export const deactivate = (resource, api) => id => async (dispatch) => {
    try {
        const response = await api.deactivate(id);
        dispatch({
            type: buildActionType(resource, METHOD.DETAILS),
            id: response.data.record.id,
            payload: response.data,
        });
        return response.data.record;
    } catch (error) {
        logger.error(`${resource} update error:`, error);
        throw error;
    }
};

export const getActivations = (resource, api) => ({
    activate: activate(resource, api),
    deactivate: deactivate(resource, api),
});

export const getOptions = (
    resource,
    api,
    filters = { active: true, limit: 100 },
) => () => async (dispatch) => {
    dispatch({
        type: buildActionType(resource, METHOD.OPTIONS, SUB_METHOD.LOADING),
    });
    try {
        const response = await api.getIndex(filters);
        dispatch({
            type: buildActionType(resource, METHOD.OPTIONS),
            payload: response.data,
        });
    } catch (error) {
        logger.error(`${resource} getOptions error:`, error);
        dispatch({
            type: buildActionType(resource, METHOD.OPTIONS),
            error,
        });
    }
};

export const search = (resource, api) => term => async (dispatch) => {
    dispatch({
        type: buildActionType(resource, METHOD.SEARCH, SUB_METHOD.LOADING),
    });
    try {
        const options = {
            search: term,
            active: true,
            limit: 5,
            sort: 'firstName',
            sortDirection: 'asc',
        };
        const response = await api.getIndex(options);
        dispatch({
            type: buildActionType(resource, METHOD.SEARCH),
            payload: response.data,
        });
    } catch (error) {
        logger.error(`${resource} search error:`, error);
        dispatch({
            type: buildActionType(resource, METHOD.SEARCH),
            error,
        });
    }
};
