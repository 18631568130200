import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import * as ROUTES from '../../../constants/routes';
import { Attribute } from '../../RecordDetails';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';
import ButtonLogsModal from '../../Logs/ButtonLogsModal';
import dateUtil from '../../../utils/date';
import { formatRutAddingDigit } from '../../../utils/rut';
import { getFullName } from '../../../utils/user';
import SelectDoctor from './SelectDoctor.redux';
import ActionsInput from './ActionsInput';
import PathologiesInput from './PathologiesInput.redux';
import SessionsInput from './SessionsInput';

const ageDetails = (dob) => {
    if (!dob) {
        return 'Desconocida';
    }
    const date = dateUtil.formatDate(dob);
    const age = dateUtil.calculateAge(dob);
    return `${age} años (${date})`;
};

const getPatientLink = (patient) => {
    if (!patient) {
        return '';
    }

    const url = ROUTES.PATIENT.replace(':id', patient.id);
    return (
        <a href={url}>
            {getFullName(patient)}
        </a>
    );
};

class RecordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    renderActions() {
        const { isSubmitting, record } = this.props;
        return (
            <Row className="mb-4">
                <Col lg="12">
                    <ButtonLogsModal
                        title={`Tratamiento ${record.id}`}
                        filters={{ relatedObjectType: 'Treatment', relatedObjectId: record.id, limit: 10 }}
                    />
                    <Button
                        className="ml-1"
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                    <span className="ml-1">
                        Guardado el
                        {' '}
                        {dateUtil.formatDateTime(record.updatedAt)}
                    </span>
                </Col>
            </Row>
        );
    }

    renderPatientData() {
        const { record } = this.props;
        const { patient = {} } = record;
        return (
            <Row>
                <Col lg="6">
                    <Attribute name="Nombre" value={getPatientLink(patient)} labelSize={2} />
                    <Attribute name="Profesión" value={patient.profession} labelSize={2} className="mt-3" />
                </Col>
                <Col lg="6">
                    <Attribute name="Rut" value={formatRutAddingDigit(patient.rut)} labelSize={2} />
                    <Attribute name="Edad" value={ageDetails(patient.birthday)} labelSize={2} className="mt-3" />
                </Col>
            </Row>
        );
    }

    renderGeneralData() {
        const { record } = this.props;
        return (
            <>
                <legend>Ficha y Tratamiento</legend>
                {this.renderPatientData()}
                <Row>
                    <Col lg="6">
                        <BKInputLabeled
                            label="Doctor"
                            name="doctorId"
                            component={SelectDoctor}
                            labelSize={2}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <PathologiesInput pathologies={record.pathologies} />
                        <BKInputLabeled
                            label="Anamnesis"
                            name="history"
                            as="textarea"
                            rows="5"
                            placeholder="Ej: La radiografía indica..."
                            labelSize={1}
                        />
                        <BKInputLabeled
                            label="Exámenes"
                            name="exams"
                            as="textarea"
                            rows="2"
                            placeholder="Ej: La radiografía indica..."
                            labelSize={1}
                        />
                        <ActionsInput />
                        <BKInputLabeled
                            label="Notas"
                            name="notes"
                            as="textarea"
                            rows="2"
                            labelSize={1}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    render() {
        return (
            <BKForm>
                <Container>
                    {this.renderAlert()}
                    {this.renderActions()}
                    {this.renderGeneralData()}
                    <SessionsInput />
                    {this.renderActions()}
                </Container>
            </BKForm>
        );
    }
}

RecordForm.propTypes = {
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    record: PropTypes.shape({
        updatedAt: PropTypes.string,
        patient: PropTypes.shape({
            id: PropTypes.number,
        }),
        sessions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            startTime: PropTypes.string,
        })),
    }),
    isSubmitting: PropTypes.bool,
};

RecordForm.defaultProps = {
    status: {},
    record: {},
    isSubmitting: false,
};

export default RecordForm;
