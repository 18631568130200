import baseApi from './base';
import { BANK_ACCOUNTS_PATH, BANK_ACCOUNT_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(
    BANK_ACCOUNTS_PATH,
    { queryParams: filters },
);

const getDetails = async (id) => {
    const url = BANK_ACCOUNT_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = BANK_ACCOUNT_PATH.replace(':id', id);
    return baseApi.put(url, { bank_account: attributes });
};

const create = async attributes => baseApi.post(BANK_ACCOUNTS_PATH, { bank_account: attributes });

export default {
    getIndex,
    getDetails,
    update,
    create,
};
