import { connect } from 'react-redux';
import RecordForm from './RecordForm.formik';
import getNestedValue from '../../../utils/getNestedValue';

const arrayToMapById = (elements = []) => {
    const elementsMap = {};
    elements.forEach((element) => {
        elementsMap[`${element.id}`] = element;
    });
    return elementsMap;
};

const mapStateToProps = state => ({
    accountingPaymentTypes: arrayToMapById(getNestedValue(
        state,
        'accountingPaymentTypes.options.data.records',
    )),
    accountingCharts: arrayToMapById(getNestedValue(
        state,
        'accountingCharts.options.data.records',
    )),
});

export default connect(mapStateToProps)(RecordForm);
