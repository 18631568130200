import React from 'react';
import Badge from 'react-bootstrap/Badge';

const CellActive = ({ value, original }) => {
    if (original && original.active === false) {
        return (
            <span>
                {value}
                {' '}
                <Badge variant="danger">Inactivo</Badge>
            </span>
        );
    }
    return value;
};

export default CellActive;
