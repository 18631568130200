import React from 'react';
import FilterSelect from './FilterSelect';

const OPTIONS = [
    { value: '', label: 'Activos e inactivos' },
    { value: 'true', label: 'Activos' },
    { value: 'false', label: 'Inactivos' },
];

const FilterActive = props => (
    <FilterSelect
        {...props}
        options={OPTIONS}
        identifier="active"
    />
);

export default FilterActive;
