import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BKInput from './BKInput';

const BKInputLabeled = ({ label, labelSize, ...rest }) => (
    <Form.Group as={Row}>
        <Form.Label column sm={labelSize} className="text-muted text-nowrap">
            <small className="float-md-right">
                {label}
            </small>
        </Form.Label>
        <Col sm={12 - labelSize}>
            <BKInput
                {...rest}
            />
        </Col>
    </Form.Group>
);

BKInputLabeled.propTypes = {
    label: PropTypes.string.isRequired,
    labelSize: PropTypes.number,
};

BKInputLabeled.defaultProps = {
    labelSize: 4,
};

export default BKInputLabeled;
