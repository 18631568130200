import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from './SelectInput';

const FIVE_MINS = 5 * 60 * 1000;
const shouldUpdate = (loading, error, updated) => {
    if (loading) {
        return false;
    }

    if (error || !updated) {
        return true;
    }

    const timeLapsed = new Date() - updated;
    return timeLapsed > FIVE_MINS;
};

class SelectInputRemote extends React.Component {
    constructor(props) {
        super(props);
        this.loadOptions();

        this.getPlaceholder = this.getPlaceholder.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

    getPlaceholder() {
        const { loading, error } = this.props;
        if (loading) {
            return 'Cargando...';
        }

        if (error) {
            return `Error: ${error.message}`;
        }

        return undefined;
    }

    getOptions() {
        const { emptyOption } = this.props;
        if (emptyOption) {
            return [
                emptyOption,
                ...this.getRecordsOptions(),
            ];
        }
        return this.getRecordsOptions();
    }

    getRecordsOptions() {
        const { records, recordsToOptions } = this.props;

        if (!records) {
            return [];
        }

        return recordsToOptions(records);
    }

    loadOptions() {
        const {
            getRecords, loading, error, updated,
        } = this.props;
        if (shouldUpdate(loading, error, updated)) {
            getRecords();
        }
    }

    render() {
        return (
            <SelectInput
                placeholder={this.getPlaceholder()}
                options={this.getOptions()}
                {...this.props}
            />
        );
    }
}

SelectInputRemote.propTypes = {
    getRecords: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    recordsToOptions: PropTypes.func.isRequired,
    emptyOption: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({})),
    updated: PropTypes.instanceOf(Date),
};

SelectInputRemote.defaultProps = {
    emptyOption: undefined,
    error: undefined,
    records: undefined,
    updated: undefined,
};

export default SelectInputRemote;
