import React from 'react';
import FilterSelectRemote from './FilterSelectRemote';

const recordsToOptions = (records) => {
    const options = {};
    records.forEach((record) => {
        if (!options[record.subcategory]) {
            options[record.subcategory] = {
                label: record.subcategory,
                options: [],
            };
        }
        options[record.subcategory].options.push({
            value: `${record.id}`,
            label: `${record.name} - ${record.chartNumber}`,
        });
    });
    return Object.values(options);
};

const FilterAccountingCharts = props => (
    <FilterSelectRemote
        {...props}
        recordsToOptions={recordsToOptions}
        emptyOption={{ value: '', label: 'Cuentas contables: Todas' }}
        identifier="accountingChart"
    />
);

export default FilterAccountingCharts;
