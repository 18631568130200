const getCategoryLabel = (loading, error, resource) => {
    if (loading) {
        return `Cargando ${resource}`;
    }

    return resource;
};

const getOptions = (records, resourceToLabel, resourceUrl) => {
    if (!records) {
        return [];
    }
    return records.map(record => ({
        value: resourceUrl.replace(':id', record.id),
        label: resourceToLabel(record),
    }));
};

const shouldShowCategory = (loading, error, options) => {
    if (loading) {
        return true;
    }

    if (error) {
        return false;
    }

    return options.length > 0;
};

const parseOptions = ({
    resource, loading, error, records, resourceToLabel, resourceUrl,
}) => {
    const label = getCategoryLabel(loading, error, resource);
    const options = getOptions(records, resourceToLabel, resourceUrl);

    if (shouldShowCategory(loading, error, options)) {
        return [{
            label,
            options,
        }];
    }

    return [];
};

export default parseOptions;
