import React from 'react';
import PropTypes from 'prop-types';
import CheckStateForm from '../StateForm';

const CellCheckState = ({ original }) => (
    <CheckStateForm key={`CellCheckState${original.id}`} record={original} />
);

CellCheckState.propTypes = {
    original: PropTypes.shape({}).isRequired,
};

export default CellCheckState;
