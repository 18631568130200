import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import BKForm from '../../BKForm';
import BKInput from '../../BKForm/BKInput';
import RutControl from '../../RutControl';

class LogInForm extends React.Component {
    renderWarning() {
        const { warning } = this.props;
        if (warning) {
            return <Alert variant="warning">{warning}</Alert>;
        }
        return '';
    }

    renderAlert() {
        const { status } = this.props;
        const { error } = status;
        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    renderButton() {
        const { isSubmitting } = this.props;
        if (isSubmitting) {
            return <Spinner animation="border" />;
        }
        return <Button variant="primary" type="submit">Iniciar sesión</Button>;
    }

    render() {
        return (
            <BKForm>
                {this.renderWarning()}
                {this.renderAlert()}
                <Form.Group>
                    <BKInput
                        name="username"
                        placeholder="Rut"
                        autoComplete="username"
                        component={RutControl}
                    />
                </Form.Group>
                <Form.Group>
                    <BKInput
                        name="password"
                        type="password"
                        placeholder="Contraseña"
                        autoComplete="current-password"
                    />
                </Form.Group>
                {this.renderButton()}
            </BKForm>
        );
    }
}

LogInForm.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    status: PropTypes.shape({
        error: PropTypes.string,
    }),
    warning: PropTypes.string,
};

LogInForm.defaultProps = {
    status: {},
    warning: undefined,
};

export default LogInForm;
