import baseApi from './base';
import {
    PROVIDERS_PATH, PROVIDERS_UNIQ_PATH, PROVIDER_PATH, PROVIDER_ACTIVE_PATH,
} from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(PROVIDERS_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = PROVIDER_PATH.replace(':id', id);
    return baseApi.get(url);
};

const getUniq = async (attribute, value, id) => {
    const queryParams = {
        attribute,
        value,
        ...id && { id },
    };
    return baseApi.get(PROVIDERS_UNIQ_PATH, { queryParams });
};

const update = async (id, attributes) => {
    const url = PROVIDER_PATH.replace(':id', id);
    return baseApi.put(url, { provider: attributes });
};

const create = async attributes => baseApi.post(PROVIDERS_PATH, { provider: attributes });

const activate = async (id) => {
    const url = PROVIDER_ACTIVE_PATH.replace(':id', id);
    return baseApi.post(url);
};

const deactivate = async (id) => {
    const url = PROVIDER_ACTIVE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    getUniq,
    update,
    create,
    activate,
    deactivate,
};
