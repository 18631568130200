import { combineReducers } from 'redux';
import { RESOURCE } from '../../actions';
import { indexReducer, detailsReducer, optionsReducer } from '../common';
import treatments from './treatments';

export default combineReducers({
    index: indexReducer(RESOURCE.DOCTORS),
    details: detailsReducer(RESOURCE.DOCTORS),
    options: optionsReducer(RESOURCE.DOCTORS),
    treatments,
});
