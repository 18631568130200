const sanetizePath = (path) => {
    let sanetizedPath = path.replace(/^\./, '');
    sanetizedPath = sanetizedPath.replace(/\[(\w+)\]/g, '.$1');
    return sanetizedPath;
};

const getPathArray = (path) => {
    if (!path) {
        return path;
    }

    const sanetizedPath = sanetizePath(path);
    return sanetizedPath.split('.');
};

const getNestedValueForPathArray = (object, pathKeys) => {
    if (!object || !pathKeys || pathKeys.length === 0) {
        return object;
    }
    const key = pathKeys[0];
    const nestedObject = object[key];
    return getNestedValueForPathArray(nestedObject, pathKeys.slice(1));
};

const getNestedValue = (object, path) => {
    const pathKeys = getPathArray(path);
    return getNestedValueForPathArray(object, pathKeys);
};

export default getNestedValue;
