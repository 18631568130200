import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Loading, Attribute } from '../../RecordDetails';
import Actions from './Actions';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.ACCOUNTING_CHARTS, label: 'Plan de Cuentas' },
];

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        const sign = record.sign > 0 ? '+' : '-';
        const sumForAccountabiliy = record.sumForAccountabiliy ? 'Sí' : 'No';

        return (
            <div>
                <Row>
                    <Col lg="6">
                        <Attribute optional name="Correlativo" value={record.orderNumber} />
                        <Attribute optional name="Categoría" value={record.category} />
                        <Attribute optional name="Subcategoría" value={record.subcategory} />
                        <Attribute optional name="Nombre" value={record.name} />
                    </Col>
                    <Col lg="6">
                        <Attribute optional name="Número" value={record.chartNumber} />
                        <Attribute optional name="Ingreso/Egreso" value={sign} />
                        <Attribute optional name="Considerar en Informe de Gestión" value={sumForAccountabiliy} />
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: `${record.orderNumber}` },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
