import { STATUS } from '../constants/api';

export const mapIndexStateToProps = (apiState = {}) => {
    const {
        status, error, updated, data = {},
    } = apiState;
    return {
        loading: status === STATUS.LOADING,
        error,
        records: data.records,
        totalRecords: data.totalRecords,
        updated,
    };
};

export const mapDetailsStateToProps = (apiState = {}, ownProps = {}) => {
    const { match: { params } } = ownProps;
    const {
        status, error, updated, data = {},
    } = apiState;
    return {
        recordId: params.id,
        loading: status === STATUS.LOADING,
        error,
        record: data.record,
        updated,
    };
};
