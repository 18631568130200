import baseApi from './base';
import { ACCOUNTING_MOVEMENTS_PATH, ACCOUNTING_MOVEMENT_PATH, ACCOUNTING_MOVEMENTS_STATEMENT_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(
    ACCOUNTING_MOVEMENTS_PATH,
    { queryParams: filters },
);

const getStatement = async (filters = {}) => baseApi.get(
    ACCOUNTING_MOVEMENTS_STATEMENT_PATH,
    { queryParams: filters },
);

const getDetails = async (id) => {
    const url = ACCOUNTING_MOVEMENT_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = ACCOUNTING_MOVEMENT_PATH.replace(':id', id);
    return baseApi.put(url, { accounting_movement: attributes });
};

const create = async attributes => baseApi.post(
    ACCOUNTING_MOVEMENTS_PATH,
    { accounting_movement: attributes },
);

const deletee = async (id) => {
    const url = ACCOUNTING_MOVEMENT_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getStatement,
    getDetails,
    update,
    create,
    delete: deletee,
};
