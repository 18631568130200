import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingMovements';
import StatementDetailsModal from './StatementDetailsModal';
import getNestedValue from '../../../utils/getNestedValue';

const getOfficeById = (state, officeId) => {
    if (!officeId) {
        return undefined;
    }
    const offices = getNestedValue(state, 'filters.offices.data.records');
    if (!offices) {
        return undefined;
    }
    const id = Number(officeId);
    return offices.find(office => Number(office.id) === id);
};

const mapStateToProps = (state, ownProps) => ({
    office: getOfficeById(state, ownProps.office),
    filters: getNestedValue(state, 'accountingMovements.statementDetails.filters'),
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        clearStatementDetails: actions.clearStatementDetails,
        getStatementDetails: actions.getStatementDetails,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(StatementDetailsModal);
