import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/treatments';
import EditPage from './EditPage';
import { mapDetailsStateToProps } from '../../../utils/apiStateToProps';

const mapStateToProps = (state, ownProps) => mapDetailsStateToProps(
    state.treatments.details,
    ownProps,
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getDetails: actions.getDetails,
    updateRecord: actions.update,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
