import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { GoTrashcan } from 'react-icons/go';
import { connect, Field, FieldArray } from 'formik';
import SearchPathology from './SearchPathology.redux';

const pathologyDetails = record => [record.bodySection, record.bodyPart, record.injury].join('/');

const PathologiesInput = ({ formik, pathologiesMap }) => {
    const { values } = formik;
    const pathologyDetailsForId = recordId => pathologyDetails(pathologiesMap[recordId]);
    const pathologyFields = arrayHelpers => (pathologyId, index) => (
        <tr key={pathologyId}>
            <td>
                {pathologyDetailsForId(pathologyId)}
                <Field type="hidden" name={`pathologyIds.${index}`} />
            </td>
            <td className="wrap-content-column">
                <OverlayTrigger overlay={<Tooltip>Eliminar</Tooltip>}>
                    <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => arrayHelpers.remove(index)}
                    >
                        <GoTrashcan />
                    </Button>
                </OverlayTrigger>
            </td>
        </tr>
    );
    const onPathologySelect = arrayHelpers => (newValue) => {
        if (values?.pathologyIds?.includes(newValue)) {
            return;
        }
        arrayHelpers.push(newValue);
    };

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={1} className="text-muted text-nowrap">
                <small className="float-md-right">
                    Diagnóstico
                </small>
            </Form.Label>
            <Col sm={11}>
                <FieldArray
                    name="pathologyIds"
                    render={arrayHelpers => (
                        <div>
                            <SearchPathology onSelect={onPathologySelect(arrayHelpers)} />
                            <Table bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Patología</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {(values?.pathologyIds && values.pathologyIds.length > 0)
                                        ? values.pathologyIds.map(pathologyFields(arrayHelpers))
                                        : (
                                            <tr>
                                                <td colSpan={2} className="font-italic text-center">
                                                    No se han ingresado patologías
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )}
                />
            </Col>
        </Form.Group>
    );
};

PathologiesInput.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            pathologyIds: PropTypes.arrayOf(PropTypes.string),
        }),
    }).isRequired,
    pathologiesMap: PropTypes.shape({}).isRequired,
};

export default connect(PathologiesInput);
