import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { Loading, Attribute } from '../../RecordDetails';
import Actions from './Actions';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.PATHOLOGIES, label: 'Patologías' },
];

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        <Attribute name="Lesión" value={record.injury} />
                        <Attribute name="Parte del cuerpo" value={record.bodyPart} />
                        <Attribute name="Sección del cuerpo" value={record.bodySection} />
                        {this.renderState()}
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderState() {
        const { record } = this.props;
        const { active } = record;

        let loginActive;
        if (active === true) {
            loginActive = <Badge variant="success">Activo</Badge>;
        } else if (active === false) {
            loginActive = <Badge variant="danger">Inactivo</Badge>;
        }
        return (
            <Attribute optional name="Estado" value={loginActive} />
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        const label = [record.bodySection, record.bodyPart, record.injury].join('/');
        return [
            ...BREADCRUMB,
            { label },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
