import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import storeManager from './utils/storeManager';
import Routes from './components/Routes';

const App = () => (
    <Provider store={storeManager.getStore()}>
        <Routes />
    </Provider>
);

export default App;
