import baseApi from './base';
import {
    SESSIONS_PATH,
} from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(SESSIONS_PATH, { queryParams: filters });

export default {
    getIndex,
};
