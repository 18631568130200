import baseApi from '../api/base';
import cacheActions from '../actions/cache';
import { updateAbilityForUser } from './ability';

const getToken = state => state.auth && state.auth.accessToken;
const getUser = state => state.auth && state.auth.user;

let currentToken;
export default (state, dispatch) => {
    const previousToken = currentToken;
    currentToken = getToken(state);

    if (previousToken !== currentToken) {
        baseApi.setToken(currentToken);
        dispatch(cacheActions.reload(currentToken));
        const currentUser = getUser(state);
        updateAbilityForUser(currentUser);
    }
};
