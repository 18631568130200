import React from 'react';
import PropTypes from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import './FilterDateRange.css';
import FilterDate from './FilterDate';
import dateUtil from '../../utils/date';

const FilterDateRange = (props) => {
    const {
        identifierFrom, defaultValueFrom,
        identifierTo, defaultValueTo,
    } = props;
    const startDate = dateUtil.parseDatetime(defaultValueFrom);
    const endDate = dateUtil.parseDatetime(defaultValueTo);

    return (
        <InputGroup className="filter-date-range flex-nowrap">
            <FilterDate
                {...props}
                selectsStart
                identifier={identifierFrom}
                defaultValue={defaultValueFrom}
                startDate={startDate}
                endDate={endDate}
            />
            <InputGroup.Prepend className="input-group-append">
                <InputGroup.Text>-</InputGroup.Text>
            </InputGroup.Prepend>
            <FilterDate
                {...props}
                selectsEnd
                identifier={identifierTo}
                defaultValue={defaultValueTo}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
            />
        </InputGroup>
    );
};

FilterDateRange.propTypes = {
    identifierFrom: PropTypes.string.isRequired,
    identifierTo: PropTypes.string.isRequired,
    defaultValueFrom: PropTypes.string,
    defaultValueTo: PropTypes.string,
};

FilterDateRange.defaultProps = {
    defaultValueFrom: '',
    defaultValueTo: '',
};

export default FilterDateRange;
