import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'name',
    'phone',
    'schedule',
    'responsible',
    'officeType',
    'imedCode',
    'saleAllocationPercentage',
    'saleAllocationSpecialPercentage',
    'saleRetentionPercentage',
    'address',
    'borough',
    'city',
    'state',
    'country',
];

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => {
        const values = ATTRIBUTES.reduce((obj, key) => ({
            ...obj,
            [key]: typeof record[key] !== 'undefined' ? record[key] : '',
        }), {});

        values.responsibleId = values.responsible ? values.responsible.id.toString() : '';
        values.officeType = typeof values.officeType !== 'undefined' ? values.officeType.toString() : '';
        delete values.responsible;
        return values;
    },
    validationSchema: () => Yup.object().shape({
        name: Yup.string()
            .required('Campo obligatorio'),
        address: Yup.string()
            .required('Campo obligatorio'),
        saleAllocationPercentage: Yup.number()
            .required('Campo obligatorio')
            .min(0, 'No puede ser menor a 0')
            .max(100, 'No puede ser mayor a 100'),
        saleAllocationSpecialPercentage: Yup.number()
            .required('Campo obligatorio')
            .min(0, 'No puede ser menor a 0')
            .max(100, 'No puede ser mayor a 100'),
        saleRetentionPercentage: Yup.number()
            .required('Campo obligatorio')
            .min(0, 'No puede ser menor a 0')
            .max(100, 'No puede ser mayor a 100'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            await onSubmit(values);
        } catch (error) {
            logger.error('Error updating office:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
