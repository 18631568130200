import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: record.title,
    }
));

const SelectInputAccountingPaymentType = props => (
    <SelectInputRemote
        {...props}
        recordsToOptions={recordsToOptions}
    />
);

export default SelectInputAccountingPaymentType;
