import { Service } from 'axios-middleware';
import createLogger from 'logging';
import storeManager from '../utils/storeManager';
import { sessionExpired } from '../actions/auth';

const logger = createLogger('Auth Middleware');

const EXPIRED_ERROR_MESSAGES = [
    'Signature has expired',
    'Tienes que registrarte o iniciar sesión antes de continuar.',
];
const isUnauthorizeError = (error) => {
    const response = error && error.response;
    if (!response || response.status !== 401 || !response.data) {
        return false;
    }

    try {
        const data = JSON.parse(response.data);
        return EXPIRED_ERROR_MESSAGES.includes(data.error);
    } catch {
        return false;
    }
};

const dispatchSessionExpired = () => {
    logger.warn('Session expired');
    storeManager.getStore().dispatch(sessionExpired());
};

export default (axiosClient) => {
    const service = new Service(axiosClient);
    service.register({
        onResponseError(error) {
            if (isUnauthorizeError(error)) {
                dispatchSessionExpired();
            }
            throw error;
        },
    });
};
