import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import officeActions from '../../../actions/offices';
import cacheActions from '../../../actions/cache';
import NavOffice from './NavOffice';
import { mapIndexStateToProps } from '../../../utils/apiStateToProps';
import getNestedValue from '../../../utils/getNestedValue';

const mapStateToProps = state => ({
    ...mapIndexStateToProps(getNestedValue(state, 'offices.options')),
    currentOffice: getNestedValue(state, 'cache.currentOffice'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getRecords: officeActions.getOptions,
    setCurrent: currentOffice => cacheActions.set({ currentOffice }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavOffice);
