import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import './Header.css';
import * as ROUTES from '../../constants/routes';
import logo from '../../logo.png';
import { getFullName, FORMAT_INITIALS } from '../../utils/user';
import NavOffice from './NavOffice';
import NavSearch from './NavSearch';

const patientsDropdown = () => (
    <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
            Pacientes
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item as={Link} to={ROUTES.PATIENTS}>
                Ver todos
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

const accountingMovementsDropdown = () => (
    <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
            Contabilidad
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNTING_MOVEMENTS}>
                Flujo de caja
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNTING_MOVEMENTS_STATEMENT}>
                Informe de gestión
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

const moreDropdown = () => (
    <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
            Más
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item as={Link} to={ROUTES.OFFICES}>Oficinas</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.USERS}>Usuarios</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={ROUTES.PROCEDURES}>Procedimientos</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.PATHOLOGIES}>Patologías</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.DOCTORS}>Médicos</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={ROUTES.PROVIDERS}>Proveedores</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.BANK_ACCOUNTS}>Cuentas bancarias</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.BANK_CHECKS}>Cheques</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNTING_CHARTS}>Plan de cuentas</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNTING_PAYMENT_TYPES}>
                Módos de pago
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={ROUTES.LOGS}>Actividad</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

const userDropdown = (user, logOut) => (
    <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
            {getFullName(user, FORMAT_INITIALS)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-right">
            <Dropdown.Item as={Link} to={ROUTES.USER.replace(':id', user.id)}>Mi información</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.PASSWORD}>Cambiar contraseña</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logOut}>Cerrar sesión</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

const Header = ({ user, logOut }) => (
    <Navbar collapseOnSelect bg="white">
        <Link to={ROUTES.LANDING}>
            <Image src={logo} height="30" className="d-inline-block align-top" />
        </Link>
        <Navbar.Collapse>
            <Nav className="mr-auto">
                {patientsDropdown()}
                {accountingMovementsDropdown()}
                {moreDropdown()}
            </Nav>
            <Nav>
                <NavSearch />
                <NavOffice />
                {userDropdown(user, logOut)}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

Header.propTypes = {
    logOut: PropTypes.func.isRequired,
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
};

export default Header;
