import baseApi from './base';
import {
    USERS_PATH, USER_PATH, USERS_UNIQ_PATH, USER_PASSWORD_PATH, USER_ACTIVE_PATH,
} from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(USERS_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = USER_PATH.replace(':id', id);
    return baseApi.get(url);
};

const getUniq = async (attribute, value, id) => {
    const queryParams = {
        attribute,
        value,
        ...id && { id },
    };
    return baseApi.get(USERS_UNIQ_PATH, { queryParams });
};

const update = async (id, attributes) => {
    const url = USER_PATH.replace(':id', id);
    return baseApi.put(url, { user: attributes });
};

const create = async attributes => baseApi.post(USERS_PATH, { user: attributes });

const resetPassword = async (id) => {
    const url = USER_PASSWORD_PATH.replace(':id', id);
    return baseApi.delete(url);
};

const activate = async (id) => {
    const url = USER_ACTIVE_PATH.replace(':id', id);
    return baseApi.post(url);
};

const deactivate = async (id) => {
    const url = USER_ACTIVE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    getUniq,
    update,
    create,
    resetPassword,
    activate,
    deactivate,
};
