import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import dateUtil from '../../utils/date';
import './InputDate.css';

registerLocale('es', es);

const InputDate = (props) => {
    const {
        name, value, formik: { setFieldValue }, setFieldValueOverride, ...rest
    } = props;

    const valueInDate = dateUtil.parseDatetime(value);

    const onChange = (newDate) => {
        const dateString = dateUtil.formatDateTime(newDate, '%Y-%m-%d');
        const setField = setFieldValueOverride || setFieldValue;
        setField(name, dateString);
    };
    return (
        <div className="InputDate">
            <DatePicker
                name={name}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
                dropdownMode="select"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                todayButton="Hoy"
                locale="es"
                {...rest}
                selected={valueInDate}
                onChange={onChange}
            />
        </div>
    );
};

InputDate.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    formik: PropTypes.shape({}),
    setFieldValueOverride: PropTypes.func,
};

InputDate.defaultProps = {
    name: '',
    value: undefined,
    formik: {},
    setFieldValueOverride: undefined,
};

export default InputDate;
