import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './StatementFilter.css';
import FilterYear from './FilterYear';
import FilterOffice from '../../Table/FilterOffice.redux';
import ButtonExcel from '../../Table/ButtonExcel';

const StatementFilter = ({ onChange, filters }) => (
    <Row className="mb-2">
        <Col xs md="3">
            <FilterYear
                defaultValue={filters.year}
                onChange={onChange}
                className="StatementFilter"
            />
        </Col>
        <Col xs md="4">
            <FilterOffice
                defaultValue={filters.office}
                onChange={onChange}
                className="StatementFilter"
            />
        </Col>
        <Col xs md="3">
            <ButtonExcel
                table="StatementTable"
                fileName="Informe de Gestión ABS"
            />
        </Col>
    </Row>
);

StatementFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        office: PropTypes.string,
        year: PropTypes.string,
    }).isRequired,
};

export default StatementFilter;
