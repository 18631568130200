import { connect } from 'react-redux';
import PathologiesInput from './PathologiesInput';
import getNestedValue from '../../../utils/getNestedValue';

const mapById = elements => elements.reduce((map, element) => {
    // eslint-disable-next-line no-param-reassign
    map[element.id] = element;
    return map;
}, {});

const mapStateToProps = (state, ownProps) => {
    const allPathologies = getNestedValue(state, 'pathologies.options.data.records') || [];
    const recordPathologies = ownProps.pathologies || [];
    return {
        pathologiesMap: mapById([
            ...allPathologies,
            ...recordPathologies,
        ]),
    };
};

export default connect(mapStateToProps)(PathologiesInput);
