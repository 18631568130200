import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import BookForm from './BookForm.formik';

const CreateMultipleModal = ({
    show, onHide, onSuccess, createMultipleRecords,
}) => {
    const onSubmit = async (attributes) => {
        const record = await createMultipleRecords(attributes);
        onSuccess(record);
    };
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar talonario de cheques bancarios
                </Modal.Title>
            </Modal.Header>
            <BookForm onCancel={onHide} onSubmit={onSubmit} />
        </Modal>
    );
};

CreateMultipleModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    createMultipleRecords: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
};

CreateMultipleModal.defaultProps = {
    onSuccess: () => { },
    show: false,
};

export default CreateMultipleModal;
