import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const STATUS = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
};

const ConfirmModal = ({
    message, activeOptions, inactiveOptions, show, onSelect, status,
}) => {
    const onSelectWrapper = (...attributes) => {
        if (status !== STATUS.LOADING) {
            onSelect(...attributes);
        }
    };

    let options = activeOptions;
    if (status === STATUS.ERROR || status === STATUS.COMPLETED) {
        options = inactiveOptions;
    }

    const buttons = options.map(option => (
        <Button
            key={option.title}
            onClick={() => onSelectWrapper(option.value)}
            disabled={status === STATUS.LOADING}
            {...option.props}
        >
            {option.title}
        </Button>
    ));

    return (
        <Modal
            show={show}
            onHide={() => onSelectWrapper(undefined)}
        >
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                {buttons}
            </Modal.Footer>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    onSelect: PropTypes.func.isRequired,
    show: PropTypes.bool,
    message: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(STATUS)).isRequired,
    activeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
            ]),
        }),
    ),
    inactiveOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
            ]),
        }),
    ),
};

ConfirmModal.defaultProps = {
    show: false,
    activeOptions: [
        { title: 'No', value: false, props: { variant: 'outline-secondary' } },
        { title: 'Sí', value: true, props: { variant: 'outline-primary' } },
    ],
    inactiveOptions: [
        { title: 'Cerrar', value: false, props: { variant: 'outline-secondary' } },
    ],
};

export default ConfirmModal;
