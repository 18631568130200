import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import RecordForm from '../RecordForm';


const EditModal = ({
    record, show, onHide, updateRecord,
}) => {
    const onSubmit = async (attributes) => {
        await updateRecord(record.id, attributes);
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar cuenta bancaria
                </Modal.Title>
            </Modal.Header>
            <RecordForm record={record} onCancel={onHide} onSubmit={onSubmit} />
        </Modal>
    );
};

EditModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    updateRecord: PropTypes.func.isRequired,
    show: PropTypes.bool,
    record: PropTypes.shape({}).isRequired,
};

EditModal.defaultProps = {
    show: false,
};

export default EditModal;
