import { LOG_IN, LOG_OUT } from '../actions';
import tokenParser from '../utils/tokenParser';

export const CACHE_KEY_ACCESS_TOKEN = 'auth.accessToken';

export const getCachedToken = () => localStorage.getItem(CACHE_KEY_ACCESS_TOKEN);
const parseToken = (accessToken) => {
    const parsedValue = tokenParser.parse(accessToken);
    if (parsedValue) {
        return {
            ...parsedValue,
            accessToken,
        };
    }
    return {};
};

const loadCachedValue = () => {
    const cachedToken = getCachedToken();
    if (cachedToken) {
        return parseToken(cachedToken);
    }
    return {};
};

const logIn = (action) => {
    const token = action.payload;
    localStorage.setItem(CACHE_KEY_ACCESS_TOKEN, token);
    return parseToken(token);
};

const logOut = (action) => {
    localStorage.removeItem(CACHE_KEY_ACCESS_TOKEN);
    if (action.payload && action.payload.warning) {
        return {
            warning: action.payload.warning,
        };
    }
    return {};
};

const reducers = (state = loadCachedValue(), action) => {
    switch (action.type) {
    case LOG_IN:
        return logIn(action);
    case LOG_OUT:
        return logOut(action);
    default:
        return state;
    }
};

export default reducers;
