const checkDetails = (original) => {
    if (original && original.bankCheck && original.bankCheck.checkNumber) {
        return ` (${original.bankCheck.checkNumber})`;
    }
    return '';
};

const orEmpty = value => (value === undefined ? '' : value);

const CellCheck = ({ value, original }) => `${orEmpty(value)}${checkDetails(original)}`;

export default CellCheck;
