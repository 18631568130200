import baseApi from './base';
import { PATHOLOGIES_PATH, PATHOLOGY_PATH, PATHOLOGY_ACTIVE_PATH } from '../constants/api';

const getIndex = async (filters = {}) => baseApi.get(PATHOLOGIES_PATH, { queryParams: filters });

const getDetails = async (id) => {
    const url = PATHOLOGY_PATH.replace(':id', id);
    return baseApi.get(url);
};

const update = async (id, attributes) => {
    const url = PATHOLOGY_PATH.replace(':id', id);
    return baseApi.put(url, { pathology: attributes });
};

const create = async attributes => baseApi.post(PATHOLOGIES_PATH, { pathology: attributes });

const activate = async (id) => {
    const url = PATHOLOGY_ACTIVE_PATH.replace(':id', id);
    return baseApi.post(url);
};

const deactivate = async (id) => {
    const url = PATHOLOGY_ACTIVE_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    getIndex,
    getDetails,
    update,
    create,
    activate,
    deactivate,
};
