import { withFormik } from 'formik';
import * as Yup from 'yup';
import LogInForm from './LogInForm';

const keepOnlyDigits = text => text.replace(/\D+/g, '');

const LogInFormik = withFormik({
    mapPropsToValues: () => ({
        username: '',
        password: '',
    }),
    validationSchema: Yup.object().shape({
        username: Yup.string()
            .min(2, 'Debe tener al menos 2 caracteres')
            .required('Campo obligatorio'),
        password: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        try {
            const { logIn } = props;
            const { username, password } = values;
            const cleanRut = keepOnlyDigits(username);
            await logIn(cleanRut, password);
        } catch (error) {
            setStatus({
                error: error.message,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'LogInForm',
})(LogInForm);

export default LogInFormik;
