import React from 'react';
import FilterSelect from '../../Table/FilterSelect';
import { STATUS } from '../../../utils/bankCheck';

const statusToOptions = status => status.join(',');

const NON_IDLE_STATUS = statusToOptions([
    STATUS.ISSUED,
    STATUS.PAID,
    STATUS.NULL,
    STATUS.STOP_PAYMENT,
    STATUS.STOLEN,
]);

const IDLE_STATUS = statusToOptions([
    STATUS.IDLE,
]);

const OPTIONS = [
    { value: '', label: 'Estado: Todos' },
    { value: NON_IDLE_STATUS, label: 'Con movimiento' },
    { value: IDLE_STATUS, label: 'Sin Movimiento' },
];

const FilterState = props => (
    <FilterSelect
        {...props}
        options={OPTIONS}
        identifier="state"
    />
);

export default FilterState;
