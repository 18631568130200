import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Loading, Attribute } from '../../RecordDetails';
import Actions from './Actions.router';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import dateUtil from '../../../utils/date';
import currency from '../../../utils/currency';
import CheckStateSelect from '../../BankChecks/StateForm';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.ACCOUNTING_MOVEMENTS, label: 'Flujo de caja' },
];

const officeLink = (record) => {
    const { office } = record;
    if (!office) {
        return undefined;
    }
    const url = ROUTES.OFFICE.replace(':id', office.id);
    return (
        <a href={url}>
            {office.name}
        </a>
    );
};

const bankAccountLink = (record) => {
    const { bankAccount } = record;
    if (!bankAccount) {
        return undefined;
    }
    const url = ROUTES.BANK_ACCOUNT.replace(':id', bankAccount.id);
    return (
        <a href={url}>
            {bankAccount.bankName}
            {' / '}
            {bankAccount.accountNumber}
        </a>
    );
};

const providerLink = (record) => {
    const { provider } = record;
    if (!provider) {
        return undefined;
    }
    const url = ROUTES.PROVIDER.replace(':id', provider.id);
    return (
        <a href={url}>
            {provider.name}
        </a>
    );
};

const accountingChartInfo = (record) => {
    const { accountingChart } = record;
    if (!accountingChart) {
        return undefined;
    }
    return `${accountingChart.name} / ${accountingChart.subcategory} / ${accountingChart.chartNumber}`;
};

const accountingPaymentTypeInfo = (record) => {
    const { accountingPaymentType, bankCheck } = record;
    if (!accountingPaymentType) {
        return undefined;
    }

    let bankCheckLink;
    let bankCheckState;
    if (bankCheck) {
        const url = ROUTES.BANK_CHECK.replace(':id', bankCheck.id);
        bankCheckLink = <a href={url}>{bankCheck.checkNumber}</a>;
        bankCheckState = <CheckStateSelect record={bankCheck} />;
    }

    return (
        <span>
            {accountingPaymentType.title}
            {' '}
            {bankCheckLink}
            <br />
            {bankCheckState}
        </span>
    );
};

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        <Attribute name="Oficina" value={officeLink(record)} />
                        <Attribute name="Cuenta" value={bankAccountLink(record)} />
                        <Attribute name="Fecha documento" value={dateUtil.formatDate(record.docDate)} />
                        <Attribute name="Fecha flujo de caja" value={dateUtil.formatDate(record.flowDate)} />
                        <Attribute name="Monto" value={currency(record.amount)} />
                    </Col>
                    <Col lg="6">
                        <Attribute optional name="Proveedor" value={providerLink(record)} />
                        <Attribute name="Cuenta contable" value={accountingChartInfo(record)} />
                        <Attribute name="Módo de pago" value={accountingPaymentTypeInfo(record)} />
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: `Movimiento ${record.id}` },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
