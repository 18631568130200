import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const InputCurrency = (props) => {
    const {
        name, value, formik: { setFieldValue }, ...rest
    } = props;

    const onChange = (event) => {
        const newValue = event.target.value;
        setFieldValue(name, newValue);
    };
    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    $
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                name={name}
                {...rest}
                value={value}
                onChange={onChange}
            />
        </InputGroup>
    );
};

InputCurrency.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    formik: PropTypes.shape({}),
};

InputCurrency.defaultProps = {
    name: '',
    value: undefined,
    formik: {},
};

export default InputCurrency;
