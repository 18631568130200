import React from 'react';
import PropTypes from 'prop-types';
import phoneUtil from '../../utils/phone';

const LinkPhone = ({ number }) => {
    if (!number) {
        return null;
    }

    return (
        <a href={`tel:${number}`}>
            {phoneUtil.formatPhone(number)}
        </a>
    );
};

LinkPhone.propTypes = {
    number: PropTypes.string,
};

LinkPhone.defaultProps = {
    number: undefined,
};

export default LinkPhone;
