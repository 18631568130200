import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCreateModal from '../Patients/CreateModal/ButtonCreateModal';
import KinesiologistsFilter from './KinesiologistsFilter.redux';

function Actions({ onChange, filters }) {
    const onSuccess = () => console.log('On patient create');
    return (
        <Row className="mb-3">
            <Col>
                <ButtonCreateModal
                    className="mr-1"
                    title="Agregar Paciente"
                    onSuccess={onSuccess}
                />
                <KinesiologistsFilter onChange={onChange} defaultValues={filters.kinesiologist_ids} />
            </Col>
        </Row>
    );
}

Actions.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        kinesiologist_ids: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
};

export default Actions;
