import baseApi from './base';
import { TREATMENT_PATH, TREATMENTS_PATH } from '../constants/api';

const getDetails = async (id) => {
    const url = TREATMENT_PATH.replace(':id', id);
    return baseApi.get(url);
};

const create = async attributes => baseApi.post(TREATMENTS_PATH, { treatment: attributes });

const update = async (id, attributes) => {
    const url = TREATMENT_PATH.replace(':id', id);
    return baseApi.put(url, { treatment: attributes });
};

const deletee = async (id) => {
    const url = TREATMENT_PATH.replace(':id', id);
    return baseApi.delete(url);
};

export default {
    create,
    getDetails,
    update,
    delete: deletee,
};
