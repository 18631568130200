import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import LogInPage from '../LogInPage';
import LoggedInRoutes from './LoggedInRoutes';
import { LANDING, LOG_IN } from '../../constants/routes';

const Routes = () => (
    <Router>
        <PrivateRoute path={LANDING} component={LoggedInRoutes} />
        <PublicRoute path={LOG_IN} component={LogInPage} />
    </Router>
);

export default Routes;
