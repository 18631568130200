export const ROLES = {
    KINESIOLOGIST: 'Kinesiologist',
    ADMINISTRATOR: 'Administrator',
    RECEPCIONIST: 'Recepcionist',
};

const ROLES_TRANSLATION = {
    [ROLES.KINESIOLOGIST]: 'Kinesiólogo',
    [ROLES.ADMINISTRATOR]: 'Administrador',
    [ROLES.RECEPCIONIST]: 'Recepcionista',
};
export const translateRole = role => ROLES_TRANSLATION[role] || role;
export const translateRoles = roles => roles && roles.map(translateRole).join(', ');
