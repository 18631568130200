import { ACCOUNTING_MOVEMENTS_STATEMENT, ACCOUNTING_MOVEMENTS_STATEMENT_LOADING } from '../../actions/index';
import { INITIAL_STATE, loading, finish } from '../common';

const newAmounts = () => {
    const amounts = {};
    for (let i = 1; i <= 12; i += 1) {
        amounts[i] = 0;
    }
    return amounts;
};

const sumAmounts = (amounts1, amounts2) => {
    const amounts = {};
    for (let i = 1; i <= 12; i += 1) {
        amounts[i] = amounts1[i] + amounts2[i];
    }
    return amounts;
};

const addSumAndAvg = (amounts) => {
    let sum = 0;
    for (let i = 1; i <= 12; i += 1) {
        sum += amounts[i];
    }
    const avg = Math.round(sum * 1.0 / 12);
    return {
        ...amounts,
        sum,
        avg,
    };
};

const calculateTotalsForCharts = charts => charts.map(chart => ({
    ...chart,
    amounts: addSumAndAvg(chart.amounts),
}));

const calculateTotalsForSubcategories = subcategories => subcategories.map((subcategory) => {
    const charts = calculateTotalsForCharts(subcategory.charts);
    const amounts = charts.reduce(
        (run, chart) => sumAmounts(run, chart.amounts),
        newAmounts(),
    );
    return {
        ...subcategory,
        charts,
        amounts: addSumAndAvg(amounts),
    };
});

const calculateTotalsForCategories = (categories) => {
    if (!categories) {
        return [];
    }

    let runningAmounts = newAmounts();
    return categories.map((category) => {
        const subcategories = calculateTotalsForSubcategories(category.subcategories);
        const amounts = subcategories.reduce(
            (run, subcategory) => sumAmounts(run, subcategory.amounts),
            newAmounts(),
        );
        runningAmounts = sumAmounts(runningAmounts, amounts);
        return {
            ...category,
            subcategories,
            amounts: addSumAndAvg(amounts),
            runningAmounts: addSumAndAvg(runningAmounts),
        };
    });
};

const calculateTotalsForPayload = (payload = {}) => ({
    ...payload,
    categories: calculateTotalsForCategories(payload.categories),
});

const reducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case ACCOUNTING_MOVEMENTS_STATEMENT_LOADING:
        return loading(state);
    case ACCOUNTING_MOVEMENTS_STATEMENT:
        return finish({
            ...action,
            payload: calculateTotalsForPayload(action.payload),
        });
    default:
        return state;
    }
};

export default reducers;
