import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../actions/sessions';
import CalendarPage from './CalendarPage';
import getNestedValue from '../../utils/getNestedValue';

const getCachedFilterUsed = state => getNestedValue(state, 'cache.sessions/index') ?? {};
const getCurrentFilterUsed = state => getNestedValue(state, 'sessions.index.filters') ?? {};

const mapStateToProps = state => ({
    filters: {
        ...getCachedFilterUsed(state),
        ...getCurrentFilterUsed(state),
    },
});

const mapDispatchToProps = dispatch => bindActionCreators(
    { getSessions: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
