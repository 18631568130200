import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';

const recordsToOptions = ({ filterOfficeType }) => records =>
    records.filter(record => !filterOfficeType || record.officeType === filterOfficeType)
        .map(record => (
            {
                value: `${record.id}`,
                label: record.name,
            }
        ));

const SelectInputOffice = props => (
    <SelectInputRemote
        placeholder="Seleccionar oficina"
        {...props}
        recordsToOptions={recordsToOptions(props)}
    />
);

export default SelectInputOffice;
