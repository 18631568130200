import { CACHE_SET, CACHE_RELOAD } from './index';

const reload = accessToken => ({
    type: CACHE_RELOAD,
    payload: {
        accessToken,
    },
});

const set = payload => ({
    type: CACHE_SET,
    payload,
});

export default {
    set,
    reload,
};
