import React from 'react';
import FilterSelectRemote from './FilterSelectRemote';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: record.title,
    }
));

const FilterAccountingPaymentType = props => (
    <FilterSelectRemote
        {...props}
        recordsToOptions={recordsToOptions}
        emptyOption={{ value: '', label: 'Modo de pago: Todos' }}
        identifier="accountingPaymentType"
    />
);

export default FilterAccountingPaymentType;
