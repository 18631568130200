import React from 'react';
import SelectInput from '../../RecordDetails/SelectInput';

const OPTIONS = [
    { value: 'Colmena Web', label: 'Colmena Web' },
    { value: 'Colmena Oficina', label: 'Colmena Oficina' },
    { value: 'Redes Sociales', label: 'Redes Sociales' },
    { value: 'Kineself Web', label: 'Kineself Web' },
    { value: 'Familiar/Amigo', label: 'Familiar/Amigo' },
    { value: 'Calle', label: 'Calle' },
    { value: 'Médico tratante', label: 'Médico tratante' },
];

const InputReference = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputReference;
