import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import StatementFilter from './StatementFilter';
import StatementTable from './StatementTable.redux';
import StatementDetailsModal from '../StatementDetailsModal';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { label: 'Informe de gestión' },
];

class StatementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsModal: undefined,
        };
        this.updateFilters = this.updateFilters.bind(this);
        this.showStatementDetailsModal = this.showStatementDetailsModal.bind(this);
        this.hideStatementDetailsModal = this.hideStatementDetailsModal.bind(this);
    }

    showStatementDetailsModal(month, accountingChart) {
        this.setState({
            detailsModal: { month, accountingChart },
        });
    }

    hideStatementDetailsModal() {
        this.setState({
            detailsModal: undefined,
        });
    }

    updateFilters(newFilters) {
        const { getStatement, filters } = this.props;
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getStatement(updatedFilters);
    }

    render() {
        const { filters } = this.props;
        const { detailsModal } = this.state;
        return (
            <div>
                <Breadcrumb paths={BREADCRUMB} />
                <StatementFilter
                    filters={filters}
                    onChange={this.updateFilters}
                />
                <StatementTable
                    filters={filters}
                    onFetchData={this.updateFilters}
                    onMonthClick={this.showStatementDetailsModal}
                />
                <StatementDetailsModal
                    show={!!detailsModal}
                    accountingChart={detailsModal && detailsModal.accountingChart}
                    month={detailsModal && detailsModal.month}
                    year={filters.year}
                    office={filters.office}
                    onHide={this.hideStatementDetailsModal}
                />
            </div>
        );
    }
}

StatementPage.propTypes = {
    getStatement: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        year: PropTypes.string,
        office: PropTypes.string,
    }).isRequired,
};

export default StatementPage;
