import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterSearch from '../../Table/FilterSearch';
import FilterOffice from '../../Table/FilterOffice.redux';
import FilterBankAccount from '../../Table/FilterBankAccount.redux';
import FilterAccountingPaymentType from '../../Table/FilterAccountingPaymentType.redux';
import FilterAccountingCharts from '../../Table/FilterAccountingCharts.redux';
import FilterSign from './FilterSign';
import FilterDateRange from '../../Table/FilterDateRange';

const AccountingMovementsFilter = ({ onChange, filters }) => (
    <Row className="mb-2">
        <Col xs md="3">
            <FilterSearch
                onChange={onChange}
                placeholder="Buscar monto"
            />
        </Col>
        <Col xs md="3">
            <FilterSign
                defaultValue={filters.sign}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterOffice
                defaultValue={filters.office}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterBankAccount
                defaultValue={filters.bankAccount}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterDateRange
                identifierFrom="fromFlowDate"
                defaultValueFrom={filters.fromFlowDate}
                identifierTo="toFlowDate"
                defaultValueTo={filters.toFlowDate}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterAccountingPaymentType
                defaultValue={filters.accountingPaymentType}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterAccountingCharts
                defaultValue={filters.accountingChart}
                onChange={onChange}
            />
        </Col>
    </Row>
);

AccountingMovementsFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        sign: PropTypes.string,
    }).isRequired,
};

export default AccountingMovementsFilter;
