import { getFullName } from '../../utils/user';
import getNestedValue from '../../utils/getNestedValue';

const FullNameAccessor = accessor => (element) => {
    const person = getNestedValue(element, accessor);
    if (!person) {
        return '';
    }
    return getFullName(person);
};

export default FullNameAccessor;
