import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { BANK_ACCOUNT } from '../../../constants/routes';
import CellCurrency from '../../Table/CellCurrency';

const COLUMNS = [
    { Header: 'Banco', accessor: 'bankName' },
    { Header: 'Número de cuenta', accessor: 'accountNumber' },
    {
        Header: 'Saldo', accessor: 'currentBalance', sortable: false, Cell: CellCurrency,
    },
    { Header: 'Ejecutivo', accessor: 'executive' },
    { Header: 'Monto línea de crédito', accessor: 'creditLineAmount', Cell: CellCurrency },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(BANK_ACCOUNT, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
