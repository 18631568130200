import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRoute from '../ConditionalRoute';
import { LOG_IN } from '../../../constants/routes';

const PrivateRoute = ({ isAuthenticated, ...rest }) => (
    <ConditionalRoute
        checker={() => isAuthenticated}
        redirectPath={LOG_IN}
        {...rest}
    />
);

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default PrivateRoute;
