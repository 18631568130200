import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'name',
    'code',
];

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => ATTRIBUTES.reduce((obj, key) => ({
        ...obj,
        [key]: record[key] || '',
    }), {}),
    validationSchema: () => Yup.object().shape({
        name: Yup.string()
            .required('Campo obligatorio'),
        code: Yup.string()
            .required('Campo obligatorio'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            await onSubmit(values);
        } catch (error) {
            logger.error('Error updating procedure:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
