import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import RecordForm from '../RecordForm';
import dateUtil from '../../../utils/date';
import { ACCOUNTING_MOVEMENT } from '../../../constants/routes';

const today = () => dateUtil.formatDateTime(new Date(), '%Y-%m-%d');

const CreateModal = ({
    show, onHide, onSuccess, createRecord, lastUsedFields,
}) => {
    const [initialRecord, setInitialRecord] = React.useState({
        ...lastUsedFields,
        docDate: today(),
        flowDate: today(),
    });
    const [creationIndex, setCreationIndex] = React.useState(0);
    const [followUp, setFollowUp] = React.useState(false);
    const [lastRecordCreated, setLastRecordCreated] = React.useState(undefined);

    const onSubmit = async (attributes) => {
        const record = await createRecord(attributes);
        if (followUp) {
            setLastRecordCreated(record);
            setInitialRecord({
                office: record.office,
                bankAccount: record.bankAccount,
                accountingPaymentType: record.accountingPaymentType,
                docDate: record.docDate,
                flowDate: record.flowDate,
            });
            setCreationIndex(creationIndex + 1);
        }
        onSuccess(record, followUp);
    };

    const onFollowUpChange = (event) => {
        setFollowUp(event.target.checked);
    };

    const renderMessage = () => {
        if (!lastRecordCreated) {
            return '';
        }
        const url = ACCOUNTING_MOVEMENT.replace(':id', lastRecordCreated.id);
        return (
            <Alert variant="success" className="mb-0">
                Movimiento
                {' '}
                <Alert.Link href={url}>
                    {lastRecordCreated.id}
                </Alert.Link>
                {' '}
                agregado con éxito
            </Alert>
        );
    };

    const internalOnHide = () => {
        setLastRecordCreated(undefined);
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={internalOnHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar movimiento
                </Modal.Title>
                {renderMessage()}
            </Modal.Header>
            <RecordForm
                key={`AccountingMovementCreateModal${creationIndex}`}
                record={initialRecord}
                onCancel={internalOnHide}
                onSubmit={onSubmit}
                onFollowUpChange={onFollowUpChange}
                followUp={followUp}
            />
        </Modal>
    );
};

CreateModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    createRecord: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
    lastUsedFields: PropTypes.shape({}),
};

CreateModal.defaultProps = {
    onSuccess: () => { },
    show: false,
    lastUsedFields: {},
};

export default CreateModal;
