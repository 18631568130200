import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import {
    Loading, Attribute, LinkAddress, LinkPhone, LinkEmail, MultiLineText,
} from '../../RecordDetails';
import Actions from './Actions';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import { getFullName, FORMAT_INVERTED } from '../../../utils/user';
import dateUtil from '../../../utils/date';
import { formatRutAddingDigit } from '../../../utils/rut';
import { ROLES, translateRoles } from '../../../utils/roles';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.USERS, label: 'Usuarios' },
];

const ageDetails = (dob) => {
    if (!dob) {
        return undefined;
    }
    const date = dateUtil.formatDate(dob);
    const age = dateUtil.calculateAge(dob);
    return `${age} años (${date})`;
};
const systemEvent = (user = {}, datetime) => (
    <span>
        {getFullName(user)}
        <br />
        {dateUtil.formatDateTime(datetime)}
    </span>
);
const workingHoursDetails = (checkIn, checkOut) => {
    if (!checkIn && !checkOut) {
        return undefined;
    }
    const checkInTime = checkIn ? dateUtil.formatTime(checkIn.start) : '';
    const checkOutTime = checkOut ? dateUtil.formatTime(checkOut.start) : '';
    return `${checkInTime} - ${checkOutTime}`;
};
const officesDetails = (offices) => {
    if (!offices) {
        return undefined;
    }
    return offices.map(office => office.name).join(', ');
};
const retentionDetails = (record, key) => {
    const assignedValue = record[key];
    if (assignedValue || assignedValue === 0) {
        return `${assignedValue}%`;
    }

    const values = record.offices && record.offices
        .filter(office => office[key] || office[key] === 0)
        .map(office => `${office[key]}% (${office.name})`)
        .join(', ');
    return values;
};
const lastLoginDetails = (lastSignInAt, lastSignInIp) => {
    if (!lastSignInAt && !lastSignInIp) {
        return 'Nunca';
    }
    const details = [];
    if (lastSignInAt) {
        details.push(dateUtil.formatDateTime(lastSignInAt));
    }
    if (lastSignInIp) {
        details.push(`(${lastSignInIp})`);
    }
    return details.join(' ');
};

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        {this.renderPersonalData()}
                        {this.renderEducationData()}
                        {this.renderBankData()}
                        {this.renderSystemData()}
                    </Col>
                    <Col lg="6">
                        {this.renderContactData()}
                        {this.renderWorkData()}
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderPersonalData() {
        const { record } = this.props;
        return (
            <div>
                <legend>{getFullName(record, FORMAT_INVERTED)}</legend>
                <Attribute optional name="Rut" value={formatRutAddingDigit(record.rut)} />
                <Attribute optional name="Género" value={record.genre} />
                <Attribute optional name="Edad" value={ageDetails(record.birthday)} />
                <Attribute optional name="Previsión" value={record.healthScheme} />
            </div>
        );
    }

    renderEducationData() {
        const { record } = this.props;
        const containsEducationInfo = record.university
            || record.universityEndDate || record.postGradTitles;

        if (containsEducationInfo) {
            return (
                <div>
                    <legend>Estudios</legend>
                    <Attribute optional name="Universidad" value={record.university} />
                    <Attribute optional name="Fecha de egreso" value={dateUtil.formatDate(record.universityEndDate)} />
                    <Attribute optional name="Post Títulos" value={MultiLineText({ text: record.postGradTitles })} />
                </div>
            );
        }

        return null;
    }

    renderBankData() {
        const { record } = this.props;
        const containsBankInfo = record.bank || record.bankCountNumber;

        if (containsBankInfo) {
            const bankType = record.bankCountType || 'Cuenta';
            return (
                <div>
                    <legend>Banco</legend>
                    <Attribute optional name="Banco" value={record.bank} />
                    <Attribute optional name={bankType} value={record.bankCountNumber} />
                </div>
            );
        }

        return null;
    }

    renderContactData() {
        const { record } = this.props;
        const {
            email, cellPhone, homePhone, officePhone, address, borough, city,
        } = record;
        const containsContactInfo = email || cellPhone || homePhone || officePhone
            || address || borough || city;

        if (containsContactInfo) {
            return (
                <div>
                    <legend>Contacto</legend>
                    <Attribute optional name="Mail" value={LinkEmail(record)} />
                    <Attribute optional name="Teléfono Celular" value={LinkPhone({ number: cellPhone })} />
                    <Attribute optional name="Teléfono Casa" value={LinkPhone({ number: homePhone })} />
                    <Attribute optional name="Teléfono Oficina" value={LinkPhone({ number: officePhone })} />
                    <Attribute optional name="Dirección" value={LinkAddress(record)} />
                </div>
            );
        }

        return null;
    }

    renderWorkData() {
        const {
            record: {
                workStartDate,
                workStopDate,
                checkIn,
                checkOut,
                roles,
                offices,
                lifeSheet,
            },
        } = this.props;

        return (
            <div>
                <legend>Trabajo</legend>
                <Attribute optional name="Rol" value={translateRoles(roles)} />
                <Attribute optional name="Llegada a la empresa" value={dateUtil.formatDate(workStartDate)} />
                <Attribute optional name="Salida de la empresa" value={dateUtil.formatDate(workStopDate)} />
                <Attribute optional name="Horario" value={workingHoursDetails(checkIn, checkOut)} />
                <Attribute optional name="Oficinas" value={officesDetails(offices)} />
                {this.renderSalesData()}
                <Attribute optional name="Notas" value={MultiLineText({ text: lifeSheet })} />
            </div>
        );
    }

    renderSalesData() {
        const { record } = this.props;
        const { roles } = record;

        const displaySales = roles && roles.includes(ROLES.KINESIOLOGIST);

        if (!displaySales) {
            return null;
        }

        return (
            <div>
                <Attribute optional name="Asignación Ventas" value={retentionDetails(record, 'saleAllocationPercentage')} />
                <Attribute optional name="Retención Ventas" value={retentionDetails(record, 'saleRetentionPercentage')} />
            </div>
        );
    }

    renderSystemData() {
        const { record } = this.props;

        const {
            active, createdBy, createdAt, updatedBy, updatedAt, lastSignInAt, lastSignInIp,
        } = record;

        let loginActive;
        if (active === true) {
            loginActive = <Badge variant="success">Activo</Badge>;
        } else if (active === false) {
            loginActive = <Badge variant="danger">Inactivo</Badge>;
        }

        const showSystemData = loginActive || lastSignInAt || lastSignInIp
            || createdBy || createdAt || updatedBy || updatedAt;
        if (!showSystemData) {
            return null;
        }

        return (
            <div>
                <legend>Sistema</legend>
                <Attribute optional name="Agregado" value={systemEvent(createdBy, createdAt)} />
                <Attribute optional name="Actualizado" value={systemEvent(updatedBy, updatedAt)} />
                <Attribute optional name="Último ingreso" value={lastLoginDetails(lastSignInAt, lastSignInIp)} />
                <Attribute optional name="Inicio de sesión" value={loginActive} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: getFullName(record) },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
