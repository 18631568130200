import React from 'react';
import SelectInput from './SelectInput';

const OPTIONS = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Femenino', label: 'Femenino' },
];

const InputGender = props => (
    <SelectInput options={OPTIONS} {...props} />
);

export default InputGender;
