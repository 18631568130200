import {
    RESOURCE, METHOD, SUB_METHOD, buildActionType,
} from '../actions';
import { INITIAL_STATE, loading, finish } from './common';

const indexLoadingAction = buildActionType(RESOURCE.LOGS, METHOD.INDEX, SUB_METHOD.LOADING);
const indexFinishAction = buildActionType(RESOURCE.LOGS, METHOD.INDEX);
const indexClearAction = buildActionType(RESOURCE.LOGS, METHOD.INDEX, SUB_METHOD.CLEAR);

const reducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case indexLoadingAction:
        return loading(state);
    case indexFinishAction:
        return finish(action);
    case indexClearAction:
        return INITIAL_STATE;
    default:
        return state;
    }
};

export default reducers;
