import React from 'react';
import FilterSelect from '../../Table/FilterSelect';

const OPTIONS = [
    { value: '', label: 'Cargos y abonos' },
    { value: '-1', label: 'Cargos' },
    { value: '1', label: 'Abonos' },
];

const FilterSign = props => (
    <FilterSelect
        {...props}
        options={OPTIONS}
        identifier="sign"
    />
);

export default FilterSign;
