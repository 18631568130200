
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/logs';
import LogsModal from './LogsModal';

const mapStateToProps = (state, ownProps) => {
    const { filters: stateFilters } = state.logs;
    const { filters: propsFilter } = ownProps;
    return {
        filters: {
            ...propsFilter,
            ...stateFilters,
        },
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getLogs: actions.getIndex,
        clearLogs: actions.clearLogs,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LogsModal);
