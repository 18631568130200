export const RESOURCE = {
    ACCOUNTING_CHARTS: 'accountingCharts',
    ACCOUNTING_MOVEMENTS: 'accountingMovements',
    ACCOUNTING_PAYMENT_TYPES: 'accountingPaymentTypes',
    BANK_ACCOUNTS: 'bankAccounts',
    BANK_CHECKS: 'bankChecks',
    DOCTORS: 'doctors',
    LOGS: 'logs',
    OFFICES: 'offices',
    PATHOLOGIES: 'pathologies',
    PATIENTS: 'patients',
    PROCEDURES: 'procedures',
    PROVIDERS: 'providers',
    SCHEDULES: 'schedules',
    SESSIONS: 'sessions',
    TREATMENTS: 'treatments',
    USERS: 'users',
};

export const METHOD = {
    INDEX: 'index',
    DETAILS: 'details',
    OPTIONS: 'options',
    SEARCH: 'search',
    DELETE: 'delete',
};

export const SUB_METHOD = {
    LOADING: 'loading',
    CLEAR: 'clear',
};

export const buildActionType = (resource, type, status) => {
    const parts = [resource, type];
    if (status) {
        parts.push(status);
    }
    return parts.join('/');
};

/* Auth */
export const LOG_IN = 'auth/login';
export const LOG_OUT = 'auth/logout';

/* Users */
const OPTIONS_ADMIN = 'optionsAdmin';
export const USERS_OPTIONS_ADMIN = buildActionType(
    RESOURCE.USERS,
    OPTIONS_ADMIN,
);
export const USERS_OPTIONS_ADMIN_LOADING = buildActionType(
    RESOURCE.USERS,
    OPTIONS_ADMIN,
    SUB_METHOD.LOADING,
);
const OPTIONS_KINESIOLOGIST = 'optionsKinesiologist';
export const USERS_OPTIONS_KINESIOLOGIST = buildActionType(
    RESOURCE.USERS,
    OPTIONS_KINESIOLOGIST,
);
export const USERS_OPTIONS_KINESIOLOGIST_LOADING = buildActionType(
    RESOURCE.USERS,
    OPTIONS_KINESIOLOGIST,
    SUB_METHOD.LOADING,
);

/* Treatments */
const TREATMENTS = 'treatments';
export const PATIENT_TREATMENTS = buildActionType(
    RESOURCE.PATIENTS,
    TREATMENTS,
);
export const PATIENT_TREATMENTS_LOADING = buildActionType(
    RESOURCE.PATIENTS,
    TREATMENTS,
    SUB_METHOD.LOADING,
);
export const DOCTOR_TREATMENTS = buildActionType(
    RESOURCE.DOCTORS,
    TREATMENTS,
);
export const DOCTOR_TREATMENTS_LOADING = buildActionType(
    RESOURCE.DOCTORS,
    TREATMENTS,
    SUB_METHOD.LOADING,
);

/* Accounting Movements */
const STATEMENT_METHOD = 'statement';
const STATEMENT_DETAIL_METHOD = 'statementDetails';
export const ACCOUNTING_MOVEMENTS_STATEMENT = buildActionType(
    RESOURCE.ACCOUNTING_MOVEMENTS,
    STATEMENT_METHOD,
);
export const ACCOUNTING_MOVEMENTS_STATEMENT_LOADING = buildActionType(
    RESOURCE.ACCOUNTING_MOVEMENTS,
    STATEMENT_METHOD,
    SUB_METHOD.LOADING,
);
export const ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS = buildActionType(
    RESOURCE.ACCOUNTING_MOVEMENTS,
    STATEMENT_DETAIL_METHOD,
);
export const ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_LOADING = buildActionType(
    RESOURCE.ACCOUNTING_MOVEMENTS,
    STATEMENT_DETAIL_METHOD,
    SUB_METHOD.LOADING,
);
export const ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_CLEAR = buildActionType(
    RESOURCE.ACCOUNTING_MOVEMENTS,
    STATEMENT_DETAIL_METHOD,
    SUB_METHOD.CLEAR,
);

/* Cache */
export const CACHE_SET = 'cache/set';
export const CACHE_RELOAD = 'cache/reload';
