import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/patients';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.patients.index;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getPatients: actions.getIndex,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
