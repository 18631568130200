import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { PROVIDER } from '../../../constants/routes';
import CellActive from '../../Table/CellActive';
import CellRut from '../../Table/CellRut';

const COLUMNS = [
    {
        Header: 'Nombre o razón social', accessor: 'name', Cell: CellActive, minWidth: 220,
    },
    { Header: 'Rut', accessor: 'rut', Cell: CellRut },
    { Header: 'Contacto', accessor: 'contact', minWidth: 60 },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(PROVIDER, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
