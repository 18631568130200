import { combineReducers } from 'redux';
import { RESOURCE } from '../../actions';
import { indexReducer, detailsReducer, searchReducer } from '../common';
import optionsAdmin from './optionsAdmin';
import optionsKinesiologist from './optionsKinesiologist';

export default combineReducers({
    index: indexReducer(RESOURCE.USERS),
    details: detailsReducer(RESOURCE.USERS),
    search: searchReducer(RESOURCE.USERS),
    optionsAdmin,
    optionsKinesiologist,
});
