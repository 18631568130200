import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/providers';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.providers.index;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { getProviders: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
