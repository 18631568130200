import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from '../../Table';
import CellCheck from '../IndexPage/CellCheck';
import CellCurrency from '../../Table/CellCurrency';
import CellDate from '../../Table/CellDate';

const COLUMNS = [
    {
        Header: 'Fecha documento', accessor: 'docDate', Cell: CellDate, minWidth: 80, sortable: false,
    },
    {
        Header: 'Fecha flujo', accessor: 'flowDate', Cell: CellDate, minWidth: 80, sortable: false,
    },
    { Header: 'Oficina', accessor: 'office.name', sortable: false },
    {
        Header: 'Modo de pago', accessor: 'accountingPaymentType.title', Cell: CellCheck, sortable: false,
    },
    {
        Header: 'Monto', accessor: 'amount', Cell: CellCurrency, sortable: false, minWidth: 80,
    },
];

const StatementDetailsTable = props => (
    <Row>
        <Col>
            <Table
                defaultSorted={[
                    {
                        id: 'docDate',
                        desc: false,
                    },
                ]}
                columns={COLUMNS}
                {...props}
            />
        </Col>
    </Row>
);

export default StatementDetailsTable;
