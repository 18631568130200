import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FaFileExcel } from 'react-icons/fa';
import excel from '../../utils/excel';

const ButtonExcel = ({
    table, fileName, sheetName, ...rest
}) => {
    const download = () => {
        excel.tableToExcel(table, fileName, sheetName || fileName);
    };
    return (
        <Button onClick={download} variant="outline-dark" {...rest}>
            <FaFileExcel color="green" />
            {' '}
            Descargar
        </Button>
    );
};

ButtonExcel.propTypes = {
    table: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    sheetName: PropTypes.string,
};

ButtonExcel.defaultProps = {
    sheetName: undefined,
};

export default ButtonExcel;
