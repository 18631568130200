import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterSearch from '../../Table/FilterSearch';
import FilterBankAccount from '../../Table/FilterBankAccount.redux';
import FilterDateRange from '../../Table/FilterDateRange';
import FilterState from './FilterState';

const Filter = ({ onChange, filters }) => (
    <Row className="mb-2">
        <Col xs md="3">
            <FilterSearch
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterBankAccount
                defaultValue={filters.bankAccount}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterDateRange
                identifierFrom="fromFlowDate"
                defaultValueFrom={filters.fromFlowDate}
                identifierTo="toFlowDate"
                defaultValueTo={filters.toFlowDate}
                onChange={onChange}
            />
        </Col>
        <Col xs md="3">
            <FilterState
                defaultValue={filters.state}
                onChange={onChange}
            />
        </Col>
    </Row>
);

Filter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        sign: PropTypes.string,
    }).isRequired,
};

export default Filter;
