import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import storeSubscriberAuth from './storeSubscriberAuth';

const SUBSCRIBERS = [
    storeSubscriberAuth,
];

class StoreManager {
    constructor() {
        this.store = createStore(
            reducers,
            applyMiddleware(thunk),
        );
        window.reduxStore = this.store;

        this.addSubscribers(SUBSCRIBERS);
    }

    addSubscribers(subscribers) {
        subscribers.forEach((subscriber) => {
            this.addSubscriber(subscriber);
        });
    }

    addSubscriber(subscriber) {
        if (typeof subscriber === 'function') {
            const callback = subscriber.onStateChange || subscriber;
            this.subscribeCallbackToStore(callback);
        }
    }

    subscribeCallbackToStore(onStateChange) {
        const unsubscribe = this.store.subscribe(() => {
            onStateChange(this.store.getState(), this.store.dispatch, unsubscribe);
        });
    }

    getStore() {
        return this.store;
    }
}

export default new StoreManager();
