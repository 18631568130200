import {
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS,
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_LOADING,
    ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_CLEAR,
} from '../../actions/index';
import { INITIAL_STATE, loading, finish } from '../common';

const reducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_LOADING:
        return loading(state, action);
    case ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS:
        return finish(action);
    case ACCOUNTING_MOVEMENTS_STATEMENT_DETAILS_CLEAR:
        return INITIAL_STATE;
    default:
        return state;
    }
};

export default reducers;
