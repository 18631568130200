import { getIndex } from './common';
import { RESOURCE } from './index';
import sessionsApi from '../api/sessions';

const indexCache = {
    key: `${RESOURCE.SESSIONS}/index`,
};

export default {
    getIndex: getIndex(RESOURCE.SESSIONS, sessionsApi, { cache: indexCache }),
};
