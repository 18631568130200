import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../actions/accountingPaymentTypes';
import FilterAccountingPaymentType from './FilterAccountingPaymentType';
import { mapIndexStateToProps } from '../../utils/apiStateToProps';
import getNestedValue from '../../utils/getNestedValue';

const mapStateToProps = state => mapIndexStateToProps(getNestedValue(state, 'accountingPaymentTypes.options'));
const mapDispatchToProps = dispatch => bindActionCreators(
    { getRecords: actions.getOptions },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FilterAccountingPaymentType);
