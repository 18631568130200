import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingCharts';
import IndexPage from './IndexPage';

const mapStateToProps = (state) => {
    const { filters } = state.accountingCharts.index;
    return {
        filters,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { getAccountingCharts: actions.getIndex },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
