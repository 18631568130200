import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: `${record.bankName} / ${record.accountNumber}`,
    }
));

const SelectInputBankAccount = props => (
    <SelectInputRemote
        {...props}
        recordsToOptions={recordsToOptions}
    />
);

export default SelectInputBankAccount;
