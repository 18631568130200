import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { GoTrashcan } from 'react-icons/go';
import createLogger from 'logging';
import ConfirmModal, { STATUS } from '../../ConfirmModal';

const logger = createLogger('ButtonDelete');

const QUESTION = '¿Estás seguro que deseas eliminar este plan de cuenta? Esta acción no se puede revertir';
const MESSAGE_LOADING = 'Cargando...';
const MESSAGE_ERROR = 'Hubo un error eliminando el plan de cuenta, por favor intente más tarde';

const ButtonDelete = ({ record, deletee, ...otherProps }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [status, setStatus] = React.useState(STATUS.IDLE);
    const [message, setMessage] = React.useState(QUESTION);
    const onSelect = async (confirmed) => {
        if (!confirmed) {
            setModalShow(false);
            setMessage(QUESTION);
            setStatus(STATUS.IDLE);
            return;
        }

        setStatus(STATUS.LOADING);
        setMessage(MESSAGE_LOADING);
        try {
            await deletee(record.id);
            setModalShow(false);
            setStatus(STATUS.IDLE);
        } catch (error) {
            logger.error('Error deleting accounting chart', error);
            setMessage(MESSAGE_ERROR);
            setStatus(STATUS.ERROR);
        }
    };

    return (
        <div className="d-inline">
            <Button onClick={() => setModalShow(true)} variant="outline-danger" {...otherProps}>
                <GoTrashcan />
                {' Eliminar'}
            </Button>
            <ConfirmModal
                show={modalShow}
                message={message}
                onSelect={onSelect}
                status={status}
            />
        </div>
    );
};

ButtonDelete.propTypes = {
    record: PropTypes.shape({}).isRequired,
    deletee: PropTypes.func.isRequired,
};

export default ButtonDelete;
