import createLogger from 'logging';
import { getCachedToken } from './auth';
import tokenParser from '../utils/tokenParser';
import { CACHE_SET, CACHE_RELOAD } from '../actions';

const logger = createLogger('Reducers Cache');

let currentUserId;
export const resetForTesting = () => {
    currentUserId = undefined;
};

const getCacheKeyForCurrentUser = () => {
    if (currentUserId) {
        return `cache.base.${currentUserId}`;
    }
    return undefined;
};

const userIdFromAccessToken = (accessToken) => {
    const parsedToken = tokenParser.parse(accessToken);
    if (parsedToken && parsedToken.user) {
        return parsedToken.user.id;
    }
    return undefined;
};

const loadFromToken = (accessToken) => {
    currentUserId = userIdFromAccessToken(accessToken);
    const key = getCacheKeyForCurrentUser(currentUserId);
    if (key) {
        const cachedValue = localStorage.getItem(key);
        if (cachedValue) {
            try {
                return JSON.parse(cachedValue);
            } catch (error) {
                logger.error('Error reading cached value', error);
            }
        }
    }

    return {};
};

const initialValue = () => loadFromToken(getCachedToken());
const reload = action => loadFromToken(action.payload.accessToken);

const set = (state, action) => {
    if (typeof action.payload !== 'object' || !action.payload) {
        return state;
    }

    try {
        const newCache = {
            ...state,
            ...action.payload,
        };
        const key = getCacheKeyForCurrentUser(currentUserId);
        const jsonCacheValue = JSON.stringify(newCache);

        if (key) {
            localStorage.setItem(key, jsonCacheValue);
        }
        return newCache;
    } catch (error) {
        logger.error('Error stringifying cache', error);
    }

    return state;
};

const reducers = (state = initialValue(), action) => {
    switch (action.type) {
    case CACHE_SET:
        return set(state, action);
    case CACHE_RELOAD:
        return reload(action);
    default:
        return state;
    }
};

export default reducers;
