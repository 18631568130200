import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Loading, Attribute } from '../../RecordDetails';
import Actions from './Actions.router';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.ACCOUNTING_PAYMENT_TYPES, label: 'Módos de pago' },
];

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        <Attribute name="Título" value={record.title} />
                    </Col>
                </Row>
                <Actions record={record} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: record.title },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
