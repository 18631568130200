import { withFormik } from 'formik';
import * as Yup from 'yup';
import createLogger from 'logging';
import '../../../utils/validations/doctorUniq';
import RecordForm from './RecordForm';

const logger = createLogger('RecordFormik');
const ATTRIBUTES = [
    'firstName',
    'lastName',
    'rut',
    'specialty',
    'hospital',
    'notes',
    'email',
    'cellPhone',
    'officePhone',
    'address',
    'borough',
    'city',
];
const keepOnlyDigits = text => text.replace(/\D+/g, '');

const RecordFormik = withFormik({
    mapPropsToValues: ({ record = {} }) => ATTRIBUTES.reduce((obj, key) => ({
        ...obj,
        [key]: record[key] || '',
    }), {}),
    validationSchema: ({ record = {} }) => Yup.object().shape({
        rut: Yup.string()
            .min(2, 'Debe tener al menos 2 caracteres')
            .required('Campo obligatorio')
            .doctorUniq('rut', record.id, 'Este rut ya existe'),
        firstName: Yup.string()
            .required('Campo obligatorio'),
        lastName: Yup.string()
            .required('Campo obligatorio'),
        email: Yup.string()
            .email('Ingrese una direccion de correo válida'),
    }),
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        const { onSubmit } = props;
        try {
            const cleanValues = {
                ...values,
                rut: keepOnlyDigits(values.rut),
            };
            await onSubmit(cleanValues);
        } catch (error) {
            logger.error('Error updating doctor:', error);
            let errorMessage = 'No se pudo conectar con el servidor, intente nuevamente';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            setStatus({
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }
    },
    displayName: 'RecordForm',
})(RecordForm);

export default RecordFormik;
