import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

const TEXTS = {
    previousText: 'Previa',
    nextText: 'Siguiente',
    loadingText: 'Cargando...',
    noDataText: 'No se encontró ningún registro',
    pageText: 'Página',
    ofText: 'de',
    rowsText: 'filas',
    pageJumpText: 'ir a página',
    rowsSelectorText: 'filas por página',
};

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.onTableChange = this.onTableChange.bind(this);
        this.getErrorProps = this.getErrorProps.bind(this);
        this.getTotalPages = this.getTotalPages.bind(this);
    }

    onTableChange(state) {
        const { page, pageSize, sorted } = state;
        const newFilters = {
            limit: pageSize,
            offset: page * pageSize,
        };

        if (sorted.length > 0) {
            const [{ id, desc }] = sorted;
            newFilters.sort = id;
            newFilters.sortDirection = desc ? 'desc' : 'asc';
        }

        const { onFetchData } = this.props;
        onFetchData(newFilters);
    }

    getErrorProps() {
        const { error } = this.props;
        if (!error) {
            return {};
        }
        return {
            data: [],
            noDataText: error.message,
        };
    }

    getTotalPages() {
        const { totalRecords, filters } = this.props;

        if (totalRecords !== undefined && filters && filters.limit) {
            return Math.ceil(totalRecords / filters.limit);
        }
        return -1;
    }

    render() {
        const { records, filters } = this.props;
        const errorProps = this.getErrorProps();
        return (
            <ReactTable
                pages={this.getTotalPages()}
                manual
                className="-striped -highlight"
                data={records}
                pageSize={filters.limit}
                {...TEXTS}
                {...this.props}
                {...errorProps}
                onFetchData={this.onTableChange}
            />
        );
    }
}

Table.propTypes = {
    onFetchData: PropTypes.func.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    filters: PropTypes.shape({
        offset: PropTypes.number,
        limit: PropTypes.number,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({})),
    totalRecords: PropTypes.number,
};

Table.defaultProps = {
    error: undefined,
    filters: {
        offset: 0,
        limit: 20,
    },
    records: undefined,
    totalRecords: undefined,

};

export default Table;
