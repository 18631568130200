import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const procedureDetails = record => `(${record.code}) ${record.name}`;

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: procedureDetails(record),
        record,
    }
));

const SearchProcedure = ({ records, getRecords, onSelect }) => {
    React.useEffect(() => {
        getRecords();
    }, [getRecords]);
    return (<ReactSelect
        placeholder="Agregar procedimiento"
        value=""
        onChange={optionSelected => onSelect(optionSelected.record)}
        options={recordsToOptions(records)}
    />);
};

SearchProcedure.propTypes = {
    records: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
    })),
    getRecords: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
};

SearchProcedure.defaultProps = {
    records: [],
    onSelect: () => { },
};

export default SearchProcedure;
