import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/procedures';
import EditModal from './EditModal';

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRecord: actions.update,
}, dispatch);

export default connect(null, mapDispatchToProps)(EditModal);
