import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from '../IndexPage/Table.redux';

class LogsModal extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilters = this.updateFilters.bind(this);
        this.intermediateOnHide = this.intermediateOnHide.bind(this);
        props.clearLogs();
    }

    updateFilters(newFilters) {
        const { getLogs, filters } = this.props;
        const updatedFilters = {
            ...filters,
            ...newFilters,
        };
        getLogs(updatedFilters);
    }

    intermediateOnHide() {
        const { onHide, clearLogs } = this.props;
        onHide();
        clearLogs();
    }

    render() {
        const { title, show, filters } = this.props;

        let modalTitle = 'Actividad';
        if (title) {
            modalTitle = `${modalTitle} / ${title}`;
        }

        return (
            <Modal
                show={show}
                onHide={this.intermediateOnHide}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Table
                                    filters={filters}
                                    onFetchData={this.updateFilters}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

LogsModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    getLogs: PropTypes.func.isRequired,
    clearLogs: PropTypes.func.isRequired,
    show: PropTypes.bool,
    title: PropTypes.string,
    filters: PropTypes.shape({}),
};

LogsModal.defaultProps = {
    show: false,
    title: '',
    filters: {
        offset: 0,
        limit: 10,
    },
};

export default LogsModal;
