import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../actions/users';
import KinesiologistsFilter from './KinesiologistsFilter';
import { mapIndexStateToProps } from '../../utils/apiStateToProps';
import getNestedValue from '../../utils/getNestedValue';

const mapStateToProps = (state) => {
    const kinesiologists = mapIndexStateToProps(getNestedValue(state, 'users.optionsKinesiologist'));
    let { records } = kinesiologists;
    const officeId = getNestedValue(state, 'cache.currentOffice.id');
    if (officeId && records) {
        records = records.filter(record => record.offices.some(office => office.id === officeId));
    }
    return {
        kinesiologists: {
            ...kinesiologists,
            records,
        },
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({
    getKinesiologists: actions.getOptionsKinesiologist,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KinesiologistsFilter);
