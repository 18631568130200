import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import { Loading } from '../../RecordDetails';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import { getFullName } from '../../../utils/user';
import dateUtil from '../../../utils/date';
import RecordForm from '../RecordForm';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
];

class EditPage extends React.Component {
    constructor(props) {
        super(props);
        this.getRecord = this.getRecord.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.getRecord();
        }
    }

    getRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    async onSubmit(attributes) {
        const { recordId, updateRecord } = this.props;
        await updateRecord(recordId, attributes);
    }

    renderBody() {
        const { loading, error, record } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <RecordForm record={record} onSubmit={this.onSubmit} />
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        const { patient, sessions } = record;

        if (!patient) {
            return BREADCRUMB;
        }

        const url = ROUTES.PATIENT.replace(':id', patient.id);
        let date = '';
        if (sessions.length > 0) {
            date = dateUtil.formatDate(sessions[0].startTime);
        }

        return [
            ...BREADCRUMB,
            { path: url, label: getFullName(patient) },
            { label: `Tratamiento ${date}` },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

EditPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    updateRecord: PropTypes.func.isRequired,
    record: PropTypes.shape({
        patient: PropTypes.shape({
            id: PropTypes.number,
        }),
        sessions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            startTime: PropTypes.string,
        })),
    }),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

EditPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default EditPage;
