import React from 'react';
import FilterSelect from '../../Table/FilterSelect';

const OPTIONS = [
    { value: '', label: 'Rol: Todos' },
    { value: 'Kinesiologist', label: 'Kinesiólogos' },
    { value: 'Recepcionist', label: 'Recepcionistas' },
    { value: 'Administrator', label: 'Administradores' },
];

const FilterRole = props => (
    <FilterSelect
        {...props}
        options={OPTIONS}
        identifier="role"
    />
);

export default FilterRole;
