import { getIndex } from './common';
import {
    RESOURCE, METHOD, SUB_METHOD, buildActionType,
} from './index';
import logsApi from '../api/logs';

const indexClearAction = buildActionType(RESOURCE.LOGS, METHOD.INDEX, SUB_METHOD.CLEAR);
const clearLogs = () => ({
    type: indexClearAction,
});

export default {
    getIndex: getIndex(RESOURCE.LOGS, logsApi),
    clearLogs,
};
