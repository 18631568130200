import React from 'react';
import SelectInputRemote from '../../RecordDetails/SelectInputRemote';
import { getFullName } from '../../../utils/user';

const recordsToOptions = records => records.map(record => (
    {
        value: `${record.id}`,
        label: getFullName(record),
    }
));

const SelectAdministrator = props => (
    <SelectInputRemote
        {...props}
        recordsToOptions={recordsToOptions}
    />
);

export default SelectAdministrator;
