import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logIn } from '../../../actions/auth';
import LogInForm from './LogInForm.formik';

const mapStateToProps = (state) => {
    if (state.auth && state.auth.warning) {
        return {
            warning: state.auth.warning,
        };
    }
    return {};
};
const mapDispatchToProps = dispatch => bindActionCreators({ logIn }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
