import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCreateModal from '../CreateModal/ButtonCreateModal';
import Can from '../../Can';
import { navigateToDetail } from '../../Table';
import { PROCEDURE } from '../../../constants/routes';

const Actions = ({ history }) => {
    const onSuccess = (record) => {
        navigateToDetail(PROCEDURE, record, history);
    };

    return (
        <Row className="mb-3">
            <Col>
                <Can do="create" on="Procedure">
                    <ButtonCreateModal
                        onSuccess={onSuccess}
                    />
                </Can>
            </Col>
        </Row>
    );
};

Actions.propTypes = {
    history: PropTypes.shape({}),
};

Actions.defaultProps = {
    history: {},
};

export default Actions;
