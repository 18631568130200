import currency from '../../utils/currency';

const CellCurrencyAbs = ({ value }) => {
    if (value === undefined || value === '') {
        return '';
    }
    return currency(Math.abs(value));
};

export default CellCurrencyAbs;
