import { combineReducers } from 'redux';
import { RESOURCE } from '../../actions';
import {
    indexReducer, detailsReducer, optionsReducer, searchReducer,
} from '../common';
import treatments from './treatments';

export default combineReducers({
    index: indexReducer(RESOURCE.PATIENTS),
    details: detailsReducer(RESOURCE.PATIENTS),
    options: optionsReducer(RESOURCE.PATIENTS),
    search: searchReducer(RESOURCE.PATIENTS),
    treatments,
});
