import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Attribute = ({
    name, value, labelSize, optional,
}) => {
    if (optional && !value) {
        return null;
    }

    return (
        <Row>
            <Col sm={labelSize} className="text-muted text-nowrap">
                <small className="float-md-right">{name}</small>
            </Col>
            <Col sm={12 - labelSize}>
                {value}
            </Col>
        </Row>
    );
};

Attribute.propTypes = {
    name: PropTypes.string,
    value: PropTypes.node,
    optional: PropTypes.bool,
    labelSize: PropTypes.number,
};

Attribute.defaultProps = {
    name: '',
    value: undefined,
    optional: false,
    labelSize: 3,
};

export default Attribute;
