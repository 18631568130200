import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonLogsModal from '../../Logs/ButtonLogsModal';
import ButtonExcel from '../../Table/ButtonExcel';
import ButtonCreateModal from '../CreateModal/ButtonCreateModal';
import { navigateToDetail } from '../../Table';
import { ACCOUNTING_MOVEMENT } from '../../../constants/routes';

const Actions = ({ history, onReload }) => {
    const onSuccess = (record, followUp) => {
        if (followUp) {
            onReload();
        } else {
            navigateToDetail(ACCOUNTING_MOVEMENT, record, history);
        }
    };

    return (
        <Row className="mb-3">
            <Col>
                <ButtonLogsModal
                    className="mr-1"
                    title="Flujo de caja"
                    filters={{ relatedObjectType: 'AccountingMovement', limit: 10 }}
                />
                <ButtonCreateModal
                    className="mr-1"
                    onSuccess={onSuccess}
                />
                <ButtonExcel
                    className="mr-1"
                    table="AccountingMovementsTable"
                    fileName="Flujo de caja"
                />
            </Col>
        </Row>
    );
};

Actions.propTypes = {
    history: PropTypes.shape({}),
    onReload: PropTypes.func,
};

Actions.defaultProps = {
    history: {},
    onReload: () => { },
};

export default Actions;
