import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BKForm from '../../BKForm';
import BKInputLabeled from '../../BKForm/BKInputLabeled';

class ChangePasswordForm extends React.Component {
    renderAlert() {
        const { status } = this.props;
        const { error, success } = status;
        if (success) {
            return <Alert variant="success">Contraseña actualizada con éxito</Alert>;
        }

        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }
        return '';
    }

    render() {
        const { isSubmitting } = this.props;
        return (
            <BKForm>
                {this.renderAlert()}
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <BKInputLabeled
                            label="Contraseña actual"
                            name="currentPassword"
                            type="password"
                            autoComplete="current-password"
                        />
                        <BKInputLabeled
                            label="Contraseña nueva"
                            name="password"
                            type="password"
                        />
                        <BKInputLabeled
                            label="Confirmar contraseña"
                            name="passwordConfirmation"
                            type="password"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Guardando...' : 'Guardar nueva contraseña'}
                    </Button>
                </Row>
            </BKForm>
        );
    }
}

ChangePasswordForm.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    status: PropTypes.shape({
        error: PropTypes.string,
        success: PropTypes.bool,
    }),
};

ChangePasswordForm.defaultProps = {
    status: {},
};

export default ChangePasswordForm;
