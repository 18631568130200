import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseTable, { navigateToDetailsProps } from '../../Table';
import { DOCTOR } from '../../../constants/routes';

const COLUMNS = [
    { Header: 'Apellido', accessor: 'lastName' },
    { Header: 'Nombre', accessor: 'firstName', minWidth: 80 },
    { Header: 'Email', accessor: 'email', minWidth: 120 },
    { Header: 'Centro médico', accessor: 'hospital', minWidth: 60 },
];

const Table = (props) => {
    const { history } = props;
    return (
        <Row>
            <Col>
                <BaseTable
                    columns={COLUMNS}
                    getTdProps={navigateToDetailsProps(DOCTOR, history)}
                    {...props}
                />
            </Col>
        </Row>
    );
};

Table.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default Table;
