import { combineReducers } from 'redux';
import { RESOURCE } from '../actions';
import { crudReducer, crudAndOptionsReducer } from './common';
import auth from './auth';
import cache from './cache';
import accountingMovements from './accountingMovements';
import users from './users';
import doctors from './doctors';
import patients from './patients';
import logs from './logs';

export default combineReducers({
    accountingCharts: crudAndOptionsReducer(RESOURCE.ACCOUNTING_CHARTS),
    accountingPaymentTypes: crudAndOptionsReducer(RESOURCE.ACCOUNTING_PAYMENT_TYPES),
    bankAccounts: crudAndOptionsReducer(RESOURCE.BANK_ACCOUNTS),
    bankChecks: crudReducer(RESOURCE.BANK_CHECKS),
    offices: crudAndOptionsReducer(RESOURCE.OFFICES),
    pathologies: crudAndOptionsReducer(RESOURCE.PATHOLOGIES),
    procedures: crudAndOptionsReducer(RESOURCE.PROCEDURES),
    providers: crudAndOptionsReducer(RESOURCE.PROVIDERS),
    schedules: crudAndOptionsReducer(RESOURCE.SCHEDULES),
    sessions: crudReducer(RESOURCE.SESSIONS),
    treatments: crudReducer(RESOURCE.TREATMENTS),
    accountingMovements,
    auth,
    cache,
    doctors,
    logs,
    patients,
    users,
});
