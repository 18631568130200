import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { IoFlagOutline } from 'react-icons/io5';
import { connect, Field } from 'formik';

const FlagButton = ({ name, value, formik }) => {
    const { setFieldValue } = formik;
    const variant = value ? 'warning' : 'outline-warning';
    const toggleValue = () => setFieldValue(name, !value);
    return (
        <>
            <Field type="hidden" name={name} checked={value} />
            <Button size="sm" variant={variant} onClick={toggleValue}>
                <IoFlagOutline />
            </Button>
        </>
    );
};

FlagButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }).isRequired,
};

export default connect(FlagButton);
