import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../../actions/accountingMovements';
import StatementPage from './StatementPage';
import getNestedValue from '../../../utils/getNestedValue';

const getCurrentYear = () => (new Date()).getFullYear();

const defaultFilters = () => ({
    year: `${getCurrentYear()}`,
    office: '',
});

const getCurrentOfficeFilter = (state) => {
    const id = getNestedValue(state, 'cache.currentOffice.id');
    if (typeof id !== 'undefined') {
        return { office: `${id}` };
    }
    return {};
};

const getCurrentFilterUsed = state => getNestedValue(state, 'accountingMovements.statement.filters') || {};

const mapStateToProps = state => ({
    filters: {
        ...defaultFilters(),
        ...getCurrentOfficeFilter(state),
        ...getCurrentFilterUsed(state),
    },
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getStatement: actions.getStatement,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(StatementPage);
