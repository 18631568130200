import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    Loading, Attribute, LinkAddress, LinkPhone, LinkEmail, MultiLineText,
} from '../../RecordDetails';
import Actions from './Actions';
import Treatments from './Treatments.redux';
import Breadcrumb from '../../Breadcrumb';
import * as ROUTES from '../../../constants/routes';
import { getFullName, FORMAT_INVERTED } from '../../../utils/user';
import dateUtil from '../../../utils/date';
import { formatRutAddingDigit } from '../../../utils/rut';

const BREADCRUMB = [
    { path: ROUTES.LANDING, label: 'Inicio' },
    { path: ROUTES.DOCTORS, label: 'Médicos' },
];

const systemEvent = (user = {}, datetime) => (
    <span>
        {user && getFullName(user)}
        <br />
        {dateUtil.formatDateTime(datetime)}
    </span>
);

class ShowPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateRecord();
    }

    componentDidUpdate(prevProps) {
        const { recordId } = this.props;
        if (prevProps.recordId !== recordId) {
            this.updateRecord();
        }
    }

    updateRecord() {
        const { getDetails, recordId } = this.props;
        getDetails(recordId);
    }

    renderBody() {
        const {
            loading, error, record, recordId,
        } = this.props;
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Alert variant="danger">Hubo un error cargando la información</Alert>;
        }

        return (
            <div>
                <Row>
                    <Col lg="6">
                        {this.renderPersonalData()}
                    </Col>
                    <Col lg="6">
                        {this.renderContactData()}
                        {this.renderSystemData()}
                    </Col>
                </Row>
                <Actions record={record} />
                <Treatments recordId={recordId} />
            </div>
        );
    }

    renderPersonalData() {
        const { record } = this.props;
        return (
            <div>
                <legend>{getFullName(record, FORMAT_INVERTED)}</legend>
                <Attribute name="Rut" value={formatRutAddingDigit(record.rut)} />
                <Attribute optional name="Especialidad" value={record.specialty} />
                <Attribute optional name="Centro médico" value={record.hospital} />
                <Attribute optional name="Notas" value={MultiLineText({ text: record.notes })} />
            </div>
        );
    }

    renderContactData() {
        const { record } = this.props;
        const {
            email, cellPhone, officePhone, address, borough, city,
        } = record;
        const containsContactInfo = email || cellPhone || officePhone
            || address || borough || city;

        if (containsContactInfo) {
            return (
                <div>
                    <legend>Contacto</legend>
                    <Attribute optional name="Mail" value={LinkEmail(record)} />
                    <Attribute optional name="Teléfono Celular" value={LinkPhone({ number: cellPhone })} />
                    <Attribute optional name="Teléfono Oficina" value={LinkPhone({ number: officePhone })} />
                    <Attribute optional name="Dirección" value={LinkAddress(record)} />
                </div>
            );
        }

        return null;
    }

    renderSystemData() {
        const { record } = this.props;
        return (
            <div>
                <legend>Sistema</legend>
                <Attribute optional name="Agregado" value={systemEvent(record.createdBy, record.createdAt)} />
                <Attribute optional name="Actualizado" value={systemEvent(record.updatedBy, record.updatedAt)} />
            </div>
        );
    }

    renderBreadcrumbPaths() {
        const { record } = this.props;
        return [
            ...BREADCRUMB,
            { label: getFullName(record) },
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb paths={this.renderBreadcrumbPaths()} />
                {this.renderBody()}
            </div>
        );
    }
}

ShowPage.propTypes = {
    recordId: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
    record: PropTypes.shape({}),
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
};

ShowPage.defaultProps = {
    record: {},
    error: undefined,
    loading: false,
};

export default ShowPage;
