const URI = 'data:application/vnd.ms-excel;base64,';
const TEMPLATE = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
const format = content => TEMPLATE.replace(/{(\w+)}/g, (m, p) => content[p]);

const createFile = content => URI + base64(format(content));

const downloadFile = (encodedUri, fileName) => {
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.xls`);
    link.click();
};

const convertReactTableToTable = (reactTable) => {
    const table = document.createElement('table');
    const reactHeader = reactTable.getElementsByClassName('rt-thead');
    if (reactHeader.length > 0) {
        const tr = document.createElement('tr');
        const reactTHs = reactHeader[0].getElementsByClassName('rt-th');
        Array.prototype.forEach.call(reactTHs, (reactTh) => {
            const th = document.createElement('th');
            th.innerHTML = reactTh.innerHTML;
            tr.appendChild(th);
        });
        const header = document.createElement('thead');
        header.appendChild(tr);
        table.appendChild(header);
    }
    const reactBody = reactTable.getElementsByClassName('rt-tbody');
    if (reactBody.length > 0) {
        const body = document.createElement('tbody');
        const reactTRs = reactBody[0].getElementsByClassName('rt-tr');
        Array.prototype.forEach.call(reactTRs, (reactTr) => {
            const tr = document.createElement('tr');
            const reactTDs = reactTr.getElementsByClassName('rt-td');
            Array.prototype.forEach.call(reactTDs, (reactTd) => {
                const td = document.createElement('td');
                td.innerHTML = reactTd.innerHTML;
                tr.appendChild(td);
            });
            body.appendChild(tr);
        });
        table.appendChild(body);
    }
    return table;
};

const tableToExcel = (tableInput, fileName, sheetName) => {
    let table = tableInput;
    if (!table.nodeType) {
        table = document.getElementById(table);
    }

    if (table.classList.contains('ReactTable')) {
        table = convertReactTableToTable(table);
    }

    const content = {
        worksheet: sheetName || 'Worksheet',
        table: table.innerHTML,
    };
    const file = createFile(content);
    downloadFile(file, fileName);
};

export default {
    tableToExcel,
};
