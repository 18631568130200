import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const TYPE_MAP = {
    Administrator: { title: 'Administrador', path: ROUTES.USER },
    Recepcionist: { title: 'Recepcionista', path: ROUTES.USER },
    Kinesiologist: { title: 'Kinesiólogo', path: ROUTES.USER },
    Patient: { title: 'Paciente', path: ROUTES.PATIENT },
    AccountingChart: { title: 'Cuenta Contable', path: ROUTES.ACCOUNTING_CHART },
    AccountingPaymentType: { title: 'Tipo Movimiento Contable', path: ROUTES.ACCOUNTING_PAYMENT_TYPE },
    AccountingMovement: { title: 'Movimiento Contable', path: ROUTES.ACCOUNTING_MOVEMENT },
    BankAccount: { title: 'Cuenta Bancaria', path: ROUTES.BANK_ACCOUNT },
    BankCheck: { title: 'Cheque', path: ROUTES.BANK_CHECK },
    Office: { title: 'Oficina', path: ROUTES.OFFICE },
    Pathology: { title: 'Patología', path: ROUTES.PATHOLOGY },
    Procedure: { title: 'Procedimiento', path: ROUTES.PROCEDURE },
    Provider: { title: 'Proveedor', path: ROUTES.PROVIDER },
    Schedule: { title: 'Horario', path: ROUTES.SCHEDULE },
    Treatment: { title: 'Tratamiento', path: ROUTES.TREATMENT },
    Sale: { title: 'Venta', path: ROUTES.SALE },
};

const getLinkInfo = ({ relatedObjectType, relatedObjectId } = {}) => {
    if (!relatedObjectType || !relatedObjectId) {
        return undefined;
    }

    const info = TYPE_MAP[relatedObjectType];
    if (!info) {
        return undefined;
    }

    return {
        title: info.title,
        path: info.path.replace(':id', relatedObjectId),
    };
};

const CellMessageLink = ({ value, original }) => {
    const linkInfo = getLinkInfo(original);
    if (linkInfo) {
        return (
            <Link to={linkInfo.path}>
                {linkInfo.title}
                :
                {' '}
                {value}
            </Link>
        );
    }
    return value;
};

export default CellMessageLink;
